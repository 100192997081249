import React from "react";

export default function SecurityWLabel() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Security of our White Label Exchange Service</h2>
            <p>
              Robust security measures, designed to protect your exchange and
              ensure a safe trading environment for your users.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one" 
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"429px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/data_encryption.svg" />
                  </div>
                  <h3 className="">Data Encryption</h3>
                  <p>
                    Ensure the confidentiality and integrity of sensitive user
                    information by employing advanced encryption protocols, both
                    for data in transit and at rest, preventing unauthorized
                    access and data breaches. <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one" 
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"429px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/jail_login.svg" />
                  </div>
                  <h3 className="">Jail Login</h3>
                  <p>
                    Protect user accounts from brute force attacks by
                    automatically locking login attempts after a set number of
                    failed attempts, effectively preventing unauthorized access
                    through repeated guessing. <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/token_based_http_authentication.svg" />
                  </div>
                  <h3>Token-Based HTTP Authentication</h3>
                  <p>
                    Secure API communications by utilizing token-based HTTP
                    authentication, ensuring that only verified clients can
                    access the platform’s services, and reducing the risk of
                    session hijacking or unauthorized access.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one" 
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"429px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/two_factor_authentication.svg" />
                  </div>
                  <h3>Two-Factor Authentication (2FA)</h3>
                  <p>
                    Add an extra layer of security to user accounts by requiring
                    a second verification step, such as a code sent to the
                    user’s mobile device, making it significantly harder for
                    attackers to compromise accounts.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"451px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/anti_denial_of_service_protection.svg" />
                  </div>
                  <h3>Anti-Denial of Service (DoS) Protection</h3>
                  <p>
                    Implement Anti-DoS measures to prevent malicious actors from
                    overwhelming the exchange with excessive traffic, ensuring
                    uninterrupted service and protecting the platform from
                    downtime or slowdowns.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"451px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/cross_site_request_forgery_security_mechanism.svg" />
                  </div>
                  <h3>Cross-Site Request Forgery (CSRF) Security Mechanism</h3>
                  <p>
                    Token-based security mechanism defends exchange from
                    unwanted actions from an authenticated user.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/server_side_request.svg" />
                  </div>
                  <h3>Server-Side Request Forgery (SSRF) Protection</h3>
                  <p>
                    Safeguard your platform from SSRF attacks by filtering and
                    validating incoming requests, ensuring that attackers cannot
                    exploit server vulnerabilities to access internal systems or
                    sensitive information.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"451px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/exchange/wle/anti_denial_of_service.svg" />
                  </div>
                  <h3>Anti-Denial of Service (Dos)</h3>
                  <p>
                    Anti-Dos secures the ecosystems when a susceptible number of
                    requests is recognized by the trading engine.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
