import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import PolygonEdgeHero from "./PolygonEdgeHero";
import PolygonNetworkBlockchain from "./PolygonNetworkBlockchain";
import PolygonEdgefor from "./PolygonEdgefor";
import BuildPolygon from "./BuildPolygon";
import ApplicationsonPolygon from "./ApplicationsonPolygon";
import PolygonSolutions from "./PolygonSolutions";
import NadcabLabs from "./NadcabLabs";
import PolygonLabs from "./PolygonLabs";

export default function PolygonEdge() {
  return (
    <div>
      <div class="boxed_wrapper ltr">
        <Header />
        <PolygonEdgeHero />
        <PolygonNetworkBlockchain/>
        <BuildPolygon/>
        <PolygonEdgefor/>
        <ApplicationsonPolygon/>
        {/* <PolygonSolutions/> */}
        {/* <NadcabLabs/> */}
        <PolygonLabs/>
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </div>
  );
}
