import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import NFTLoanHero from "./NFTLoanHero";
import NFTLending from "./NFTLending";
import AntiersExpertise from "./AntiersExpertise";
import LoanPlatform from "./LoanPlatform";
import FeaturesOfNFT from "./FeaturesOfNFT";
import TalkToExperts from "../Coman/TalkToExperts";
import BenefitsNFT from "./BenefitsNFT";
import Spotlight from "./Spotlight";
import LookingD from "../About-Us/LookingD";
import WhyChoNFTDevS from "./WhyChoNFTDevS";

export default function NFTLoan() {
  return (
    <div>
      <Header />
      <NFTLoanHero />
      <NFTLending />
      <AntiersExpertise />
      <LoanPlatform />
      <TalkToExperts />
      {/* <FeaturesOfNFT/> */}
      <BenefitsNFT />
      <WhyChoNFTDevS />
      <Spotlight />
      <LookingD />
      <Footar />
    </div>
  );
}
