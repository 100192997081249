import React from "react";

export default function PresentingNFT() {
  return (
    <>
      <section className="feature-section p_150 bg-black">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-inner">
                <figure className="image">
                  <img
                    src="assets/img/nft_dev/nft-gaming-platform-development-by-xeer-technology.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column pt-5">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    NFT Gaming Platform Development By Xeer Technology
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    At Xeer Technology, we specialize in NFT Gaming Platform
                    Development that merges the excitement of gaming with the
                    innovative power of blockchain technology. Our dedicated
                    team is committed to creating immersive gaming experiences
                    where players can truly own, trade, and earn from their
                    in-game assets through non-fungible tokens (NFTs).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="glance-section centred">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>NFT Gaming Platform Development By Xeer Technology</h2>
              <p>
                At Xeer Technology, we specialize in NFT Gaming Platform
                Development that merges the excitement of gaming with the
                innovative power of blockchain technology. Our dedicated team is
                committed to creating immersive gaming experiences where players
                can truly own, trade, and earn from their in-game assets through
                non-fungible tokens (NFTs).
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
