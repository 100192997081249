import React from 'react'
import Header from '../Coman/Header'
import MobileWalletAppHero from './MobileWalletAppHero'
import Footar from '../Coman/Footar'
import WalletApps from './WalletApps'
import SafetyAttributes from './SafetyAttributes'
import DevBitcoinW from './DevBitcoinW'
import Attributes from './Attributes'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../About-Us/LookingD'
import WhyCmwad from './WhyCmwad'
import Letushelp from './Letushelp'

export default function MobileWalletApp() {
  return (
    <div>
      <Header/>
      <MobileWalletAppHero/>
      <Letushelp/>
      {/* <SafetyAttributes/> */}
      {/* <DevBitcoinW/> */}
      <Attributes/>
      <WalletApps/>
      <WhyCmwad/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
