import React from "react";
import Header from "../Coman/Header";
import PaymentGatewayHero from "./PaymentGatewayHero";
import Footar from "../Coman/Footar";
import Enhance from "./Enhance";
import OurRange from "./OurRange";
import Gateways from "./Gateways";
import BeforeChoosing from "./BeforeChoosing";
import BlogsNFT from "../NFT/BlogsNFT";
import GlobalReach from "./GlobalReach";
import LookingD from "../About-Us/LookingD";
import GetStarted from "./GetStarted";
import WhyChoosePayGateway from "./WhyChoosePayGateway";

export default function PaymentGateway() {
  return (
    <div>
      <Header />
      <PaymentGatewayHero />
      <Enhance />
      <BeforeChoosing />
      <OurRange />
      <Gateways />
      <GlobalReach />
      <GetStarted />
      <WhyChoosePayGateway />
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
