import React from "react";

export default function MarketExpansion() {
  return (
    <>
      <section
        className="integrations-section centred p_50"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white py-3">Benefits of Crypto Banking</h2>
              <p className="text-white ">
                Discover how crypto banking enhances financial accessibility,
                reduces transaction costs, and provides users with greater
                control, flexibility, and security over their digital assets,{" "}
                <br />
                revolutionizing the traditional banking experience.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/blockchain_development.svg"
                      />
                    </div>
                    <h3 className="pt-4 text-white">Financial Inclusion</h3>
                    <p className="pt-2 text-white">
                      Crypto banking services are available globally, offering
                      banking solutions to those without access to traditional
                      banks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/smart_contracts.svg"
                      />
                    </div>
                    <h3 className="pt-4 text-white">Lower Fees</h3>
                    <p className="pt-2 text-white">
                      Crypto banking transactions often come with lower fees
                      compared to traditional banks, thanks to reduced
                      intermediaries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/crypto_exchange.svg"
                      />
                    </div>
                    <h3 className="pt-4 text-white">Control Over Assets</h3>
                    <p className="pt-2 text-white">
                      Crypto banks provide users with greater control over their
                      finances through decentralized wallets and flexible
                      investment options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/defi_app.svg"
                      />
                    </div>
                    <h3 className="pt-4 text-white">24/7 Access</h3>
                    <p className="pt-2 text-white">
                      Users can access their accounts and perform transactions
                      anytime, anywhere, due to the global and digital nature of
                      crypto banking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
