import React from "react";

export default function TechnologyStack() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">Technology Stack</h1>
            <p className="text-white py-4">
              Our blockchain experts utilize Ethereum, Hyperledger, Polkadot,
              and Binance Smart Chain, and create customized protocols for
              asset-backed tokens that prioritize security and regulatory
              compliance.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20" >
          {/* className="theme-btn btn-one mr_20" */}
          Contact Us to begin your journey into asset tokenization.
          </a>
        </div>
      </section>
    </>
  );
}
