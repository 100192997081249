import React from "react";

export default function NFTGameDev() {
  return (
    <>
      <section
        className="feature-section p_150 "
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/nft_dev/nft-gaming-platform-development.jpg)",
        }}
      >
        <div className="auto-container pb-4">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25 pt-5">
                  <h2 className="pt-5 text-white">
                    NFT Gaming Platform Development
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology offers customized NFT Gaming Platform
                    Development services that blend the thrill of gaming with
                    the innovative potential of non-fungible tokens (NFTs). Our
                    goal is to empower game developers and studios to create
                    engaging, immersive experiences that revolutionize how
                    players interact with their in-game assets.
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <a href="#" className="theme-btn btn-one">
                  Talk To Our Team
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column pt-5"></div>
          </div>
        </div>
      </section>
    </>
  );
}

{
  /* <video muted autoPlay loop id="myVideo" className="video">
<source
  src="assets/images/img-one/hero_section_video.mp4"
  type="video/mp4"
/>
</video> */
}
