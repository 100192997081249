import React from 'react'
import Header from '../Coman/Header'
import DefiStakingHero from './DefiStakingHero'
import Footar from '../Coman/Footar'
import Opportunities from './Opportunities'
import PremiumDeF from './PremiumDeF'
import LookingD from '../About-Us/LookingD'
import GloballyDYK from './GloballyDYK'
import TransAndSecure from './TransAndSecure'
import PopularTokens from './PopularTokens'
import FarmingSyrup from './FarmingSyrup'
import PassiveIncome from './PassiveIncome'
import EndToEnd from './EndToEnd'

export default function DefiStaking() {
  return (
    <div>
      <Header/>
      <DefiStakingHero/>
      <PassiveIncome/>
      <Opportunities/>
      <PremiumDeF/>
      <TransAndSecure/>
      {/* <PopularTokens/> */}
      {/* <GloballyDYK/> */}
      {/* <FarmingSyrup/> */}
      <EndToEnd/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
