import React from 'react'
import Header from '../../Coman/Header'
import BlogsNFT from '../../NFT/BlogsNFT'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import Layer2Hero from './Layer2Hero'
import Layer2Solutions from './Layer2Solutions'
import Layer2Mechanisms from './Layer2Mechanisms'
import Layer2Performance from './Layer2Performance'
import Layer2Presenting from './Layer2Presenting'

export default function Layer2BlockchanNetwork() {
  return (
    <>
     <div class="boxed_wrapper ltr">
        <Header/>
        <Layer2Hero/>
        <Layer2Presenting/>
        <Layer2Solutions/>
        <Layer2Mechanisms/>
        <Layer2Performance/>       
        <BlogsNFT />
        <LookingD />
        <Footar />
        </div>
    </>
  )
}
