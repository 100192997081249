import React from "react";

export default function CryptoGameDevelopment() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 animated">
            <h2>Key Features of Blockchain Game Development</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/blockchain-dev/SVG/blockchain_integration.svg" />
                  </div>
                  <h3>Blockchain Integration</h3>
                  <p>
                    Blockchain seamlessly integrates digital assets, allowing
                    players to own and trade their in-game items securely. Our
                    blockchain solutions address current gamification issues by
                    streamlining transactions and creating genuine digital
                    assets for both players and developers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/blockchain-dev/SVG/smart_contracts.svg" />
                  </div>
                  <h3>Smart Contracts</h3>
                  <p>
                    Smart contracts bring transparency and fairness to in-game
                    transactions. By enabling verification of dealer win rates,
                    smart contracts boost trust in games where outcomes matter,
                    all while adding value to every transaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/blockchain-dev/SVG/ownership_of_digital_assets.svg" />
                  </div>
                  <h3>Ownership of Digital Assets</h3>
                  <p>
                    Non-fungible tokens (NFTs) revolutionize ownership in gaming
                    by transforming in-game items into unique assets. Players
                    now have the freedom to truly own, trade, and benefit from
                    their digital possessions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="pb-3">
                    <img style={{width:"60px"}} src="assets/img/blockchain-dev/SVG/optimized_blockchain_design.svg" />
                  </div>
                  <h3>Optimized Blockchain Design</h3>
                  <p>
                    Our game architecture employs distributed ledgers for faster
                    block validation and ensures a secure gaming experience with
                    privacy-focused, optimized frameworks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
