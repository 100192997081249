import React from "react";

export default function ICOdevelopmentHero() {
  return (
    <>
      <section className="feature-section p_150"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/coin-dev/initial-coin-offering-ico-services.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Initial Coin Offering (ICO) Services</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology is your strategic partner in launching a
                    successful ICO! We combine technical excellence with a
                    market-driven approach, ensuring your project’s readiness
                    for the dynamic blockchain environment.
                  </p>
                </div>
              </div>
              <alkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
