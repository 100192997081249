import React from "react";

export default function AirdropsMarketing() {
  return (
    <>
      <section className="feature-section p_50"style={{background:"url(assets/img/010.png",padding:"30px"}} >
        <div className="auto-container">
          <h1 className="text-center">Airdrop Marketing</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/airdrop_marketing1.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h4>Create Buzz with Targeted Airdrop Campaigns</h4>
                  <p className="py-3">
                    {" "}
                    Airdrops are an effective way to increase awareness and
                    attract users. We design and manage airdrop campaigns that
                    are engaging and value-driven, helping you build a strong
                    user base and promote your token.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>User Acquisition</h4>
                      <p>
                        Drive new user interest and onboard a broader audience.
                      </p>
                    </li>
                    <li>
                      <h4>Incentivized Engagemen</h4>
                      <p>
                        Reward users for participating, boosting brand loyalty.
                      </p>
                    </li>
                    <li>
                      <h4>Secure Distribution</h4>
                      <p> Ensure seamless airdrop distribution and tracking.</p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Launch an Airdrop Campaign
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
