import React from "react";

export default function WhiteLCryL() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">
              Launch Your Crypto Platform with Xeer Technology
            </h1>
            <p className="text-white py-3">
              With Xeer Technology, you can confidently enter the blockchain
              space, offering startups and investors a reliable and feature-rich
              launchpad experience. From strategy to development and beyond, we
              deliver everything needed to create a successful launchpad.
            </p>
          </div>
          <a href="#" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
