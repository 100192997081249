import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [showtab, setShowTab] = useState(null);

  useEffect(() => {
    if (isHeaderOpen) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }

    // Cleanup to avoid memory leaks when the component unmounts
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [isHeaderOpen]);
  const toggleHeader = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };

  useEffect(() => {
    console.log("showtab : ", showtab);
  });
  const currentPath = window.location.pathname;

  return (
    <>
      <header className="main-header header-style-one">
        {/* header-lower */}
        <div className="header-lower">
          <div
            className="auto px-2 px-md-4 px-lg-5 py-3"
            style={{
              backgroundColor: "#071522",
            }}
          >
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/">
                    <img
                      src="assets/img/logo.svg"
                      style={{ height: "50px" }}
                      alt=""
                    />
                  </Link>
                </figure>
              </div>
              <div className="menu-area ">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler" onClick={toggleHeader}>
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className=" dropdown">
                        <Link
                          className={`${
                            currentPath === "/" ? "xeer-color  " : " "
                          }`}
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="dropdown">
                        <Link
                          to="/about-us"
                          className={`${
                            currentPath === "/about-us" ? "xeer-color  " : " "
                          }`}
                        >
                          About Us
                        </Link>
                      </li>

                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/exchange" ||
                            currentPath === "/white-label-exchanges" ||
                            currentPath === "/margin-trading-exchange" ||
                            currentPath === "/security-exchangees" ||
                            currentPath === "/p2p-exchange" ||
                            currentPath === "/decentralized-exchange" ||
                            currentPath === "/derivatives-exchange" ||
                            currentPath === "/arbitrage-trading-bots" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/cryptocurrency-wallet" ||
                            currentPath === "/web3-wallet" ||
                            currentPath === "/multicurrency-wallet" ||
                            currentPath === "/nft-wallet" ||
                            currentPath === "/ethereum-wallet" ||
                            currentPath === "/bitcoin-wallet" ||
                            currentPath === "/mobile-wallet-app" ||
                            currentPath === "/white-label-wallet" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/nft-development" ||
                            currentPath === "/nft-collection-launch" ||
                            currentPath === "/nft-marketplace" ||
                            currentPath === "/nft-gaming" ||
                            currentPath === "/nft-loan" ||
                            currentPath === "/defi-development" ||
                            currentPath === "/defi-staking" ||
                            currentPath === "/dapp-development-compoany" ||
                            currentPath === "/defi-lottery" ||
                            currentPath === "/dao-blockchain" ||
                            currentPath === "/defi-lending-and-borrowing" ||
                            currentPath === "/defi-yienld-farming" ||
                            currentPath === "/olympus-dao-development" ||
                            currentPath === "/web-30-devlopment" ||
                            currentPath === "/web3-game-devlopment" ||
                            currentPath === "/dex-aggregator" ||
                            currentPath === "/amm-dex" ||
                            currentPath === "/order-book-dexs" ||
                            currentPath === "/crypto-banking" ||
                            currentPath === "/cryptocurrency-payment-gateway" ||
                            currentPath === "/token-development" ||
                            currentPath === "/ido-development" ||
                            currentPath === "/crypto-launchpad" ||
                            currentPath === "/ico-development" ||
                            currentPath === "/trading-bot" ||
                            currentPath === "/flash-loan-bot" ||
                            currentPath === "/arbitrage-bots" ||
                            currentPath === "/mev-bot"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Solutions <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/exchange" ||
                                currentPath === "/white-label-exchanges" ||
                                currentPath === "/margin-trading-exchange" ||
                                currentPath === "/security-exchangees" ||
                                currentPath === "/p2p-exchange" ||
                                currentPath === "/decentralized-exchange" ||
                                currentPath === "/derivatives-exchange" ||
                                currentPath === "/arbitrage-trading-bots" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/exchange.svg"
                                className="mx-2"
                              />
                              Exchange
                            </Link>
                            {/* </div> */}
                            <ul>
                              <li>
                                <Link
                                  to="/exchange"
                                  className={`${
                                    currentPath === "/exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/white-label-exchange"
                                  className={`${
                                    currentPath === "/white-label-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/margin-trading-exchange"
                                  className={`${
                                    currentPath === "/margin-trading-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Margin Trading Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/security-exchange"
                                  className={`${
                                    currentPath === "/security-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Security Exchange
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/p2p-exchange"
                                  className={`${
                                    currentPath === "/p2p-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  P2P Exchange
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/derivatives-exchange"
                                  className={`${
                                    currentPath === "/derivatives-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Derivatives Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/arbitrage-trading-bots"
                                  className={`${
                                    currentPath === "/arbitrage-trading-bots"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Arbitrage Trading Bots
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/market-making-services"
                                  className={`${
                                    currentPath === "/market-making-services"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Market Making Services
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/cryptocurrency-wallet" ||
                                currentPath === "/web3-wallet" ||
                                currentPath === "/multicurrency-wallet" ||
                                currentPath === "/nft-wallet" ||
                                currentPath === "/ethereum-wallet" ||
                                currentPath === "/bitcoin-wallet" ||
                                currentPath === "/mobile-wallet-app" ||
                                currentPath === "/white-label-wallet" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/wallet.svg"
                                className="mx-2"
                              />
                              Wallet
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/cryptocurrency-wallet"
                                  className={`${
                                    currentPath === "/cryptocurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/web3-wallet"
                                  className={`${
                                    currentPath === "/web3-walle"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/multicurrency-wallet"
                                  className={`${
                                    currentPath === "/multicurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Wallet Clone App
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-wallet"
                                  className={`${
                                    currentPath === "/nft-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ethereum-wallet"
                                  className={`${
                                    currentPath === "/ethereum-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Ethereum Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/bitcoin-wallet"
                                  className={`${
                                    currentPath === "/bitcoin-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Bitcoin Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/mobile-wallet-app"
                                  className={`${
                                    currentPath === "/mobile-wallet-app"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Mobile Wallet App
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-wallet"
                                  className={`${
                                    currentPath === "/defi-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/white-label-wallet"
                                  className={`${
                                    currentPath === "/white-label-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Wallet
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/nft-development" ||
                                currentPath === "/nft-collection-launch" ||
                                currentPath === "/nft-marketplace" ||
                                currentPath === "/nft-gaming" ||
                                currentPath === "/nft-loan"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/nft.svg"
                                className="mx-2"
                              />
                              NFT
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/nft-development"
                                  className={`${
                                    currentPath === "/nft-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-collection-launch"
                                  className={`${
                                    currentPath === "/nft-collection-launch"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label NFT Marketplace
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/nft-marketplace"
                                  className={`${
                                    currentPath === "/nft-marketplace"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Marketplace
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-loan"
                                  className={`${
                                    currentPath === "/nft-loan"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Loan
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/nft-gaming"
                                  className={`${
                                    currentPath === "/nft-gaming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Gaming Platform
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/defi-development" ||
                                currentPath === "/defi-staking" ||
                                currentPath === "/dapp-development-compoany" ||
                                currentPath === "/defi-lottery" ||
                                currentPath === "/dao-blockchain" ||
                                currentPath === "/defi-lending-and-borrowing" ||
                                currentPath === "/defi-yienld-farming" ||
                                currentPath === "/olympus-dao-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/defi.svg"
                                className="mx-2"
                              />
                              Defi
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/defi-development"
                                  className={`${
                                    currentPath === "/defi-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-staking"
                                  className={`${
                                    currentPath === "/defi-staking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Staking Platform
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/defi-lottery"
                                  className={`${
                                    currentPath === "/defi-lottery"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lottery
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/defi-lending-and-borrowing"
                                  className={`${
                                    currentPath ===
                                    "/defi-lending-and-borrowing"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lending And Borrowing
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-yienld-farming"
                                  className={`${
                                    currentPath === "/defi-yienld-farming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Yienld Farming
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/web-30-devlopment" ||
                                currentPath === "/web3-game-devlopment"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                className="mx-2"
                              />
                              Web3
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/web-30-devlopment"
                                  className={`${
                                    currentPath === "/web-30-devlopment"
                                      ? "xeer-color"
                                      : " "
                                  }`}
                                >
                                  Web 3.0 Devlopment
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/web3-game-devlopment"
                                  className={`${
                                    currentPath === "/web3-game-devlopment"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Game Devlopment
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/dex-aggregator" ||
                                currentPath === "/amm-dex" ||
                                currentPath === "/order-book-dexs"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/dexs.svg"
                                className="mx-2"
                              />
                              DEXs
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/decentralized-exchange"
                                  className={`${
                                    currentPath === "/decentralized-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Decentralized Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/dex-aggregator"
                                  className={`${
                                    currentPath === "/dex-aggregator"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Dex Aggregator
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/amm-dex"
                                  className={`${
                                    currentPath === "/amm-dex"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  AMM Dex
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/order-book-dexs"
                                  className={`${
                                    currentPath === "/order-book-dexs"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Order Book Dexs
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/crypto-banking" ||
                                currentPath ===
                                  "/cryptocurrency-payment-gateway"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/crypto_banking.svg"
                                className="mx-2"
                              />
                              Crypto Banking
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/crypto-banking"
                                  className={`${
                                    currentPath === "/crypto-banking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Banking
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/cryptocurrency-payment-gateway"
                                  className={`${
                                    currentPath ===
                                    "/cryptocurrency-payment-gateway"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Payment Gateway
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/token-development" ||
                                currentPath === "/ido-development" ||
                                currentPath === "/crypto-launchpad" ||
                                currentPath === "/ico-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/coin_development.svg"
                                className="mx-2"
                              />
                              Coin Development
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/token-development"
                                  className={`${
                                    currentPath === "/token-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Coin/Token Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ido-development"
                                  className={`${
                                    currentPath === "/ido-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  IDP Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/crypto-launchpad"
                                  className={`${
                                    currentPath === "/crypto-launchpad"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Launchpad
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ico-development"
                                  className={`${
                                    currentPath === "/ico-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  ICO Development
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="">
                            <Link
                              to="/crypto-gaming-app"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                className="mx-2"
                              />
                              Crypto Gaming
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/trading-bot"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/mlm.svg"
                                className="mx-2"
                              />
                              Trading Bot
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/mlm"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/mlm.svg"
                                className="mx-2"
                              />
                              MLM
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/Blockchain-development" ||
                            currentPath === "/harshgraph-development" ||
                            currentPath === "/blockchain-game-development" ||
                            currentPath === "/substrate" ||
                           
                            currentPath === "/tezos" ||
                            currentPath === "/cosmos" ||
                            currentPath === "/polygon-edge" ||
                            currentPath === "/polygon-blockchain"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Blockchain <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/Blockchain-development" ||
                                currentPath === "/harshgraph-development" ||
                                currentPath ===
                                  "/blockchain-game-development" ||
                                currentPath === "/dag-acyclic-graph"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              Blockchain Development
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/Blockchain-development"
                                  className={`${
                                    currentPath === "/Blockchain-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/harshgraph-development"
                                  className={`${
                                    currentPath === "/harshgraph-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Harshgraph Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/blockchain-game-development"
                                  className={`${
                                    currentPath ===
                                    "/blockchain-game-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Game Development
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/substrate" ||
                               
                                currentPath === "/tezos" ||
                                currentPath === "/cosmos" ||
                                currentPath === "/polygon-edge" ||
                                currentPath === "/polygon-blockchain"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              Blockchain Frameworks
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/substrate"
                                  className={`${
                                    currentPath === "/substrate"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  SubStrate
                                </Link>
                              </li>
                              
                              <li>
                                <Link
                                  to="/tezos"
                                  className={`${
                                    currentPath === "/tezos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Tezos
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/cosmos"
                                  className={`${
                                    currentPath === "/cosmos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cosmos
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/polygon-edge"
                                  className={`${
                                    currentPath === "/polygon-edge"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon Edge
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/polygon-blockchain"
                                  className={`${
                                    currentPath === "/polygon-blockchain"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon BlockChain
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Layer 1 & 2 Solution</Link>
                            <ul>
                              <li>
                                <Link to="/bsc">BSC</Link>
                              </li>
                              <li>
                                <Link to="/ethereum">Ethereum</Link>
                              </li>
                              <li>
                                <Link to="/solana">Solana</Link>
                              </li>

                              <li>
                                <Link to="/cardano">Cardano</Link>
                              </li>
                              <li>
                                <Link to="/polkadot">Polkadot</Link>
                              </li>
                              <li>
                                <Link to="/ton">TON</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Smart Contract</Link>
                            <ul>
                              <li>
                                <Link to="/smart-contract-development">
                                  Smart Contract Development
                                </Link>
                              </li>
                              <li>
                                <Link to="/deFi-smart-contract">
                                  DeFi Smart Contract
                                </Link>
                              </li>
                              <li>
                                <Link to="/smart-contract-audit">
                                  Smart Contract Audit
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Assent Tekenization</Link>
                            <ul>
                              <li>
                                <Link to="/asset-tokenization">
                                  Asset Tokenization
                                </Link>
                              </li>
                              <li>
                                <Link to="/crypto-asset-management">
                                  Crypto Asset Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="">
                            <Link
                              to="/enterprise-solution"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              Enterprise Solution
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/securitization"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              Securitization
                            </Link>
                          </li>
                          <li className="p2p-lending">
                            <Link
                              to="/p2p-lending"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              P2P Lending
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link>
                          Listing <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li>
                            <Link to="/exchange-listing">Exchange Listing</Link>
                          </li>
                          <li>
                            <Link to="/token-listing">Token Listing</Link>
                          </li>
                          <li>
                            <Link to="/coingecko-and-cmc-listing">
                              CoinGecko & CMC Listing
                            </Link>
                          </li>
                          
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="/marketing">Marketing</Link>
                        <ul>
                          <li>
                            <Link to="/crypto-token-marketing">
                              Crypto Token Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/pr-and-media">PR & Media</Link>
                          </li>
                          <li>
                            <Link to="/marketing-strategies">
                              Marketing Strategies
                            </Link>
                          </li>
                          <li>
                            <Link to="/social-media-marketing">
                              Social Media Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/content-marketing">
                              Content Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/community-building-and-management">
                              Community Building & Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/airdrop-marketing">
                              Airdrop Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/rebranding">Rebranding</Link>
                          </li>
                          <li>
                            <Link to="/pitch-deck-design">
                              Pitch Deck Design
                            </Link>
                          </li>
                          <li>
                            <Link to="/fund-raising">Fund Raising</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li>
                        <Link to="#">Hire Developer</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="btn-box">
                  <Link to="index.html" className="theme-btn btn-one">
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* sticky Header*/}
        <div className="sticky-header ">
          <div
            className="auto"
            style={{ paddingLeft: "2%", paddingRight: "2%", height: "75px" }}
          >
             <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/">
                    <img
                      src="assets/img/logo.svg"
                      style={{ height: "50px" }}
                      alt=""
                    />
                  </Link>
                </figure>
              </div>
              <div className="menu-area ">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler" onClick={toggleHeader}>
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className=" dropdown">
                        <Link
                          className={`${
                            currentPath === "/" ? "xeer-color  " : " "
                          }`}
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="dropdown">
                        <Link
                          to="/about-us"
                          className={`${
                            currentPath === "/about-us" ? "xeer-color  " : " "
                          }`}
                        >
                          About Us
                        </Link>
                      </li>

                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/exchange" ||
                            currentPath === "/white-label-exchanges" ||
                            currentPath === "/margin-trading-exchange" ||
                            currentPath === "/security-exchangees" ||
                            currentPath === "/p2p-exchange" ||
                            currentPath === "/decentralized-exchange" ||
                            currentPath === "/derivatives-exchange" ||
                            currentPath === "/arbitrage-trading-bots" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/cryptocurrency-wallet" ||
                            currentPath === "/web3-wallet" ||
                            currentPath === "/multicurrency-wallet" ||
                            currentPath === "/nft-wallet" ||
                            currentPath === "/ethereum-wallet" ||
                            currentPath === "/bitcoin-wallet" ||
                            currentPath === "/mobile-wallet-app" ||
                            currentPath === "/white-label-wallet" ||
                            currentPath === "/market-making-services" ||
                            currentPath === "/nft-development" ||
                            currentPath === "/nft-collection-launch" ||
                            currentPath === "/nft-marketplace" ||
                            currentPath === "/nft-gaming" ||
                            currentPath === "/nft-loan" ||
                            currentPath === "/defi-development" ||
                            currentPath === "/defi-staking" ||
                            currentPath === "/dapp-development-compoany" ||
                            currentPath === "/defi-lottery" ||
                            currentPath === "/dao-blockchain" ||
                            currentPath === "/defi-lending-and-borrowing" ||
                            currentPath === "/defi-yienld-farming" ||
                            currentPath === "/olympus-dao-development" ||
                            currentPath === "/web-30-devlopment" ||
                            currentPath === "/web3-game-devlopment" ||
                            currentPath === "/dex-aggregator" ||
                            currentPath === "/amm-dex" ||
                            currentPath === "/order-book-dexs" ||
                            currentPath === "/crypto-banking" ||
                            currentPath === "/cryptocurrency-payment-gateway" ||
                            currentPath === "/token-development" ||
                            currentPath === "/ido-development" ||
                            currentPath === "/crypto-launchpad" ||
                            currentPath === "/ico-development" ||
                            currentPath === "/trading-bot" ||
                            currentPath === "/flash-loan-bot" ||
                            currentPath === "/arbitrage-bots" ||
                            currentPath === "/mev-bot"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Solutions <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/exchange" ||
                                currentPath === "/white-label-exchanges" ||
                                currentPath === "/margin-trading-exchange" ||
                                currentPath === "/security-exchangees" ||
                                currentPath === "/p2p-exchange" ||
                                currentPath === "/decentralized-exchange" ||
                                currentPath === "/derivatives-exchange" ||
                                currentPath === "/arbitrage-trading-bots" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/exchange.svg"
                                className="mx-2"
                              />
                              Exchange
                            </Link>
                            {/* </div> */}
                            <ul>
                              <li>
                                <Link
                                  to="/exchange"
                                  className={`${
                                    currentPath === "/exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/white-label-exchange"
                                  className={`${
                                    currentPath === "/white-label-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/margin-trading-exchange"
                                  className={`${
                                    currentPath === "/margin-trading-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Margin Trading Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/security-exchange"
                                  className={`${
                                    currentPath === "/security-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Security Exchange
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/p2p-exchange"
                                  className={`${
                                    currentPath === "/p2p-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  P2P Exchange
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/derivatives-exchange"
                                  className={`${
                                    currentPath === "/derivatives-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Derivatives Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/arbitrage-trading-bots"
                                  className={`${
                                    currentPath === "/arbitrage-trading-bots"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Arbitrage Trading Bots
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/market-making-services"
                                  className={`${
                                    currentPath === "/market-making-services"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Market Making Services
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/cryptocurrency-wallet" ||
                                currentPath === "/web3-wallet" ||
                                currentPath === "/multicurrency-wallet" ||
                                currentPath === "/nft-wallet" ||
                                currentPath === "/ethereum-wallet" ||
                                currentPath === "/bitcoin-wallet" ||
                                currentPath === "/mobile-wallet-app" ||
                                currentPath === "/white-label-wallet" ||
                                currentPath === "/market-making-services"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/wallet.svg"
                                className="mx-2"
                              />
                              Wallet
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/cryptocurrency-wallet"
                                  className={`${
                                    currentPath === "/cryptocurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/web3-wallet"
                                  className={`${
                                    currentPath === "/web3-walle"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/multicurrency-wallet"
                                  className={`${
                                    currentPath === "/multicurrency-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Wallet Clone App
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-wallet"
                                  className={`${
                                    currentPath === "/nft-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ethereum-wallet"
                                  className={`${
                                    currentPath === "/ethereum-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Ethereum Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/bitcoin-wallet"
                                  className={`${
                                    currentPath === "/bitcoin-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Bitcoin Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/mobile-wallet-app"
                                  className={`${
                                    currentPath === "/mobile-wallet-app"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Mobile Wallet App
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-wallet"
                                  className={`${
                                    currentPath === "/defi-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Wallet
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/white-label-wallet"
                                  className={`${
                                    currentPath === "/white-label-wallet"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label Wallet
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/nft-development" ||
                                currentPath === "/nft-collection-launch" ||
                                currentPath === "/nft-marketplace" ||
                                currentPath === "/nft-gaming" ||
                                currentPath === "/nft-loan"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/nft.svg"
                                className="mx-2"
                              />
                              NFT
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/nft-development"
                                  className={`${
                                    currentPath === "/nft-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-collection-launch"
                                  className={`${
                                    currentPath === "/nft-collection-launch"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  White Label NFT Marketplace
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/nft-marketplace"
                                  className={`${
                                    currentPath === "/nft-marketplace"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Marketplace
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/nft-loan"
                                  className={`${
                                    currentPath === "/nft-loan"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Loan
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/nft-gaming"
                                  className={`${
                                    currentPath === "/nft-gaming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  NFT Gaming Platform
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/defi-development" ||
                                currentPath === "/defi-staking" ||
                                currentPath === "/dapp-development-compoany" ||
                                currentPath === "/defi-lottery" ||
                                currentPath === "/dao-blockchain" ||
                                currentPath === "/defi-lending-and-borrowing" ||
                                currentPath === "/defi-yienld-farming" ||
                                currentPath === "/olympus-dao-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/defi.svg"
                                className="mx-2"
                              />
                              Defi
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/defi-development"
                                  className={`${
                                    currentPath === "/defi-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-staking"
                                  className={`${
                                    currentPath === "/defi-staking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Staking Platform
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/defi-lottery"
                                  className={`${
                                    currentPath === "/defi-lottery"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lottery
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/defi-lending-and-borrowing"
                                  className={`${
                                    currentPath ===
                                    "/defi-lending-and-borrowing"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Lending And Borrowing
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/defi-yienld-farming"
                                  className={`${
                                    currentPath === "/defi-yienld-farming"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Defi Yienld Farming
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/web-30-devlopment" ||
                                currentPath === "/web3-game-devlopment"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                className="mx-2"
                              />
                              Web3
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/web-30-devlopment"
                                  className={`${
                                    currentPath === "/web-30-devlopment"
                                      ? "xeer-color"
                                      : " "
                                  }`}
                                >
                                  Web 3.0 Devlopment
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/web3-game-devlopment"
                                  className={`${
                                    currentPath === "/web3-game-devlopment"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Web3 Game Devlopment
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/dex-aggregator" ||
                                currentPath === "/amm-dex" ||
                                currentPath === "/order-book-dexs"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/dexs.svg"
                                className="mx-2"
                              />
                              DEXs
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/decentralized-exchange"
                                  className={`${
                                    currentPath === "/decentralized-exchange"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Decentralized Exchange
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/dex-aggregator"
                                  className={`${
                                    currentPath === "/dex-aggregator"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Dex Aggregator
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/amm-dex"
                                  className={`${
                                    currentPath === "/amm-dex"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  AMM Dex
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/order-book-dexs"
                                  className={`${
                                    currentPath === "/order-book-dexs"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Order Book Dexs
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/crypto-banking" ||
                                currentPath ===
                                  "/cryptocurrency-payment-gateway"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/crypto_banking.svg"
                                className="mx-2"
                              />
                              Crypto Banking
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/crypto-banking"
                                  className={`${
                                    currentPath === "/crypto-banking"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Banking
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/cryptocurrency-payment-gateway"
                                  className={`${
                                    currentPath ===
                                    "/cryptocurrency-payment-gateway"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cryptocurrency Payment Gateway
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/token-development" ||
                                currentPath === "/ido-development" ||
                                currentPath === "/crypto-launchpad" ||
                                currentPath === "/ico-development"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/coin_development.svg"
                                className="mx-2"
                              />
                              Coin Development
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/token-development"
                                  className={`${
                                    currentPath === "/token-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Coin/Token Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ido-development"
                                  className={`${
                                    currentPath === "/ido-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  IDP Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/crypto-launchpad"
                                  className={`${
                                    currentPath === "/crypto-launchpad"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Crypto Launchpad
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/ico-development"
                                  className={`${
                                    currentPath === "/ico-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  ICO Development
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className="">
                            <Link
                              to="/crypto-gaming-app"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/web3.svg"
                                className="mx-2"
                              />
                              Crypto Gaming
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/trading-bot"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/mlm.svg"
                                className="mx-2"
                              />
                              Trading Bot
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/mlm"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              <img
                                style={{ width: "35px" }}
                                src="assets/img/icon/solution/mlm.svg"
                                className="mx-2"
                              />
                              MLM
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link
                          className={`${
                            currentPath === "/Blockchain-development" ||
                            currentPath === "/harshgraph-development" ||
                            currentPath === "/blockchain-game-development" ||
                            currentPath === "/substrate" ||
                           
                            currentPath === "/tezos" ||
                            currentPath === "/cosmos" ||
                            currentPath === "/polygon-edge" ||
                            currentPath === "/polygon-blockchain"
                              ? "xeer-color  "
                              : " "
                          }`}
                        >
                          Blockchain <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/Blockchain-development" ||
                                currentPath === "/harshgraph-development" ||
                                currentPath ===
                                  "/blockchain-game-development" ||
                                currentPath === "/dag-acyclic-graph"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              Blockchain Development
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/Blockchain-development"
                                  className={`${
                                    currentPath === "/Blockchain-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/harshgraph-development"
                                  className={`${
                                    currentPath === "/harshgraph-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Harshgraph Development
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/blockchain-game-development"
                                  className={`${
                                    currentPath ===
                                    "/blockchain-game-development"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Blockchain Game Development
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link
                              className={`${
                                currentPath === "/substrate" ||
                               
                                currentPath === "/tezos" ||
                                currentPath === "/cosmos" ||
                                currentPath === "/polygon-edge" ||
                                currentPath === "/polygon-blockchain"
                                  ? "xeer-color  "
                                  : " "
                              }`}
                            >
                              Blockchain Frameworks
                            </Link>
                            <ul>
                              <li>
                                <Link
                                  to="/substrate"
                                  className={`${
                                    currentPath === "/substrate"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  SubStrate
                                </Link>
                              </li>
                              
                              <li>
                                <Link
                                  to="/tezos"
                                  className={`${
                                    currentPath === "/tezos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Tezos
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/cosmos"
                                  className={`${
                                    currentPath === "/cosmos"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Cosmos
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/polygon-edge"
                                  className={`${
                                    currentPath === "/polygon-edge"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon Edge
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/polygon-blockchain"
                                  className={`${
                                    currentPath === "/polygon-blockchain"
                                      ? "xeer-color  "
                                      : " "
                                  }`}
                                >
                                  Polygon BlockChain
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Layer 1 & 2 Solution</Link>
                            <ul>
                              <li>
                                <Link to="/bsc">BSC</Link>
                              </li>
                              <li>
                                <Link to="/ethereum">Ethereum</Link>
                              </li>
                              <li>
                                <Link to="/solana">Solana</Link>
                              </li>

                              <li>
                                <Link to="/cardano">Cardano</Link>
                              </li>
                              <li>
                                <Link to="/polkadot">Polkadot</Link>
                              </li>
                              <li>
                                <Link to="/ton">TON</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Smart Contract</Link>
                            <ul>
                              <li>
                                <Link to="/smart-contract-development">
                                  Smart Contract Development
                                </Link>
                              </li>
                              <li>
                                <Link to="/deFi-smart-contract">
                                  DeFi Smart Contract
                                </Link>
                              </li>
                              <li>
                                <Link to="/smart-contract-audit">
                                  Smart Contract Audit
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link>Assent Tekenization</Link>
                            <ul>
                              <li>
                                <Link to="/asset-tokenization">
                                  Asset Tokenization
                                </Link>
                              </li>
                              <li>
                                <Link to="/crypto-asset-management">
                                  Crypto Asset Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="">
                            <Link
                              to="/enterprise-solution"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              Enterprise Solution
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              to="/securitization"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              Securitization
                            </Link>
                          </li>
                          <li className="p2p-lending">
                            <Link
                              to="/p2p-lending"
                              className={`${
                                currentPath === "" ? "xeer-color  " : " "
                              }`}
                            >
                              P2P Lending
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link>
                          Listing <i class="fa-solid fa-angle-down ms-1"></i>
                        </Link>
                        <ul>
                          <li>
                            <Link to="/exchange-listing">Exchange Listing</Link>
                          </li>
                          <li>
                            <Link to="/token-listing">Token Listing</Link>
                          </li>
                          <li>
                            <Link to="/coingecko-and-cmc-listing">
                              CoinGecko & CMC Listing
                            </Link>
                          </li>
                          
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="/marketing">Marketing</Link>
                        <ul>
                          <li>
                            <Link to="/crypto-token-marketing">
                              Crypto Token Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/pr-and-media">PR & Media</Link>
                          </li>
                          <li>
                            <Link to="/marketing-strategies">
                              Marketing Strategies
                            </Link>
                          </li>
                          <li>
                            <Link to="/social-media-marketing">
                              Social Media Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/content-marketing">
                              Content Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/community-building-and-management">
                              Community Building & Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/airdrop-marketing">
                              Airdrop Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/rebranding">Rebranding</Link>
                          </li>
                          <li>
                            <Link to="/pitch-deck-design">
                              Pitch Deck Design
                            </Link>
                          </li>
                          <li>
                            <Link to="/fund-raising">Fund Raising</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li>
                        <Link to="#">Hire Developer</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-right-content">
                <div className="btn-box">
                  <Link to="/contact-us" className="theme-btn btn-one">
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop" />
          <div>
            <div className="close-btn" onClick={toggleHeader}>
              <i className="fas fa-times" />
            </div>
          </div>
          <nav className="menu-box pt-2">
            <div>
              <a href="#" className="m-3">
                <img
                  src="assets/img/logo.svg"
                  style={{ width: "150px" }}
                  alt=""
                />
              </a>
            </div>
            <div className="menu-outer mt-4">
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
              <div
                className="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li
                    className="dropdown"
                    onClick={() => {
                      showtab !== null ? setShowTab(null) : setShowTab(0);
                    }}
                  >
                    <a>Solutions</a>
                    <ul className={showtab === 0 ? "d-block " : "d-none"}>
                      <li className="dropdown">
                        <a>Exchange</a>
                        <ul>
                          <li>
                            <a href="/exchange">Cryptocurrency Exchange</a>
                          </li>
                          <li>
                            <a href="/white-label-exchange">
                              White Label Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/margin-trading-exchange">
                              Margin Trading Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/security-exchange">Security Exchange</a>
                          </li>

                          <li>
                            <a href="/p2p-exchange">P2P Exchange</a>
                          </li>
                          <li>
                            <a href="/decentralized-exchange">
                              Decentralized Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/derivatives-exchange">
                              Derivatives Exchange
                            </a>
                          </li>
                          <li>
                            <a href="/arbitrage-trading-bots">
                              Arbitrage Trading Bots
                            </a>
                          </li>
                          <li>
                            <a href="/market-making-services">
                              Market Making Services
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Wallet</a>
                        <ul>
                          <li>
                            <a href="/cryptocurrency-wallet">
                              Cryptocurrency Wallet
                            </a>
                          </li>
                          <li>
                            <a href="/web3-wallet">Web3 Wallet</a>
                          </li>
                          <li>
                            <a href="/multicurrency-wallet">
                              Crypto Wallet Clone App
                            </a>
                          </li>
                          <li>
                            <a href="/nft-wallet">NFT Wallet</a>
                          </li>
                          <li>
                            <a href="/ethereum-wallet">Ethereum Wallet </a>
                          </li>
                          <li>
                            <a href="/bitcoin-wallet">Bitcoin Wallet</a>
                          </li>
                          <li>
                            <a href="/mobile-wallet-app">Mobile Wallet App</a>
                          </li>
                          <li>
                            <a href="/defi-wallet">Defi Wallet</a>
                          </li>
                          <li>
                            <a href="/white-label-wallet">White Label Wallet</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>NFT</a>
                        <ul>
                          <li>
                            <a href="/nft-development">NFT Development</a>
                          </li>
                          <li>
                            <a href="/nft-collection-launch">
                              White Label NFT Marketplace
                            </a>
                          </li>

                          <li>
                            <a href="/nft-marketplace">NFT Marketplace</a>
                          </li>
                          <li>
                            <a href="/nft-loan">NFT Loan</a>
                          </li>

                          <li>
                            <a href="/nft-gaming">NFT Gaming</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Defi</a>
                        <ul>
                          <li>
                            <a href="/defi-development">Defi Development</a>
                          </li>
                          <li>
                            <a href="/defi-staking">Defi Staking</a>
                          </li>
                          <li>
                            <a href="/dapp-development-compoany">
                              Dapp Development Compoany
                            </a>
                          </li>
                          <li>
                            <a href="/defi-lottery">Defi Lottery</a>
                          </li>
                          <li>
                            <a href="/dao-blockchain">DAO Blockchain</a>
                          </li>
                          <li>
                            <a href="/defi-lending-and-borrowing">
                              Defi Lending And Borrowing
                            </a>
                          </li>
                          <li>
                            <a href="/defi-yienld-farming">
                              Defi Yienld Farming
                            </a>
                          </li>
                          <li>
                            <a href="/olympus-dao-development">
                              Olympus DAO Development
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Web3</a>
                        <ul>
                          <li>
                            <a href="/web-30-devlopment">Web 3.0 Devlopment</a>
                          </li>
                          <li>
                            <a href="/web3-game-devlopment">
                              Web3 Game Devlopment
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>DEXs</a>
                        <ul>
                          <li>
                            <a href="/dex-aggregator">Dex Aggregator</a>
                          </li>
                          <li>
                            <a href="/amm-dex">AMM Dex</a>
                          </li>
                          <li>
                            <a href="/order-book-dexs">Order Book Dexs</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Crypto Banking</a>
                        <ul>
                          <li>
                            <a href="/crypto-banking">Crypto Banking</a>
                          </li>
                          <li>
                            <a href="/cryptocurrency-payment-gateway">
                              Cryptocurrency Payment Gateway
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Crypto Gaming</a>
                        <ul>
                          <li>
                            <a href="#">Crypto Gaming App</a>
                          </li>
                          <li>
                            <a href="#">Crypto Gaming Platform</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Coin Development</a>
                        <ul>
                          <li>
                            <a href="/token-development">
                              Coin/Token Development
                            </a>
                          </li>
                          <li>
                            <a href="/ido-development">IDO Development</a>
                          </li>
                          <li>
                            <a href="/crypto-launchpad">Crypto Launchpad</a>
                          </li>
                          <li>
                            <a href="/ico-development">ICO Development</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Trading Bot</a>
                        <ul>
                          <li>
                            <a href="/trading-bot">Trading Bot</a>
                          </li>
                          <li>
                            <a href="/flash-loan-bot">Flash Loan Bot</a>
                          </li>
                          <li>
                            <a href="/arbitrage-bots">Arbitrage Bots</a>
                          </li>
                          <li>
                            <a href="/mev-bot">MEV Bot</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="/mlm"> MLM</a>
                      </li>
                    </ul>
                    <div className="dropdown-btn">
                      <span className="fas fa-angle-down" />
                    </div>
                  </li>
                  {/* <li>
                    <a href="about.html">About</a>
                  </li> */}
                  <li
                    className="dropdown"
                    onClick={() => {
                      showtab !== null ? setShowTab(null) : setShowTab(1);
                    }}
                  >
                    <a>Blockchain</a>
                    <ul className={showtab === 1 ? "d-block " : "d-none"}>
                      <li className="dropdown">
                        <a>Blockchain Development</a>
                        <ul>
                          <li>
                            <a href="/Blockchain-development">
                              Blockchain Development
                            </a>
                          </li>
                          <li>
                            <a href="/harshgraph-development">
                              Harshgraph Development
                            </a>
                          </li>
                          <li>
                            <a href="/blockchain-game-development">
                              Blockchain Game Development
                            </a>
                          </li>
                          <li>
                            <a href="/dag-acyclic-graph">DAG Acyclic Graph</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Blockchain Frameworks</a>
                        <ul>
                          <li>
                            <a href="/substrate">SubStrate</a>
                          </li>
                          <li>
                            <a href="/algorand-blockchain">
                              Algorand Blockchain
                            </a>
                          </li>
                          <li>
                            <a href="/tezos">Tezos</a>
                          </li>
                          <li>
                            <a href="/cosmos">Cosmos</a>
                          </li>
                          <li>
                            <a href="/polygon-edge">Polygon Edge</a>
                          </li>
                          <li>
                            <a href="/polygon-blockchain">Polygon BlockChain</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Layer 1 & 2 Solution</a>
                        <ul>
                          <li>
                            <a href="/layer-one-blockchain-network">
                              Layer 1 Blockchain Network
                            </a>
                          </li>
                          <li>
                            <a href="/layer-two-blockchain-network">
                              Layer 2 Blockchain Network
                            </a>
                          </li>
                          <li>
                            <a href="/ethereum-layer-two-network">
                              Ethereum Layer 2 Network
                            </a>
                          </li>
                          <li>
                            <a href="/solana-layer-two-network">
                              Solana Layer 2 Network
                            </a>
                          </li>
                          <li>
                            <a href="/custom-layer-two-network">
                              Custom Layer 2 Network
                            </a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Enterprise Blockchain</a>
                        <ul>
                          <li>
                            <a href="#">Supply chain</a>
                          </li>
                          <li>
                            <a href="#">Entertainment</a>
                          </li>
                          <li>
                            <a href="#">Procure-to-Pay</a>
                          </li>
                          <li>
                            <a href="#">Transport and Logistics</a>
                          </li>
                          <li>
                            <a href="#">Education</a>
                          </li>
                          <li>
                            <a href="#">Carbon Credits</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Enterprise Blockchain</a>
                        <ul>
                          <li>
                            <a href="#">Supply chain</a>
                          </li>
                          <li>
                            <a href="#">Entertainment</a>
                          </li>
                          <li>
                            <a href="#">Procure-to-Pay</a>
                          </li>
                          <li>
                            <a href="#">Transport and Logistics</a>
                          </li>
                          <li>
                            <a href="#">Education</a>
                          </li>
                          <li>
                            <a href="#">Carbon Credits</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Smart Contract</a>
                        <ul>
                          <li>
                            <a href="#">Smart Contract Development</a>
                          </li>
                          <li>
                            <a href="#">DeFi Smart Contract</a>
                          </li>
                          <li>
                            <a href="#">Smart Contract Audit</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Assent Tekenization</a>
                        <ul>
                          <li>
                            <a href="#">Asset Tokenization</a>
                          </li>
                          <li>
                            <a href="#">Crypto Asset Management</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>Securitization</a>
                        <ul>
                          <li>
                            <a href="#">STO</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown">
                        <a>P2P Lending</a>
                        <ul>
                          <li>
                            <a href="#">P2P lending platform</a>
                          </li>
                        </ul>
                        <div className="dropdown-btn">
                          <span className="fas fa-angle-down" />
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-btn">
                      <span className="fas fa-angle-down" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a href="#">Listing</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">Marketing</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">Hire Developer</a>
                  </li>
                </ul>
                <a class="theme-btn btn-one py-2 ms-3" href="#">
                  Get a Quote
                </a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
