import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import PolygonBlockChainHero from "./PolygonBlockChainHero";
import PolygonBlockchaiService from "./PolygonBlockchaiService";
import OPStack from "./OPStack";
import OurOPStackSer from "./OurOPStackSer";
import BenefitsOfOPS from "./BenefitsOfOPS";
import PartnerWithXeer from "./PartnerWithXeer";

export default function PolygonBlockChain() {
  return (
    <div>
      <div class="boxed_wrapper ltr">
        <Header />
        <PolygonBlockChainHero />
        <PolygonBlockchaiService />
        <OPStack />
        <OurOPStackSer/>
        <BenefitsOfOPS/>
        <PartnerWithXeer/>
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </div>
  );
}
