import React from "react";

export default function OurTalented() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features & Wallet Development Service</h2>
            <p>
              These features of Ethereum wallet securely manage Ethereum and its
              diverse ecosystem of tokens and dApps.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"293px"}}>
                  <div className="">
                    <img src="assets/img/secure_storage.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Secure Storage</h3>
                  <p>
                    Utilizes advanced encryption and private key management to
                    ensure the safety of users' Ether (ETH) and ERC-20 tokens.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"293px"}}>
                  <div className="">
                    <img src="assets/img/user_friendly_interface.svg" style={{width:"64px"}} />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    Intuitive design that allows users to easily navigate, send,
                    and receive ETH and tokens without technical expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"293px"}}>
                  <div className="">
                    <img src="assets/img/multi_token_support.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Multi-Token Support</h3>
                  <p>
                    Ability to store and manage a variety of Ethereum-based
                    tokens (ERC-20, ERC-721, etc.) alongside Ether, providing
                    flexibility in asset management.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img src="assets/img/seamless_transactions.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Seamless Transactions</h3>
                  <p>
                    Quick and efficient transaction processing with low gas
                    fees, enabling users to send and receive funds easily.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img src="assets/img/multi_device_access.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Multi-Device Access</h3>
                  <p>
                    Synchronization across multiple devices, enabling users to
                    manage their wallets on smartphones, tablets, and desktops.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="">
                    <img src="assets/img/backup_recovery_options.svg" style={{width:"64px"}}/>
                  </div>
                  <h3>Backup and Recovery Options</h3>
                  <p>
                    Secure backup solutions, such as mnemonic phrases or seed
                    phrases, to restore wallet access in case of device loss.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img src="assets/img/transaction_history.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Transaction History</h3>
                  <p>
                    Detailed records of all transactions made within the wallet,
                    providing users with easy tracking and management of their
                    activity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img src="assets/img/custom_gas_fee_management.svg" style={{width:"64px"}} />
                  </div>
                  <h3>Custom Gas Fee Management</h3>
                  <p>
                    Users can set and adjust gas fees for transactions, allowing
                    them to optimize costs based on network conditions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img src="assets/img/two_factor_authentication.svg" style={{width:"64px"}}/>
                  </div>
                  <h3>Two-Factor Authentication (2FA)</h3>
                  <p>
                    Enhanced security through optional 2FA, adding an extra
                    layer of protection against unauthorized access.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
