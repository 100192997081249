import React from 'react'
import Header from '../Coman/Header'
import DefiLotteryHero from './DefiLotteryHero'
import Footar from '../Coman/Footar'
import NeedforDeFi from './NeedforDeFi'
import Redefining from './Redefining'
import BlogsNFT from '../NFT/BlogsNFT'
import LookingD from '../About-Us/LookingD'
import Diligently from './Diligently'
import WCDeFiLottery from './WCDeFiLottery'
import PopularofUse from './PopularofUse'

export default function DefiLottery() {
  return (
    <div>
      <Header/>
      <DefiLotteryHero/>
      <NeedforDeFi/>
      <Diligently/>
      <Redefining/>
      <PopularofUse/>
      <WCDeFiLottery/>
      <BlogsNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
