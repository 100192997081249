import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function Engaging() {
  return (
    <>
      <section className="feature-section p_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 text-center">
            <h2>MLM Plans </h2>
            <p className="pt-3">
              With advanced features, secure infrastructure, and seamless user
              experiences, our MLM Plans help you create a thriving network
              marketing ecosystem.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <figure className="">
                <img
                  src="assets/img/we-deliver-the-best-crypto-exchange-development-services.png"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div className="text-box">
                  <div className="row">
                    <div className="col">
                      <ul className="list-style-one clearfix">
                        <li>Binary MLM Plan</li>
                        <li>Matrix MLM Plan</li>
                        <li>Unilevel MLM Plan</li>
                        <li>Stair Step MLM Plan</li>
                        <li>Board MLM Plan</li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="list-style-one clearfix">
                        <li>Matrix MLM Plan</li>
                        <li>Generation MLM Plan</li>
                        <li>Level MLM Plan</li>
                        <li>Linear MLM Plan</li>
                        <li>Group MLM Plan</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box pt_100">
                <TalkToOurTeamBTN />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
