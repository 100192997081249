import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SolanaHero from "./SolanaHero";
import SolanaPerfect from "./SolanaPerfect";
import SolanaServices from "./SolanaServices";
import SolanaServicesPrice from "./SolanaServicesPrice";
import SolanaOffered from "./SolanaOffered";

export default function Solana() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <SolanaHero />
        <SolanaServicesPrice />
        <SolanaServices />
        <SolanaOffered />
        <SolanaPerfect />
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
