import React from "react";

export default function Powerful() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_50 pb_50 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Start your journey towards financial innovation and take the lead
              in the future of digital finance with our White Label Crypto
              Wallet solution. Your brand, your wallet, your way – delivered
              today!
            </h2>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mt-5">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
