import React from "react";

export default function TezosHero() {
  return (
    <div>
      <section className="feature-section p_150" style={{
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundImage:
          "url(assets/img/framework/Tezos-Blockchain-Solutions-Partner-Hero-Section-Image.jpg)",
      }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_80">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">Tezos Blockchain Solutions Partner
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white fs-5">
                    Xeer Technology, a trusted leader in Tezos Blockchain Development, offers cutting-edge solutions to help businesses leverage the efficiency, security, and scalability of Tezos.
                  </p>
                </div>
              </div>
              <div className="btn-box mt_40">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Hire Blockchain Expert
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
