import React from "react";

export default function AdvancedBitcoin() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Iterations of Bitcoin Wallets</h2>
            <p>
              Bitcoin wallet falls under several categories with its iterations
              depending on the network. Following are the iterations offered by
              Xeer Technology.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"375px"}}>
                  <div className="text-start">
                  <img src="assets/img/bitcoin_wallet.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className="text-start pt-3">SegWit Bitcoin Wallet</h3>
                  <p className="text-start">
                  A SegWit wallet supports Bitcoin’s Segregated Witness (SegWit) upgrade, enabling faster, lower-cost transactions by separating signature data. It’s widely supported, offering efficient network performance without changing standard Bitcoin use.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"375px"}}>
                  <div className="text-start">
                  <img src="assets/img/lightning_wallet.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className="text-start pt-3">Lightning Wallet</h3>
                  <p className="text-start">
                  Lightning wallets utilize the Lightning Network for faster, low-fee transactions. Ideal for frequent, small transactions, they allow instant transfers through payment channels, bypassing main blockchain confirmation times.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"375px"}}>
                  <div className="text-start">
                  <img src="assets/img/bech_wallet.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className="text-start pt-3">Bech32 Wallet</h3>
                  <p className="text-start">
                  Bech32 wallets use Bitcoin’s Bech32 address format, beginning with "bc1," for native SegWit addresses. This format reduces transaction size, lowers fees, improves error detection, and enhances user-friendliness.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
          </div>
        </div>
      </section>
    </>
  );
}
