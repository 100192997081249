import React from "react";

export default function BlogsNFT() {
  return (
    <>
      <section className="news-section p-5">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <div className="inner_wrap">
              <h2>Our Blogs</h2>
              <p>Read latest blogs from Xeer Technology.</p>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-1.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a href="blog-details.html">
                        Resource AI content tips tricks and strategy started
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 20, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Alex Beniwal</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-2.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a href="blog-details.html">
                        You made it this far, why not make it to the end
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 18, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Haris Gilat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-3.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <h3>
                      <a href="blog-details.html">
                        Keeps your data safest private with built in security
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 17, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Tomas Wats</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
