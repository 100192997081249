import React from 'react'
import Header from '../Coman/Header'
import BitcoinWalletHero from './BitcoinWalletHero'
import Footar from '../Coman/Footar'
import TopSolutions from './TopSolutions'
import AdvancedBitcoin from './AdvancedBitcoin'
import ProcessOfBitcoin from './ProcessOfBitcoin'
import SecurelyStore from './SecurelyStore'
import LookingD from '../About-Us/LookingD'
import BitcoinWDevService from './BitcoinWDevService'
import GetAQuote from './GetAQuote'

export default function BitcoinWallet() {
  return (
    <div>
        <Header/>
        <BitcoinWalletHero/>
        <BitcoinWDevService/>
        <TopSolutions/>
        <AdvancedBitcoin/>
        <GetAQuote/>
        {/* <ProcessOfBitcoin/> */}
        <SecurelyStore/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
