import React, { useState } from "react";

export default function ByPartnering() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      {/* <section className="faq-style-three pt_100 pb_140">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-44.png)" }}
        />
        <div
          className="pattern-layer-2"
          style={{ backgroundImage: "url(assets/images/shape/shape-70.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <h3 className="" style={{ fontSize: "40px" }}>
              Why Choose Xeer Technology?
            </h3>
            <p>
              Xeer Technology stands out as a leader in blockchain and DeFi
              development, offering innovative solutions tailored to your
              business needs. <br /> Here’s why you should partner with us:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_30">
                <figure className="image">
                  <img src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30">
                <ul className="accordion-box">
                  <li className="accordion block active-block">
                    <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(0)} />
                      <h4>1. Expertise in Blockchain & DeFi</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 0 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Our team comprises seasoned blockchain developers and
                          financial experts who possess deep industry knowledge.
                          We have a proven track record of developing successful
                          DeFi platforms, NFT marketplaces, and blockchain-based
                          applications across various sectors.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(1)} />
                      <h4>2. Custom Solutions</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 1 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We believe every project is unique. That’s why we
                          offer fully customized solutions that align with your
                          specific requirements and goals. Whether you need a
                          decentralized exchange, lending platform, or NFT
                          marketplace, we create tailored solutions to bring
                          your vision to life.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(2)} />
                      <h4>3. Security-First Approach</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 2 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Security is at the core of everything we build. We
                          follow best practices and rigorous testing protocols
                          to ensure your platform is secure from
                          vulnerabilities, fraud, and cyber threats. Our smart
                          contracts undergo comprehensive audits to guarantee
                          flawless execution.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(3)} />
                      <h4>4. Scalable Development</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 3 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We employ an agile development methodology that allows
                          for flexibility, fast iteration, and scalability.
                          Whether you’re launching an MVP or scaling an existing
                          platform, our development process ensures your project
                          can grow with demand.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(4)} />
                      <h4>5. End-to-End Services</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 4 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We offer comprehensive services, from concept creation
                          and design to development, deployment, and post-launch
                          support. Our end-to-end development ensures that every
                          aspect of your DeFi or blockchain project is handled
                          with precision and expertise.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(5)} />
                      <h4>6. Post-Launch Support</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 5 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We don’t just stop at delivering the final product.
                          Our team provides ongoing support, updates, and
                          maintenance to ensure that your platform continues to
                          perform optimally. We stay with you every step of the
                          way to ensure long-term success.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology?
            </h2>
            <p className="p-3">
              Xeer Technology stands out as a leader in blockchain and DeFi development, offering innovative solutions tailored to your business needs.
              Here’s why you should partner with us:
            </p>

          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/wallet/dw/blockchain_pioneers.svg" />
                  </div>
                  <h3 className="pt-4">Expertise in Blockchain & DeFi</h3>
                  <p>
                    Our team comprises seasoned blockchain developers and financial experts who possess deep industry knowledge. We have a proven track record of developing successful DeFi platforms, NFT marketplaces, and blockchain-based applications across various sectors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/wallet/mwa/customized_solutions.svg" />
                  </div>
                  <h3 className="pt-4">Custom Solutions</h3>
                  <p>
                    We believe every project is unique. That’s why we offer fully customized solutions that align with your specific requirements and goals. Whether you need a decentralized exchange, lending platform, or NFT marketplace, we create tailored solutions to bring your vision to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/asset-tok/svg/institutional_grade_security.svg" />
                  </div>
                  <h3 className="pt-4">Security-First Approach</h3>
                  <p>
                    Security is at the core of everything we build. We follow best practices and rigorous testing protocols to ensure your platform is secure from vulnerabilities, fraud, and cyber threats. Our smart contracts undergo comprehensive audits to guarantee flawless execution.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/dde/customizable_and_scalable_platform.svg" />
                  </div>
                  <h3 className="pt-4">Scalable Development</h3>
                  <p>
                    We employ an agile development methodology that allows for flexibility, fast iteration, and scalability. Whether you’re launching an MVP or scaling an existing platform, our development process ensures your project can grow with demand.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/wallet/dw/end_to_end_services.svg" />
                  </div>
                  <h3 className="pt-4"> End-to-End Services</h3>
                  <p>
                    We offer comprehensive services, from concept creation and design to development, deployment, and post-launch support. Our end-to-end development ensures that every aspect of your DeFi or blockchain project is handled with precision and expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/wallet/mwa/post_launch_support.svg" />
                  </div>
                  <h3 className="pt-4">Post-Launch Support</h3>
                  <p>
                    We don’t just stop at delivering the final product. Our team provides ongoing support, updates, and maintenance to ensure that your platform continues to perform optimally. We stay with you every step of the way to ensure long-term success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
