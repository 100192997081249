import React from "react";

export default function MlmCompiler() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our MLM Platforms</h2>
            <p className="pt-3">
              Explore the powerful features of our MLM platforms, designed to
              streamline operations, enhance user engagement, and drive
              sustainable growth across all levels of your network.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/multi-tier-commission-tracking.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Multi-Tier Commission Tracking</h3>
                  <p>
                    Efficiently manage and track multi-level commissions,
                    allowing accurate and transparent earnings distribution at
                    every level.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/automated_pricing.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Automated Payout System</h3>
                  <p>
                    With built-in automated payouts, users receive their
                    commissions and bonuses directly to their wallets or bank
                    accounts on schedule, reducing administrative effort.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "315px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/referral_and_reward_programs.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Referral & Reward Management</h3>
                  <p>
                    Fully customizable referral and reward systems to
                    incentivize users, increase retention, and encourage network
                    expansion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/real_time_monitoring.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Real-Time Dashboard & Analytics</h3>
                  <p>
                    Gain insights into your network performance with a
                    comprehensive dashboard, providing real-time data on user
                    activity, earnings, and growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/fiat_payment_gateway.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Integrated Wallet & Payment Gateways</h3>
                  <p>
                    Enable seamless transactions with integrated wallets and
                    support for multiple payment gateways, including crypto and
                    fiat currency options.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "330px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/mlm/kyc _aml_window.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>KYC/AML Compliance</h3>
                  <p>
                    Keep your platform compliant with built-in KYC (Know Your
                    Customer) and AML (Anti-Money Laundering) protocols to
                    enhance trust and security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
