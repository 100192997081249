import React from "react";

export default function PolygonEdgefor() {
  return (
    <>
      <section className="feature-style-two pt-5 pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Polygon zkEVM Development Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/exchange/wle/custom_pages.svg" />
                  </div>
                  <h3>Custom DApp Development</h3>
                  <p>
                    We create Ethereum-compatible, highly scalable DApps using
                    Polygon zkEVM, ensuring faster, cost-effective operations
                    with zero-knowledge rollup technology.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/smart_contract_development_audit.svg" />
                  </div>
                  <h3>Smart Contract Development & Audit</h3>
                  <p>
                    Xeer’s developers craft robust smart contracts and provide
                    thorough security audits, ensuring reliability and
                    efficiency on Polygon zkEVM.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/framework/SVG/zero_knowledge_rollups_implementation.svg" />
                  </div>
                  <h3>Zero-Knowledge Rollups Implementation</h3>
                  <p>
                    Our team implements zero-knowledge rollups, reducing
                    transaction costs and latency, and supporting high
                    transaction throughput.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/nft_marketplace_development.svg" />
                  </div>
                  <h3>NFT Marketplace Development</h3>
                  <p>
                    Leverage Polygon zkEVM’s efficiency with Xeer’s custom-built
                    NFT marketplaces, offering secure, scalable, and
                    cost-effective trading solutions for digital assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/framework/SVG/defi_platforms.svg" />
                  </div>
                  <h3>DeFi Solutions</h3>
                  <p>
                    We develop DeFi platforms with Polygon zkEVM, integrating
                    staking, lending, and yield farming for optimized user
                    experiences and lower transaction fees.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/listing/svg/liquidity_solutions.svg" />
                  </div>
                  <h3>Cross-Chain Solutions</h3>
                  <p>
                    Xeer Technology enables interoperability with other
                    blockchains, using Polygon zkEVM for cross-chain bridges,
                    ensuring secure asset transfers and data exchange.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
