import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import CryptoBankingHero from "./CryptoBankingHero";
import Seamless from "./Seamless";
import BankingServices from "./BankingServices";
import MarketExpansion from "./MarketExpansion";
import Essential from "./Essential";
import WhyChooseCryBanking from "./WhyChooseCryBanking";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import Together from "./Together";

export default function CryptoBanking() {
  return (
    <div>
      <Header />
      <CryptoBankingHero />
      <Seamless />
      <BankingServices />
      <MarketExpansion />
      <Essential />
      <Together />
      <WhyChooseCryBanking />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
