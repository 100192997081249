import React, { useState } from "react";

export default function Decade() {
  const [toggleTab, setToggleTab] = useState(0);
  return (
    <>
      <section className="service-section py_100">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Features You Get With Crypto Exchange</h2>
            <p className="pt-3">
              Our team will help you build a fully functional crypto exchange
              with all the necessary features.
            </p>
          </div>
          <div className="tabs-box">
            <div className="tab-btn-box p_relative mb_60 d-flex justify-content-center">
              <ul className="tab-btns tab-buttons ">
                <li
                  className={`tab-btn ${toggleTab === 0 ? "active-btn" : ""}`}
                  data-tab="#tab-1"
                  onClick={() => setToggleTab(0)}
                >
                  Fundamental Features
                </li>
                <li
                  className={`tab-btn ${toggleTab === 1 ? "active-btn" : ""}`}
                  data-tab="#tab-2"
                  onClick={() => setToggleTab(1)}
                >
                  Advanced Features
                </li>
                <li
                  className={`tab-btn ${toggleTab === 2 ? "active-btn" : ""}`}
                  data-tab="#tab-3"
                  onClick={() => setToggleTab(2)}
                >
                  Premium Features
                </li>
              </ul>
            </div>
            <div className="tabs-content centred">
              {toggleTab === 0 && (
                <div
                  className={`tab ${
                    toggleTab === 0 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-1"
                >
                  <span className="sub-title-two">Fundamental Features</span>
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/spot_trading_module.svg"
                                />
                              </div>
                              <h5 className="pt-4">Spot Trading Module</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/markets_limit_and_stop_limit_orders.svg"
                                />
                              </div>
                              <h5 className="pt-2">
                                Markets, Limit, and Stop Limit Orders
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"194px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/automated_third_party_kyc.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                Automated Third-Party KYC
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/multichain_usdt.svg"
                                />
                              </div>
                              <h5 className="pt-4">Multichain USDT</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center ">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/crypto_swapping.svg"
                                />
                              </div>
                              <h5 className="pt-4">Crypto Swapping</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"210px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/fiat_on.svg"
                                />
                              </div>
                              <h5 className="pt-5">Fiat On/Off Ramp</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/news_and_announcements.svg"
                                />
                              </div>
                              <h5 className="pt-4">News and Announcements</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/referral_and_reward_programs.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                Referral and Reward Programs
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"208px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/mobile_applications.svg"
                                />
                              </div>
                              <h5 className="pt-4">Mobile Applications</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"208px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/admin_panel.svg"
                                />
                              </div>
                              <h5 className="pt-4">Admin Panel</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/external_liquidity_module.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                External Liquidity Module
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {toggleTab === 1 && (
                <div
                  className={`tab ${
                    toggleTab === 1 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-2"
                >
                  <span className="sub-title-two">Advanced Features</span>
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"196px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/airdrop.svg"
                                />
                              </div>
                              <h5 className="pt-2">Airdrop </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"196px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/customer_support_chat.svg"
                                />
                              </div>
                              <h5 className="pt-4">Customer Support Chat </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/fiat_trading_pairs.svg"
                                />
                              </div>
                              <h5 className="pt-4">Fiat Trading Pairs</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/nft_collectables.svg"
                                />
                              </div>
                              <h5 className="pt-4">NFT Collectables</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/peer_to_peer_trading.svg"
                                />
                              </div>
                              <h5 className="pt-4">Peer-to-Peer Trading</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/admin_support_chat.svg"
                                />
                              </div>
                              <h5 className="pt-4">Admin Support Chat</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/multi_lingual_platform.svg"
                                />
                              </div>
                              <h5 className="pt-4">Multi-Lingual Platform</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/grid_trading.svg"
                                />
                              </div>
                              <h5 className="pt-4">Grid Trading</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/fiat_payment_gateway.svg"
                                />
                              </div>
                              <h5 className="pt-4">Fiat Payment Gateway</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/vip_accounts.svg"
                                />
                              </div>
                              <h5 className="pt-4">VIP Accounts</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/multi_role_access.svg"
                                />
                              </div>
                              <h5 className="pt-4">Multi-Role Access</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox"
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/social_copy_Trading.svg"
                                />
                              </div>
                              <h5 className="pt-4">Social/Copy Trading</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"195px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/automated_market_making_bot.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                Automated Market Making Bot
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "70px" }}
                                  src="assets/img/exchange/svg/native_utility_token.svg"
                                />
                              </div>
                              <h5 className="pt-4">Native/Utility Token</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {toggleTab === 2 && (
                <div
                  className={`tab ${
                    toggleTab === 2 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-3"
                >
                  <span className="sub-title-two">Premium Features</span>
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/derivatives_perpetual_trading.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                Derivatives/Perpetual Trading
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/merchant_payment_gateway.svg"
                                />
                              </div>
                              <h5 className="pt-4">Merchant Payment Gateway</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/price_alerts.svg"
                                />
                              </div>
                              <h5 className="pt-4">Price Alerts</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/staking_module.svg"
                                />
                              </div>
                              <h5 className="pt-4">Staking Module</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/crypto_betting.svg"
                                />
                              </div>
                              <h5 className="pt-4">Crypto Betting</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/trusted_device_management.svg"
                                />
                              </div>
                              <h5 className="pt-4">
                                Trusted Device Management
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/user_chat.svg"
                                />
                              </div>
                              <h5 className="pt-4">User chat</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/nft_marketplace.svg"
                                />
                              </div>
                              <h5 className="pt-4">NFT Marketplace</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/prepaid_cards.svg"
                                />
                              </div>
                              <h5 className="pt-4">Prepaid Cards</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/margin_spot_trading.svg"
                                />
                              </div>
                              <h5 className="pt-4">Margin Spot Trading</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px " }}
                                  src="assets/img/exchange/svg/lending_and_borrowing.svg"
                                />
                              </div>
                              <h5 className="pt-4">Lending and Borrowing</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/crypto_loans.svg"
                                />
                              </div>
                              <h5 className="pt-4">Crypto Loans</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/launchpad.svg"
                                />
                              </div>
                              <h5 className="pt-4">Launchpad</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="feature-block">
                          <div
                            className="feature-block-smallbox" style={{height:"200px"}}
                            data-aos="fade-up"
                            data-aos-easing="linear"
                            data-aos-duration={700}
                          >
                            <div className="inner-box">
                              <div className="icon-box">
                                <img
                                  style={{ width: "59px" }}
                                  src="assets/img/exchange/svg/crypto_arbitrage_modules.svg"
                                />
                              </div>
                              <h5 className="pt-4">Crypto Arbitrage Modules</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
