import React from 'react'
import Header from '../../Coman/Header'
import BlogsNFT from '../../NFT/BlogsNFT'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import SmartContractAuditHero from './SmartContractAuditHero'
import AchieveFlawless from './AchieveFlawless'
import WhydoNeedSmartCon from './WhydoNeedSmartCon'
import TheHighlights from './TheHighlights'
import SoliditySmartCon from './SoliditySmartCon'

export default function SmartContractAudit() {
  return (
    <div>
      <Header/>
      <SmartContractAuditHero/>
      {/* <AchieveFlawless/> */}
      {/* <WhydoNeedSmartCon/> */}
      <TheHighlights/>
      <SoliditySmartCon/>
      <BlogsNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
