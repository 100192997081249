import React from "react";

export default function P2PCryptocurrency() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
      
          <div className="feature-section pt-5 pb_150">
            <div className="auto-container">
              <div className="row text-start">
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div className="content-box mr_40">
                    <div data-animation-box className="sec-title mb_25">
                      <h2>Unleashing Power of P2P Trading</h2>
                    </div>
                    <div className="text-box">
                      <p>
                        Xeer Technology specializes in developing a peer-to-peer
                        (P2P) crypto exchange platform, tailored to meet the
                        evolving needs of the crypto marketplace.
                      </p>
                      <p>
                        Our P2P exchanges empower users to trade
                        cryptocurrencies directly with one another without the
                        need for intermediaries, ensuring greater privacy, lower
                        transaction fees, and enhanced control over assets.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                  <div className="ml_40">
                    <div className="image-box">
                      <figure className="image image-1">
                        <img
                          src="assets/img/unleashing_Power of_p2p_trading.svg"
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
