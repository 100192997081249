import React, { useState, useEffect } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

export default function ContactUs() {
  const [captchaInput, setCaptchaInput] = useState("");
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    // Load the CAPTCHA engine
    loadCaptchaEnginge(6); // You can set the number of characters here
  }, []);

  const handleChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isCaptchaValid = validateCaptcha(captchaInput);
    setIsValid(isCaptchaValid);
  };
  return (
    <>
      <section className="cta-style-two centred pb_150" id="contact-us">
        <div className="auto-container">
          <div className="inner-container">
            <div
              className="pattern-layer bg-black"
              style={{
                backgroundImage:
                  "url(assets/img/Lets-get-in-touch-Image-File.png)",
              }}
            />
            <div className="row">
              <div className="col">
                <h3 className="text-white text-start">Get in Touch with Us!</h3>
                <p className="pt-2 text-start" style={{fontSize:"18px"}}>
                  At Xeer Technology, we value your questions, feedback, and
                  project inquiries. Whether you’re looking to discuss a
                  potential collaboration, need support for an ongoing project,
                  or just want to know more about our services, we’re here to
                  help!
                </p>
                <div className="text-start">
                 
                  <p className="text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-envelope-at"
                      viewBox="0 0 16 16"
                      style={{ color: "#1a73e8" }}
                    >
                      <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                      <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                    </svg>
                    &nbsp; sales@xeertechnology.com
                  </p>
                  <p className="text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                      style={{ color: "#1a73e8" }}
                    >
                      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg>
                    &nbsp; +91 7383847632
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <p className="contact-social-link">
                    <a
                      href="https://www.facebook.com/people/Xeer-Technology/61555342661387/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook" />
                    </a>
                  </p>
                  <p className="contact-social-link">
                    <a href="https://x.com/technology_xeer" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>
                  </p>
                  <p className="contact-social-link">
                    <a
                      href="https://www.linkedin.com/company/xeer-technology/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </p>
                  <p className="contact-social-link">
                    <a
                      href="https://www.instagram.com/xeer.technology/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="contact-section ">
                  <div className="auto-container">
                    <div className="form-inner">
                      <form
                        method="post"
                        action="https://azim.hostlin.com/Sensai/sendemail.php"
                        id="contact-form"
                      >
                        <div className="row clearfix">
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input
                              type="text"
                              name="username"
                              placeholder="Your Name"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input
                              type="email"
                              name="email"
                              placeholder="Your email"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input
                              type="text"
                              name="phone"
                              required
                              placeholder="Phone"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <input
                              type="text"
                              name="subject"
                              required
                              placeholder="Subject"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea
                              name="message"
                              placeholder="Type message"
                              defaultValue=""
                            />
                          </div>
                          <div>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <LoadCanvasTemplate />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <form onSubmit={handleSubmit}>
                                  <input
                                    type="text"
                                    value={captchaInput}
                                    onChange={handleChange}
                                    placeholder="Enter Captcha"
                                  />
                                  <br />
                                  {/* <button type="submit">Submit</button> */}
                                </form>
                                {isValid !== null && (
                                  <p>
                                    {isValid
                                      ? "CAPTCHA is valid!"
                                      : "CAPTCHA is invalid. Please try again."}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                            <button
                              className="theme-btn btn-one"
                              type="submit"
                              name="submit-form"
                            >
                              Post a Comment
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
