import React from 'react'
import Header from '../Coman/Header'
import MultiCurrencyWalletHero from './MultiCurrencyWalletHero'
import Footar from '../Coman/Footar'
import Partner from './Partner'
import TwoFactor from './TwoFactor'
import Exquisite from './Exquisite'
import ReachXeer from './ReachXeer'
import ExquisiteFeatures from './ExquisiteFeatures'
import UniqueOfferings from './UniqueOfferings'
import HowDoWeDevMW from './HowDoWeDevMW'
import WhyChosseUsM from './WhyChosseUsM'
import LookingD from '../About-Us/LookingD'
import UseExisting from './UseExisting'
import Logos from './Logos'

export default function MultiCurrencyWallet() {
  return (
    <div>
        <Header/>
        <MultiCurrencyWalletHero/>
        <UseExisting/>
        {/* <Partner/> */}
        {/* <TwoFactor/> */}
        <Logos/>
        <Exquisite/>
        <ReachXeer/>
        {/* <ExquisiteFeatures/> */}
        {/* <UniqueOfferings/> */}
        {/* <HowDoWeDevMW/> */}
        <WhyChosseUsM/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
