import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import MarginTradingExchangeHero from './MarginTradingExchangeHero'
import AmplifyRevenue from './AmplifyRevenue'
import LeverageAndMargin from './LeverageAndMargin'
import TradingExchange from './TradingExchange'
import SecurityPractices from './SecurityPractices'
import RiskManagement from './RiskManagement'
import MarginTradingBlog from './MarginTradingBlog'
import WhatISMargin from './WhatISMargin'
import LookingD from '../About-Us/LookingD'

export default function MarginTradingExchange() {
  return (
    <>
     <Header/>
     <MarginTradingExchangeHero/>
     <WhatISMargin/>
     <AmplifyRevenue/>
     <LeverageAndMargin/>
     <TradingExchange/>
     <SecurityPractices/>
     {/* <RiskManagement/> */}
     <MarginTradingBlog/>
     <LookingD/>
     <Footar/> 
    </>
  )
}
