import React from "react";

export default function CosmosNadcab() {
  return (
    <>
      <section className="about-style-three p_50">
        <div className="auto-container">
          <div data-animation-box className="sec-title text-center mb_55">
            <h2>Our Cosmos SDK Development Services</h2>
          </div>

          <div className="content_block_four">
            <div className="content-box ml_40">
              <div className="inner-box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-item my_10">
                      <div className="icon-box">
                        <img src="assets/img/wallet/mwa/customized_solutions.svg" />
                      </div>
                      <h3>Custom Blockchain Development</h3>
                      <p>
                        Xeer Technology offers end-to-end Cosmos SDK blockchain
                        development services, enabling businesses to build
                        custom blockchains optimized for security, speed, and
                        scalability. Whether you need a decentralized
                        application or a standalone blockchain, we deliver
                        solutions that align with your business goals.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/exchange/se/tokenization_solutions.svg" />
                      </div>
                      <h3>Interoperability Solutions</h3>
                      <p>
                        Our team specializes in implementing the
                        Inter-Blockchain Communication (IBC) protocol, ensuring
                        seamless interoperability between your blockchain and
                        other networks within the Cosmos ecosystem.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/asset-tok/svg/defi_and_staking_management.svg" />
                      </div>
                      <h3>Validator Node Setup & Management</h3>
                      <p>
                        We provide reliable validator node setup and management
                        services to secure the Cosmos blockchain network. Xeer’s
                        experienced team configures, monitors, and maintains
                        validator nodes, ensuring high uptime and performance.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/smart-con/smart_contract_integration.svg" />
                      </div>
                      <h3>Smart Contract Development</h3>
                      <p>
                        Our Cosmos SDK developers design and deploy secure smart
                        contracts tailored to execute complex workflows and
                        automate business logic. Using Cosmos’s robust
                        framework, we enhance the functionality of your
                        decentralized application.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/framework/SVG/bridge.svg" />
                      </div>
                      <h3>Cross-Chain Bridge Development</h3>
                      <p>
                        Xeer Technology facilitates cross-chain compatibility by
                        building secure bridges that connect blockchains,
                        enabling seamless asset transfer and data exchange
                        across networks, enhancing your ecosystem’s scalability
                        and reach.
                      </p>
                    </div>
                  </div>
                
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/blockchain-dev/SVG/crypto_wallet_development.svg" />
                      </div>
                      <h3>Wallet Development</h3>
                      <p>
                        We design secure, user-friendly digital wallets
                        compatible with Cosmos and other Tendermint-based
                        chains. Our wallets ensure safe and accessible storage,
                        transfer, and management of digital assets for users.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/framework/SVG/sdk.svg" />
                      </div>
                      <h3>Cosmos SDK Consultation</h3>
                      <p>
                        Leverage our Cosmos SDK consulting services to design
                        strategies that maximize the potential of your
                        blockchain project. Our team assists with architecture
                        design, security protocols, and performance optimization
                        to ensure long-term success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
