import React from "react";

export default function Striking() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Key Features & Functionalities of Our IDO Development Service
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "340px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/custom_smart_contract_development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Custom Smart Contract Development</h3>
                  <p>
                    We create secure, flexible smart contracts tailored to your
                    token’s requirements, ensuring smooth transactions and fair
                    token allocation during the IDO.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "340px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/rigorous_security_audits.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Rigorous Security Audits</h3>
                  <p>
                    Our team conducts in-depth audits, ensuring that all
                    contracts and processes meet top security standards to
                    protect investors and build trust in your project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "340px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/optimized_tokenomics_design.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Optimized Tokenomics Design</h3>
                  <p>
                    We work closely with you to design sustainable tokenomics
                    that support long-term growth, integrating effective
                    strategies for token allocation, liquidity, and investor
                    incentives.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/platform_integration_dex_compatibility.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Platform Integration & DEX Compatibility</h3>
                  <p>
                    Our development ensures compatibility with major DEXs,
                    facilitating a smooth IDO launch and helping to build
                    liquidity for your token on leading decentralized exchanges.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "350px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/comprehensive_marketing_community_support.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Comprehensive Marketing & Community Support</h3>
                  <p>
                    Beyond technical support, we provide marketing strategies
                    and community-building services to drive awareness and
                    engagement, creating a strong foundation for IDO success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
