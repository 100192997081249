import React from "react";

export default function SocialMediaMarketing() {
  return (
    <>
      <section className="feature-section p_50" style={{background:"url(assets/img/03.png",padding:"30px"}} >
        <div className="auto-container">
          <h1 className="text-center">Social Media Marketing</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/marketing/social_media_marketing.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <p className="py-3">
                    <h4>Build a Strong Social Presence Across Platforms </h4>
                    Our social media experts design platform-specific strategies
                    to build, grow, and engage communities across popular
                    networks. We create and distribute content that drives
                    interactions and grows your follower base organically.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Channel Management</h4>
                      <p>
                        Comprehensive management across major social platforms.
                      </p>
                    </li>
                    <li>
                      <h4>Engagement Strategy</h4>
                      <p>
                        Craft campaigns that encourage audience interaction.
                      </p>
                    </li>
                    <li>
                      <h4>Trend Monitoring</h4>
                      <p>
                        Stay ahead with the latest trends in the crypto space. .
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Enhance Your Social Media Presence
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
