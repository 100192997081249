import React from 'react'
import Header from '../Coman/Header'
import Hero from './Hero'
import OurServices from './OurServices'
import OurTechStack from './OurTechStack'
import Industries from './Industries'
import Footar from '../Coman/Footar'
import Blog from './Blog'
import GetInTouch from './GetInTouch'
import HowDoWork from './HowDoWork'
import ContactUs from '../Coman/ContactUs'
import Integrations from './Integrations'
import BlockchainOffer from './BlockchainOffer'
import BlockchainProcess from './BlockchainProcess'
import TechnologiesweWork from './TechnologiesweWork'
import TechnologyOffers from './TechnologyOffers'
import BlockchainSolutions from './BlockchainSolutions'



export default function Index() {
  return (
    <>
       <div class="boxed_wrapper ltr">
        <Header/>
        <Hero/>
        <OurServices/>
        <BlockchainOffer/>
        {/* <OurTechStack/> */}
        <Industries/>
        <TechnologyOffers/>
        <Integrations/>
        <BlockchainSolutions/>
        <HowDoWork/>
        {/* <BlockchainProcess/> */}
        {/* <GetInTouch/> */}
        <TechnologiesweWork/>
        <Blog/>
        <ContactUs/>
        <Footar/>
       </div>
    </>
  )
}
