import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import SemiFungibleToken from './SemiFungibleToken'
import SemiFungible from './SemiFungible'
import AntierSemi from './AntierSemi'
import SemiBenefits from './SemiBenefits'
import SpotlightInsights from './SpotlightInsights'
import LookingD from '../About-Us/LookingD'
import ReadyTransform from './ReadyTransform'

export default function SemiToken() {
  return (
    <div>
      <Header/>
      <SemiFungibleToken/>
      <SemiFungible/>
      <SemiBenefits/>
      <AntierSemi/>
      <ReadyTransform/>
      <SpotlightInsights/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
