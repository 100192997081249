import React from "react";

export default function BuildARobust() {
  return (
    <>
      <section
        className="clients-section alternat-2 pt_40 pb_40 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Ready to Launch Your NFT Collection?</h2>
            <p className="text-white py-3">
              Don’t miss out on the opportunity to capitalize on the booming NFT
              market. With Xeer Technology’s comprehensive NFT collection launch
              service, your project is in trusted hands. Get in touch with us
              today to begin your journey into the world of NFTs!
            </p>
          </div>
          <a href="/" className="theme-btn btn-one mt_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
