import React from "react";

export default function BusinessGoals() {
  return (
    <>
      <section className="page-title centred pt_120 pb_150">
        <div className="auto-container">
          <div className="content-box">
            <div data-animation-box className="sec-title ">
              <h2>How Our Development Approach Sets Us Apart</h2>
            <p>
              At Xeer Technology, our development approach is centered on
              delivering innovative, secure, and scalable DeFi solutions that
              drive long-term success. Here’s how we approach every project:
            </p>
            </div>
          </div>
          <div className="py-5">
            <img src="assets/img/defi/defi-dev/how_our_development_approach_sets.svg" />
          </div>
        </div>
      </section>
    </>
  );
}
