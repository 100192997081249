import React from "react";

export default function CosmosHero() {
  return (
    <div>
      <section
       className="feature-section p_150" style={{
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundImage:
          "url(assets/img/framework/Cosmos-SDK-Development-Services-Hero-Section-Image.jpg)",
      }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2 className="text-white">
                    Cosmos SDK Development Services
                  </h2>
                </div>
                <p className="text-white">
                  As a leading Cosmos SDK development company, Xeer Technology
                  empowers businesses with scalable, secure, and interoperable
                  blockchain solutions.
                </p>
              </div>
              <div className="btn-box pt-5">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Hire Blockchain Expert
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
