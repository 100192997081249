import React from "react";

export default function PolygonLabs() {
  return (
    <div>
      <section
        className="clients-section alternat-2 pt_40 pb_40 centred"
        style={{ backgroundColor: "rgb(7, 21, 34)" }}
      >
        <div className="auto-container">
          <div className>
            <h1 className="text-white">
            Get Started with Xeer Technology
            </h1>
            <p className="text-white p-4">
            With a specialized approach to zkEVM and scaling solutions, Xeer Technology helps you leverage Polygon’s power to drive your blockchain project forward. Partner with Xeer to build secure, efficient, and scalable blockchain solutions on Polygon zkEVM today.

            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
