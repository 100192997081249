import React from "react";

export default function ReachXeer() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light">
              <h2>Launch Your Crypto Wallet Quickly</h2>
              <p>
                With Xeer Technology’s Crypto Wallet Clone app, you can quickly
                enter the market with a cost-effective solution that delivers a
                superior user experience, scalability, and future-proof
                technology. Whether you’re building a wallet for personal use,
                institutional clients, or as part of your broader DeFi strategy,
                our clone solutions offer the perfect foundation.
              </p>
            </div>
            <a href="/contact-us" className="theme-btn btn-one m_20">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
