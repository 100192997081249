import React from "react";

export default function TypesCrypto() {
  return (
    <>
      <section className="integrations-section centred pb_150">
        <div className="">
          <div className="sec-title mb_60">
            <div className="inner_wrap">
              <h2>Our Crypto Exchange Platform Services</h2>
              <p className="pt-2">
                Xeer Technology offers a comprehensive crypto exchange
                development service tailored for your needs. Weather a 
                centralized crypto exchange or decentralized exchange, <br /> our 360
                degree exchange development services suit best for your
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/crypto_exchange.svg"
                      />
                    </div>
                    <h5 className="pt-4">Centralized Crypto Exchange (CEX)</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/svg/spot_margin_&_futures_trading.svg"
                      />
                    </div>
                    <h5 className="pt-4">Margin Trading Exchange</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/svg/derivatives_perpetual_trading.svg"
                      />
                    </div>
                    <h5 className="pt-4">Derivatives Trading Exchange</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/dse/futures_&_options_trading.svg"
                      />
                    </div>
                    <h5 className="pt-4">Futures & Options Trading Exchange</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/decentralized.svg"
                      />
                    </div>
                    <h5 className="pt-4">Decentralized Exchange (DEX)</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/svg/peer_to_peer_trading.svg"
                      />
                    </div>
                    <h5 className="pt-4">Peer-to-peer (P2P) Exchange</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox" style={{height:"213px"}}
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/exchange/svg/multi_lingual_platform.svg"
                      />
                    </div>
                    <h5 className="pt-4">OTC Platform</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
