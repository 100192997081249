import React from "react";

export default function BringsNext() {
  return (
    <>
      <section className="feature-section p_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_50 text-center">
            <h2>Crypto Coin & Token Development by Xeer Technology</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div>
                  <h3>
                    Unlock the Potential of Blockchain with Our Expert Solutions
                  </h3>
                  <p className="py-3">
                    Xeer Technology specializes in comprehensive Crypto Coin and
                    Token Development Services designed to help businesses and
                    entrepreneurs harness the power of blockchain technology.
                    Whether you need a unique cryptocurrency or a specialized
                    token, our team of experienced developers is here to guide
                    you through the entire process.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix pt-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <li>Custom Coin Development</li>
                        <li>Token Development</li>
                        <li>Token Smart Contract Development</li>
                        <li>Tokenomics Design</li>
                      </div>
                      <div className="col-lg-6">
                        <li>Crypto Coin Consultation</li>
                        <li>Token or Coin Integration</li>
                        <li>Security Audits </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  src="assets/img/coin-dev/crypto-coin-token-development-by-xeer-technology.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
