import React from 'react'
import Header from '../../Coman/Header'
import BlogWeb from '../../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import TokenListingHero from './TokenListingHero'
import TokenListingServices from './TokenListingServices'
import DetailedListing from './DetailedListing'

export default function TokenListing() {
  return (
    <div>
      <Header/>
      <TokenListingHero/>
      <TokenListingServices/>
      <DetailedListing/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
