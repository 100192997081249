import React from 'react'
import Header from '../../Coman/Header'
import Layer0SolutionHero from './Layer0SolutionHero'
import Layer0Blockchain from './Layer0Blockchain'
import Layer0BlockchainTechnology from './Layer0BlockchainTechnology'
import Layer0BlockchainAsked from './Layer0BlockchainAsked'
import BlogsNFT from '../../NFT/BlogsNFT'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import Fundamental from './Fundamental'

export default function Layer1Solution() {
  return (
    <>
        <div class="boxed_wrapper ltr">
        <Header/>
        <Layer0SolutionHero/>
        <Layer0Blockchain/>
        <Layer0BlockchainTechnology/>
        <Fundamental/>
        <Layer0BlockchainAsked/>
        <BlogsNFT />
        <LookingD />
        <Footar />
        </div>
    </>
  )
}
