import React from 'react'
import Header from '../../Coman/Header'
import BlogsNFT from '../../NFT/BlogsNFT'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import AssetTokenizationHero from './AssetTokenizationHero'
import MaximizeValue from './MaximizeValue'
import ModulesofAsset from './ModulesofAsset'
import WhyTokenize from './WhyTokenize'
import TechnologyStack from './TechnologyStack'

export default function AssetTokenization() {
  return (
    <div>
      <Header/>
      <AssetTokenizationHero/>
      <MaximizeValue/>
      <WhyTokenize/>
      <ModulesofAsset/>
      <TechnologyStack/>
      <BlogsNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
