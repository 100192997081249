import React from "react";

export default function WCDeFiLottery() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology for DeFi Lottery Platform Development?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
              <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    Choose Xeer Technology to transform your DeFi lottery
                    platform vision into reality with a powerful, user-focused,
                    and future-ready solution that captivates and retains users.
                    Partner with us to redefine lottery gaming in the DeFi
                    space, offering transparency, fairness, and exciting new
                    possibilities.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Blockchain Expertise</h4>
                      <p>
                        Our team brings extensive experience in developing
                        decentralized finance solutions, ensuring your staking
                        platform is secure, scalable, and future-proof.
                      </p>
                    </li>
                    <li>
                      <h4>User-Centric Design</h4>
                      <p>
                        We prioritize creating a seamless, intuitive user
                        experience that makes it easy for both new and
                        experienced users to engage with your platform.
                      </p>
                    </li>
                    <li>
                      <h4>End-to-End Development</h4>
                      <p>
                        From initial concept design to post-launch support, we
                        handle every aspect of the development process to ensure
                        the success of your DeFi staking platform.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
