import React from "react";
import NFTartH from "./NFTartH";
import Header from "../Coman/Header";
import BlogsNFT from "../NFT/BlogsNFT";
import Footar from "../Coman/Footar";
import TransformArt from "./TransformArt";
import ArtMarkCon from "./ArtMarkCon";
import GetRtoBY from "./GetRtoBY";
import IntuitiveStorefront from "./IntuitiveStorefront";
import Enlisting from "./Enlisting";
import EarnBig from "./EarnBig";
import LookingD from "../About-Us/LookingD";

export default function NFTART() {
  return (
    <>
      <Header />
      <NFTartH />
      {/* <TransformArt/> */}
      <ArtMarkCon/>
      {/* <GetRtoBY/> */}
      {/* <IntuitiveStorefront/> */}
      {/* <Enlisting/> */}
      {/* <EarnBig/> */}
      <BlogsNFT />
      <LookingD/>
      <Footar />
    </>
  );
}
