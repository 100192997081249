import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CosmosHero from "./CosmosHero";
import CosmosSDK from "./CosmosSDK";
import ApplicationArchitecture from "./ApplicationArchitecture";
import BlockchainCommunication from "./BlockchainCommunication";
import CosmosSDKlaunch from "./CosmosSDKlaunch";
import CosmosSuite from "./CosmosSuite";
import CosmosNadcab from "./CosmosNadcab";

export default function Cosmos() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <CosmosHero />
        <CosmosSDK />
        {/* <ApplicationArchitecture /> */}
        {/* <BlockchainCommunication/> */}
        <CosmosNadcab/>
        <CosmosSuite/>
        <CosmosSDKlaunch/>
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
