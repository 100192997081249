import React from "react";

export default function CustomLayer2Wallet() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="text-center">
              Benefits of Cardano Development with Xeer Technology
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Eco-Friendly and Secure</h4>
                      <p>
                        Cardano’s PoS mechanism provides a sustainable, secure
                        platform for decentralized solutions.
                      </p>
                    </li>
                    <li>
                      <h4>Peer-Reviewed Foundation</h4>
                      <p>
                        Built on academic rigor, Cardano’s architecture ensures
                        reliability and long-term scalability.
                      </p>
                    </li>
                    <li>
                      <h4>End-to-End Solutions</h4>
                      <p>
                        Xeer Technology offers full-cycle development, from
                        concept to deployment, maintenance, and support.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
