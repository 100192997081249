import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import TezosHero from "./TezosHero";
import TezosLeads from "./TezosLeads ";
import HowtoGetTez from "./HowtoGetTez";
import BuildTezos from "./BuildTezos";
import SmartContracts from "./SmartContracts";

export default function Tezos() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <TezosHero />
        <TezosLeads />
        <HowtoGetTez />
        {/* <BuildTezos /> */}
        <SmartContracts />
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
