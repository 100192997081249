import React, { useState } from "react";

export default function WhyChosseUsM() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      {/* <section className="faq-style-three pt_100 pb_140">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-44.png)" }}
        />
        <div
          className="pattern-layer-2"
          style={{ backgroundImage: "url(assets/images/shape/shape-70.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <h3 className="" style={{ fontSize: "40px" }}>
              Why Choose Us for Multicurrency Wallet Development?
            </h3>
            <p>
              Xeer is a trusted IT partner that delivers enterprise-graded and
              futuristic multicurrency wallet development solutions. In
              addition, we provide unparalleled service accessibility and assist
              enterprises in achieving their goals.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30">
                <ul className="accordion-box">
                  <li className="accordion block active-block">
                    <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(0)} />
                      <h4>Blockchain Pioneers</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 0 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          With years of experience in blockchain technology, we
                          are industry pioneers with extensive expertise. We do
                          one thing, and we do it well.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(1)} />
                      <h4>End-to-End Services </h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 1 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We provide end-to-end services covering everything
                          from ideation and development to deployment with the
                          utmost professionalism and expertise.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(2)} />
                      <h4>Competitive Pricing </h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 2 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We offer competitive pricing structures that ensure
                          you receive exceptional value for your investment
                          without compromising quality.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(3)} />
                      <h4>Customization Excellence</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 3 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We understand that no two businesses are the same, nor
                          are their needs. We excel at tailoring solutions to
                          your unique requirements and vision.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(4)} />
                      <h4>24/7 Support</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 4 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          We offer unwavering, ongoing support with 24/7
                          availability to ensure your blockchain solutions'
                          longevity and optimal performance.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(5)} />
                      <h4>Security-Centric Approach</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 5 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Security is paramount in the crypto space so we adopt
                          a security-first mindset, implementing industry best
                          practices to safeguard your digital assets.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_30">
                <figure className="image">
                  <img src="assets/images/resource/faq-1.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology for Crypto Wallet Clone App Development?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    We prioritize utilizing robust encryption and advanced
                    security protocols to ensure your crypto wallet clone app
                    operates securely across various blockchain networks. Our
                    scalable architecture supports high-volume transactions,
                    providing a smooth and reliable experience for users. With
                    Xeer Technology’s end-to-end crypto wallet clone app
                    development services, you can trust us to deliver a fully
                    customizable, user-friendly, and future-proof solution that
                    mirrors the features of top wallets while incorporating
                    additional functionalities to enhance performance and user
                    satisfaction.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Dedicated Support</h4>
                      <p>
                        Our team provides round-the-clock support, ensuring that
                        any issues or questions are promptly addressed to keep
                        your platform running smoothly.
                      </p>
                    </li>
                    <li>
                      <h4>Experienced Team</h4>
                      <p>
                        With years of expertise in blockchain and exchange
                        development, our skilled developers create reliable,
                        high-performance margin trading platforms tailored to
                        your needs.
                      </p>
                    </li>
                    <li>
                      <h4>Standard Development Practice</h4>
                      <p>
                        We follow industry-standard development practices,
                        ensuring secure, compliant, and scalable platforms with
                        robust functionality and future-ready infrastructure.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for Crypto Wallet Clone App Development?
            </h2>
            <p className="p-3">
              We prioritize utilizing robust encryption and advanced
              security protocols to ensure your crypto wallet clone app
              operates securely across various blockchain networks. Our
              scalable architecture supports high-volume transactions,
              providing a smooth and reliable experience for users. With
              Xeer Technology’s end-to-end crypto wallet clone app
              development services, you can trust us to deliver a fully
              customizable, user-friendly, and future-proof solution that
              mirrors the features of top wallets while incorporating
              additional functionalities to enhance performance and user
              satisfaction.
            </p>

          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/dedicated_support.svg" />
                  </div>
                  <h3 className="pt-4">Dedicated Support</h3>
                  <p>
                    Our team provides round-the-clock support, ensuring that any
                    issues or questions are promptly addressed to keep your
                    platform running smoothly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/experienced_team.svg" />
                  </div>
                  <h3 className="pt-4">Experienced Team</h3>
                  <p>
                    With years of expertise in blockchain and exchange
                    development, our skilled developers create reliable,
                    high-performance margin trading platforms tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/standard_development_practice.svg" />
                  </div>
                  <h3 className="pt-4">Standard Development Practice</h3>
                  <p>
                    We follow industry-standard development practices, ensuring
                    secure, compliant, and scalable platforms with robust
                    functionality and future-ready infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
