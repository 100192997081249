import React from "react";

export default function AppWeUSE() {
  return (
    <>
      <section
        className="cta-style-two centred bg-black"
        style={{ paddingBlockEnd: "5%", paddingTop: "5%" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <div
              className="pattern-layer"
              style={
                {
                  // backgroundImage: "url(assets/img/O55GQZ0.jpg)",
                }
              }
            />
            <p>
              By leveraging an extensive array of blockchain protocols,
              development frameworks & advanced design tools, our skilled team
              at Xeer Technology provides customized NFT game development
              solutions designed to meet your specific needs!
            </p>
            <div>
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/ps-icon.png.webp" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/Logo_Blender.png.webp" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/pinpng.com-maya-logo-png-1979034-1.png.webp" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/pngaaa.png.webp" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/Unity_Technologies_logo.png.webp" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <img src="https://www.antiersolutions.com/wp-content/uploads/2022/07/pngwing.png.webp" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
