import React from "react";
import Header from "../Coman/Header";
import CryptoLaunchpadHero from "./CryptoLaunchpadHero";
import Footar from "../Coman/Footar";
import Journey from "./Journey";
import ICOLaunchpad from "./ICOLaunchpad";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import WinWin from "./WinWin";
import WhiteLCryL from "./WhiteLCryL";
import KnowYour from "./KnowYour";
import WhatMakes from "./WhatMakes";

export default function CryptoLaunchpad() {
  return (
    <div>
      <Header />
      <CryptoLaunchpadHero />
      <Journey />
      <ICOLaunchpad />
      <KnowYour />
      <WinWin />
      <WhiteLCryL />
      <WhatMakes />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
