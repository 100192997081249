import React from "react";

export default function SolanaServices() {
  return (
    <div>
      <section className="service-style-two  mt-5">
        <div className="container px-0 px-md-2">
          <div className="inner-contr pt-5">
            <div />
            <div
              data-animation-box="true"
              className="sec-title mb_55 centred animated"
            >
              <h2>Xeer's Solana Blockchain Development Services</h2>
              <p className="pt-3">
                With a team experienced in leveraging Solana’s features, Xeer
                Technology delivers comprehensive development solutions tailored
                to your business needs.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/solana_dapp_development.svg" style={{width:"45px"}} />
                    </div>
                    <h3>Solana DApp Development</h3>
                    <p>
                      We create powerful decentralized applications on Solana
                      that support high-speed transactions and low fees,
                      providing an efficient, scalable user experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/solana_defi_development.svg" style={{width:"45px"}} />

                    </div>
                    <h3>Solana DeFi Development</h3>
                    <p>
                      Xeer Technology builds innovative DeFi platforms on
                      Solana, including staking platforms, lending protocols,
                      and yield farming solutions that offer reliable,
                      cost-effective services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/smart_contract_development.svg" style={{width:"45px"}} />

                    </div>
                    <h3>Smart Contract Development</h3>
                    <p>
                      Our developers write secure and high-performance smart
                      contracts in Rust for the Solana network, ensuring
                      efficiency and adherence to Solana's standards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/nft_marketplacedevelopment.svg" style={{width:"45px"}} />

                    </div>
                    <h3>NFT Marketplace Development</h3>
                    <p>
                      Create engaging NFT marketplaces on Solana with Xeer,
                      designed for fast, affordable trading, minting, and
                      management of NFTs with user-friendly interfaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/solana_wallet_development.svg" style={{width:"45px"}} />

                    </div>
                    <h3>Solana Wallet Development</h3>
                    <p>
                      We develop robust crypto wallets tailored for Solana,
                      allowing users to securely store, send, and receive SOL
                      and other tokens.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/token_development.svg" style={{width:"45px"}} />

                    </div>
                    <h3>Token Development (SPL Tokens)</h3>
                    <p>
                      Xeer Technology specializes in SPL token creation on
                      Solana, helping you develop and launch tokens for diverse
                      use cases like DeFi, NFTs, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"350px"}}>
                    <div className="icon-box">
                    <img src="assets/img/blockchain_integration.svg" style={{width:"45px"}} />

                    </div>
                    <h3>Blockchain Integration</h3>
                    <p>
                      We assist businesses in integrating Solana into their
                      existing systems, making it easier to adopt Solana's
                      blockchain infrastructure for enhanced performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
