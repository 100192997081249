import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function SoliditySmartCon() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
     
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Xeer Technology’s Smart Contract Audit Process</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow"
                data-wow-delay="0s"
                style={{
                  height:"185px",
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box">
                      <h1>01</h1>
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Code Review</h4>
                      <p className="text-start">
                        We conduct a thorough manual and automated review of
                        your code to identify potential vulnerabilities and
                        ensure high-quality code standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow"
                data-wow-delay="0s"
                style={{
                  height:"185px",
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box">
                      <h1>02</h1>
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Security Analysis</h4>
                      <p className="text-start">
                        Our team examines your contract for security risks,
                        including reentrancy attacks, overflow vulnerabilities,
                        and unauthorized access points, providing a clear
                        roadmap for remediation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="box-white-wrap4-tow"
                data-wow-delay="0s"
                style={{
                  height:"185px",
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box">
                      <h1>03</h1>
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">Functional Testing</h4>
                      <p className="text-start">
                        Through comprehensive functional testing, we evaluate
                        your contract’s performance in different scenarios,
                        ensuring that it operates as intended under all
                        conditions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="box-white-wrap4-tow"
                data-wow-delay="0s"
                style={{
                  height:"185px",
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="num-box">
                      <h1>04</h1>
                    </div>
                  </div>
                  <div className="col">
                    <div className="fugu--iconbox-data">
                      <h4 className="text-start">
                        Optimization Recommendations
                      </h4>
                      <p className="text-start">
                        Our audit includes recommendations to improve contract
                        efficiency, helping to minimize gas costs and optimize
                        performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
