import React from "react";

export default function Strategic() {
  return (
    <>
      <section className="faq-style-three p_50">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_40">
                <figure className="image">
                  <img style={{width:"80%"}}
                    src="assets/img/defi/Unleash-The-Power-of-Decentralized-Finance-With-Launching-Lending-Borrowing-Platform.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="pt-2">
                <h1>
                  Unleash The Power of Decentralized Finance With Launching
                  Lending Borrowing Platform
                </h1>
                <p>
                  Our DeFi lending and borrowing platform development services
                  enable you to leverage the power of decentralized finance,
                  providing your users with access to secure, peer-to-peer
                  financial services. By removing intermediaries and offering
                  smart contract-based lending solutions, you can deliver
                  unparalleled benefits like instant loan approvals, dynamic
                  interest rates, and flexible collateral management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
