import React from "react";

export default function TheAverage() {
  return (
    <>
      <section className="glance-section centred p_150">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Launch Your NFT Collection?</h2>
              <p>
                NFTs have become a global phenomenon, offering creators an
                innovative way to monetize their work while providing buyers
                with verifiable ownership of digital assets. Whether you're a
                digital artist, musician, game developer, or brand, launching an
                NFT collection unlocks new revenue streams and strengthens
                community engagement.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
