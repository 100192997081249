import React from 'react'
import Header from '../Coman/Header'
import DefiWalletHero from './DefiWalletHero'
import Footar from '../Coman/Footar'
import Remunerative from './Remunerative'
import Comprehensive from './Comprehensive'
import SaveBig from './SaveBig'
import DefaultFeatures from './DefaultFeatures'
import Launch from './Launch'
import Potential from './Potential'
import Render from './Render'
import Capabilities from './Capabilities'
import Fintech from './Fintech'
import LookingD from '../About-Us/LookingD'

export default function DefiWallet() {
  return (
    <div>
      <Header/>
      <DefiWalletHero/>
      <Remunerative/>
      <Comprehensive/>
      <SaveBig/>
      {/* <DefaultFeatures/> */}
      {/* <Launch/> */}
      {/* <Potential/> */}
      {/* <Capabilities/> */}
      <Fintech/>
      <Render/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
