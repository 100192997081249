import React from "react";

export default function LaunchYourDeFi() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">Launch Your DeFi Platform Today</h1>
            <p className="text-white py-3">
              Join the decentralized finance revolution with Xeer Technology’s
              expert development services. We help you create innovative,
              secure, and scalable DeFi lending and borrowing platforms that
              deliver value to your users and drive growth for your business.
            </p>
          </div>
          <a href="#" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
