import React from "react";

export default function Arts() {
  return (
    <>
      <section className="contact-info-section centred pt_80 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>NFT Development Services</h2>
            <p className="pt-3">
              The NFT development process involves several key stages that
              transform your concept into a fully functional non-fungible token,
              ready for deployment on the blockchain. Here's an overview of the
              steps involved:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/conceptualization_consultation.svg"
                      height={60}
                      width={60}
                    />
                  </div>
                  <h3>Conceptualization & Consultation</h3>
                  <p>
                    The process begins with understanding your vision and
                    objectives for the NFT project. Whether you’re creating
                    digital art, tokenizing real-world assets, or building an
                    NFT-based platform, we work with you to refine the idea and
                    outline the technical requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/blockchain_selection.svg"
                      height={60}
                      width={60}
                    />
                  </div>
                  <h3>Blockchain Selection</h3>
                  <p>
                    Based on your project’s needs, we help you select the most
                    suitable blockchain network (e.g., Ethereum, Binance Smart
                    Chain, Solana, Polygon). This decision depends on factors
                    like transaction speed, cost, scalability, and the target
                    audience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "400px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/nft_design_metadata_creation.svg"
                      height={60}
                      width={60}
                    />
                  </div>
                  <h3>NFT Design & Metadata Creation</h3>
                  <p>
                    Our team collaborates with you to design custom NFTs,
                    focusing on the visual elements, asset attributes, and
                    metadata (information that defines the NFT’s unique
                    properties, such as its name, description, and traits). This
                    ensures your NFTs are one-of-a-kind and recognizable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/smart_contract_development.svg"
                      height={60}
                      width={60}
                    />{" "}
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    We develop secure, tamper-proof smart contracts that handle
                    the minting, ownership transfers, and other functionalities
                    of your NFTs. We ensure compliance with leading token
                    standards like ERC-721, ERC-1155, or other relevant
                    protocols.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/nft_minting.svg"
                      height={60}
                      width={60}
                    />{" "}
                  </div>
                  <h3>NFT Minting</h3>
                  <p>
                    Once the design and smart contract are ready, the NFTs are
                    minted (created) on the chosen blockchain. Each minted NFT
                    is linked to its corresponding metadata and becomes a
                    verifiable digital asset that can be traded, sold, or
                    transferred.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="">
                    <img
                      src="assets/img/nft_dev/launch_support.svg"
                      height={60}
                      width={60}
                    />{" "}
                  </div>
                  <h3>Launch & Support</h3>
                  <p>
                    After successful testing, your NFTs are deployed to the
                    blockchain and made available to the public. We provide
                    post-launch support to ensure smooth operation and address
                    any issues that arise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
