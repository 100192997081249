import React from "react";

export default function WhatISMargin() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is Margin Trading Exchange?</h2>
              <p>
                A Margin Trading Exchange is a platform that allows users to
                borrow funds to trade assets with more capital than they
                currently own. This practice of margin trading, lets traders
                open larger positions by using leverage, which increases both
                potential gains and losses. The additional capital is typically
                provided by the exchange.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
