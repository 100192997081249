import React from 'react'
import Header from '../Componet/Coman/Header'
import Footar from '../Componet/Coman/Footar'
import SecurityExchangeHero from './SecurityExchangeHero'
import SecurityToken from './SecurityToken'
import SecurityDevelopment from './SecurityDevelopment'
import STOExchange from './STOExchange'
import WhatisSTE from './WhatisSTE'
import LookingD from '../Componet/About-Us/LookingD'

export default function SecurityExchange() {
  return (
    <div>
      <Header/>
      <SecurityExchangeHero/>
      <WhatisSTE/>
      <SecurityToken/>
      <SecurityDevelopment/>
      <STOExchange/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
