import React from "react";

export default function OurCentralized() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title my_55">
            <h2>Features of our Crypto Derivatives Exchange</h2>
            <p>
              These features combine security, scalability, and regulatory
              compliance while supporting high-frequency trading and leverage,
              which are crucial for a successful crypto derivatives exchange.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/high_performance_matching_engine.svg" />
                  </div>
                  <h3>High-Performance Matching Engine</h3>
                  <p>
                    The core component that processes buy and sell orders at
                    lightning speed to ensure quick and accurate trade
                    execution. Developers need to build a robust, scalable
                    matching engine capable of handling high-frequency trading
                    and large transaction volumes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"412px"}}>
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/multi_tier_security_architecture.svg" />
                  </div>
                  <h3>Multi-Tier Security Architecture</h3>
                  <p>
                    Incorporating strong security protocols, such as encryption,
                    two-factor authentication (2FA), and multi-signature
                    wallets, to protect user accounts, assets, and data.
                    Advanced threat detection systems and secure coding
                    practices are essential to prevent breaches and
                    vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/leverage_and_margin_management.svg" />
                  </div>
                  <h3>Leverage and Margin Management</h3>
                  <p>
                    Designing an efficient margin and leverage system that
                    calculates risk, ensures proper collateral management, and
                    triggers margin calls or liquidations when necessary. This
                    includes integrating automated margin adjustment tools for
                    real-time accuracy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"441px"}}>
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/smart_contract_integration.svg" />
                  </div>
                  <h3 className="pb-4">Smart Contract Integration</h3>
                  <p>
                    Developing and deploying smart contracts to manage
                    derivatives products like futures, options, and perpetual
                    swaps. These contracts ensure transparency, automation, and
                    enforceability of trading terms without intermediaries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"441px"}}>
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/api_integration_for_liquidity_and_trading_bots.svg" />
                  </div>
                  <h3>API Integration for Liquidity and Trading Bots</h3>
                  <p>
                    Providing secure, well-documented APIs for integration with
                    third-party trading bots, liquidity providers, and market
                    makers. This helps boost exchange liquidity, enhances
                    automated trading strategies, and facilitates external
                    application interactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/comprehensive_risk_and_compliance_framework.svg" />
                  </div>
                  <h3>Comprehensive Risk and Compliance Framework</h3>
                  <p>
                    Implementing KYC/AML (Know Your Customer/Anti-Money
                    Laundering) procedures, ensuring compliance with regulatory
                    standards. Developers need to incorporate compliance modules
                    to meet different jurisdictional requirements while
                    maintaining a seamless user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/modular_architecture_for_scalability.svg" />
                  </div>
                  <h3>Modular Architecture for Scalability</h3>
                  <p>
                    Designing a microservices-based architecture that allows
                    different components, such as the trading engine, risk
                    management, and user interface, to be developed and scaled
                    independently. This ensures the platform can handle
                    increased traffic and trading volumes as user demand grows.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"440px"}}>
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/real_time_data_feeds_and_analytics.svg" />
                  </div>
                  <h3>Real-Time Data Feeds and Analytics</h3>
                  <p>
                    Implementing low-latency data streaming and real-time market
                    analytics for live price updates, order book tracking, and
                    trade execution details. Developers must ensure that users
                    can access fast, accurate data for better decision-making
                    and effective technical analysis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"440px"}}>
                  <div className="icon-box">
                    <img style={{width:"65%"}} src="assets/img/exchange/dse/customizable_user_interfaces.svg" />
                  </div>
                  <h3>Customizable User Interfaces (UI/UX)</h3>
                  <p>
                    Developing a highly customizable and user-friendly front-end
                    interface that can cater to both novice and professional
                    traders. This includes features like customizable
                    dashboards, multiple charting tools, and an intuitive layout
                    for placing orders and tracking performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
