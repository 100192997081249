import React from "react";

export default function BitcoinWDevService() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Bitcoin Wallet Development Service</h2>
              <p>
                At Xeer Technology, we offer comprehensive Bitcoin Wallet
                Development Services to help individuals, startups, and
                enterprises create secure and feature-rich Bitcoin wallets. Our
                expertise lies in building custom wallets tailored to meet your
                specific needs, whether you’re seeking a simple storage solution
                or a complex, multi-functional wallet for your business.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
