import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function AmplifyRevenue() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55"></div>
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_five">
                  <div className="image-box mr_80">
                    <figure className="image">
                      <img
                        src="assets/img/we_offer_best_margin_trading_exchange3.svg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_four">
                  <div className="content-box ml_40">
                    <div className="text-start mb_40">
                      <h1>
                        We Offer Best Margin Trading Exchange Development
                        Service
                      </h1>
                      <p className="pt-3">
                        Xeer Technology offers the best margin trading crypto
                        exchange development services, providing a secure and
                        scalable platform that empowers traders to leverage
                        their positions for higher returns. Our tailored
                        solutions include advanced features such as real-time
                        market data integration, customizable leverage options,
                        and automated risk management systems to ensure a smooth
                        trading experience.
                      </p>
                      <p className="pt-3">
                        With a focus on high liquidity, seamless user
                        interfaces, and adherence to industry regulations, Xeer
                        Technology delivers a robust and reliable margin trading
                        exchange that meets the demands of both novice and
                        experienced traders.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
