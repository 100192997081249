import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CustomLayer2Hero from "./CustomLayer2Hero";
import CustomLayer2Main from "./CustomLayer2Main";
import CustomLayer2Ethereum from "./CustomLayer2Ethereum";
import CustomLayer2Wallet from "./CustomLayer2Wallet";
import CustomLayer2exchange from "./CustomLayer2exchange";
import CustomLayer2Development from "./CustomLayer2Development";

export default function CustomLayer2() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <CustomLayer2Hero />
        <CustomLayer2Main/>
        <CustomLayer2Ethereum/>
        <CustomLayer2Wallet/>
        {/* <CustomLayer2exchange/> */}
        <CustomLayer2Development/>
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
