import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function CMCListingHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/listing/boost_your_token_visibility-with-cmc_coingecko-listings.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Boost Your Token’s Visibility with CoinMarketCap & CoinGecko
                    Listings
                  </h2>
                  <p className="pt-3 text-white">
                    Maximize token awareness and market reach through
                    top-ranking cryptocurrency data platforms.
                  </p>
                </div>
                <div className="btn-box pt-5">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
