import React from "react";

export default function BlogsStwo() {
  return (
    <>
      <section className="news-section pb_120">
        <div className="default-pattern">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-1.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Resource AI content tips tricks and strategy started
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 20, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Alex Beniwal</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-2.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        You made it this far, why not make it to the end
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 18, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Haris Gilat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-3.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Keeps your data safest private with built in security
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 17, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Tomas Wats</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-7.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Resource AI content tips tricks and strategy started
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 20, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Alex Beniwal</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-8.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        You made it this far, why not make it to the end
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 18, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Haris Gilat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-9.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Keeps your data safest private with built in security
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 17, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Tomas Wats</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-10.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Resource AI content tips tricks and strategy started
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 20, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Alex Beniwal</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-11.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        You made it this far, why not make it to the end
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 18, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Haris Gilat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-12.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Keeps your data safest private with built in security
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 17, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Tomas Wats</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-13.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Resource AI content tips tricks and strategy started
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 20, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Alex Beniwal</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-14.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        You made it this far, why not make it to the end
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 18, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Haris Gilat</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 news-block">
              <div
                className="news-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <a href="blog-details.html">
                      <img src="assets/images/news/news-15.jpg" alt="" />
                    </a>
                  </figure>
                  <div className="lower-content">
                    <span className="category">Digital Payment</span>
                    <h3>
                      <a href="blog-details.html">
                        Keeps your data safest private with built in security
                      </a>
                    </h3>
                    <ul className="post-info">
                      <li>
                        <i className="icon-7" />
                        March 17, 2023
                      </li>
                      <li>
                        <i className="icon-8" />
                        <a href="blog-details.html">Tomas Wats</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-wrapper centred pt_50 pb_30">
            <ul className="pagination clearfix">
              <li>
                <a href="blog.html">
                  <i className="icon-42" />
                </a>
              </li>
              <li>
                <a href="blog.html" className="current">
                  1
                </a>
              </li>
              <li>
                <a href="blog.html">2</a>
              </li>
              <li>
                <a href="blog.html">3</a>
              </li>
              <li>
                <a href="blog.html">
                  <i className="icon-43" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
