import React from "react";

export default function BlockchainFrameworks() {
  return (
    <>
      {/* <section className="service-style-two  mt-5">
        <div
          className="pattern-layer "
          style={{ backgroundImage: "url(assets/images/shape/shape-41.png)" }}
        />
        <div className="container-fluid px-0 px-md-2">
          <div className="inner-contr pt-5">
            <div
              data-animation-box
              className="sec-title mb_55 centred animated"
            >
              <h2>Blockchain Frameworks We Utilize for L2/L3 Development</h2>
              <p>
                Our blockchain developers are skilled in building on robust
                frameworks for L2 and L3 blockchains.
              </p>
            </div>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-16" />
                    </div>
                    <h3>Polygon zkEVM</h3>
                    <p>
                      Ethereum-compatible scaling using zero-knowledge
                      technology for smart contracts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-16" />
                    </div>
                    <h3>zkSync Hyperchains</h3>
                    <p>
                      Parallel zkEVM instances achieving consensus on Ethereum
                      Layer 1.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-16" />
                    </div>
                    <h3>Avalanche Subnets</h3>
                    <p>
                      Custom blockchains supporting scalable, sovereign
                      networks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-16" />
                    </div>
                    <h3>Cosmos SDK</h3>
                    <p>Toolkit for multi-asset PoS and PoA blockchains</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-16" />
                    </div>
                    <h3>Polkadot Parachains</h3>
                    <p>
                      Permissionless platform supporting Layer 2 and Layer 3
                      custom chains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-17" />
                    </div>
                    <h3>Arbitrum Orbit</h3>
                    <p>
                      Platform supporting custom Layer 2 and Layer 3
                      blockchains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-18" />
                    </div>
                    <h3>Parity Substrate</h3>
                    <p>
                      Flexible, modular framework for building customized
                      blockchains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div
                  className="feature-block-one aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1300}
                >
                  <div className="inner-box" style={{height:"277px"}}>
                    <div className="">
                      <i c style={{width:"70px"}}lassName="icon-21" />
                    </div>
                    <h3>OP Stack</h3>
                    <p>
                      Development stack for creating Layer 2 blockchains on the
                      OP Mainnet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="feature-style-two p_100 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Blockchain Frameworks We Utilize for L2/L3 Development</h2>
            <p className="pt-3">
              Our blockchain developers are skilled in building on robust
              frameworks for L2 and L3 blockchains.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/polygon_evm.svg" />
                  </div>
                  <h3>Polygon zkEVM</h3>
                  <p>
                    Ethereum-compatible scaling using zero-knowledge
                    technology for smart contracts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/sync_hyperchains.svg" />
                  </div>
                  <h3>zkSync Hyperchains</h3>
                  <p>
                    Parallel zkEVM instances achieving consensus on Ethereum
                    Layer 1.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/avalanche_subnets.svg" />
                  </div>
                  <h3>Avalanche Subnets</h3>
                  <p>
                    Custom blockchains supporting scalable, sovereign
                    networks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/sdk.svg" />
                  </div>
                  <h3>Cosmos SDK
                  </h3>
                  <p>
                    Toolkit for multi-asset PoS and PoA blockchains
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/polkadot_parachains.svg" />
                  </div>
                  <h3>Polkadot Parachains
                  </h3>
                  <p>
                    Permissionless platform supporting Layer 2 and Layer 3 custom chains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/arbitrum_orbit.svg" />
                  </div>
                  <h3>Arbitrum Orbit
                  </h3>
                  <p>
                    Platform supporting custom Layer 2 and Layer 3 blockchains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/parity_substrate.svg" />
                  </div>
                  <h3>Parity Substrate
                  </h3>
                  <p>
                    Flexible, modular framework for building customized blockchains.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"277px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/blockchain-dev/SVG/op.svg" />
                  </div>
                  <h3>OP Stack
                  </h3>
                  <p>
                    Development stack for creating Layer 2 blockchains on the OP Mainnet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
