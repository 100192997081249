import React from "react";
import Header from "../Coman/Header";
import IDPDevelopmentHero from "./IDPDevelopmentHero";
import Footar from "../Coman/Footar";
import InsightsOn from "./InsightsOn";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import WhyIDPDev from "./WhyIDPDev";
import Striking from "./Striking";
import Fundraising from "./Fundraising";
import InitialDEX from "./InitialDEX";
import TrendingIDO from "./TrendingIDO";
import Turbocharge from "./Turbocharge";
import Perks from "./Perks";

export default function IDPDevelopment() {
  return (
    <div>
      <Header />
      <IDPDevelopmentHero />
      <InsightsOn />
      <Striking />
      <Fundraising />
      {/* <InitialDEX/> */}
      {/* <TrendingIDO/> */}
      <Turbocharge />
      {/* <Perks/> */}
      <WhyIDPDev />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
