import React from "react";

export default function TalkToOurTeamBTN() {
  return (
    <>
      <button
        type="button"
        class="btn-box"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <span className="theme-btn btn-one mr_20">Talk To Our Team</span>
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Get in Touch with Us!
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <section className="contact-section">
  <div className="auto-container">
    <div className="form-inner">
      <form method="post" action="https://azim.hostlin.com/Sensai/sendemail.php" id="contact-form"> 
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
            <input type="text" name="username" placeholder="Your Name" required />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
            <input type="email" name="email" placeholder="Your email" required />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
            <input type="text" name="phone" required placeholder="Phone" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 form-group">
            <input type="text" name="subject" required placeholder="Subject" />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <textarea name="message" placeholder="Type message" defaultValue={""} />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
            <button className="theme-btn btn-one" type="submit" name="submit-form">Post a Comment</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

            </div>           
          </div>
        </div>
      </div>
    </>
  );
}
