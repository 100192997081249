import React from "react";

export default function Empowering() {
  return (
    <>
      <section className="contact-info-section centred p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Top-notch Web3 Development Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "465px" }}>
                  <img
                    src="assets/img/web3/decentralized-application.svg"
                    width={60}
                  />
                  <h3>Decentralized Application (dApp) Development</h3>
                  <p>
                    Unlock the potential of decentralized applications with Xeer
                    Technology’s dApp development services. We design and
                    develop dApps that offer seamless user experiences, improved
                    security, and full transparency for users and businesses
                    alike.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "465px" }}>
                  <img
                    src="assets/img/web3/blockchain-network-development.svg"
                    width={60}
                  />
                  <h3>Blockchain Network Development</h3>
                  <p>
                    From private to public blockchain solutions, Xeer Technology
                    offers full-stack blockchain network development. We build
                    custom blockchain protocols tailored to your business needs,
                    utilizing advanced consensus mechanisms and ensuring
                    interoperability with existing systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "465px" }}>
                  <img src="assets/img/web3/smart-contract.svg" width={60} />
                  <h3>Smart Contract Development & Audits</h3>
                  <p>
                    Our team specializes in building secure, efficient, and
                    cost-effective smart contracts to automate business
                    processes and financial transactions on the blockchain. We
                    also conduct comprehensive audits to identify and mitigate
                    potential risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <img
                    src="assets/img/web3/nft-development-services.svg"
                    width={60}
                  />
                  <h3>NFT Development Services</h3>
                  <p>
                    As NFTs continue to revolutionize asset ownership, Xeer
                    Technology offers end-to-end NFT development services. From
                    creating unique digital assets to developing platforms for
                    NFT trading, we make your vision a reality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <img src="assets/img/web3/defi-solutions.svg" width={60} />
                  <h3>DeFi Solutions</h3>
                  <p>
                    With deep expertise in decentralized finance, Xeer
                    Technology provides development services for DeFi platforms,
                    including lending, borrowing, staking, and liquidity
                    solutions. We help your business tap into the transformative
                    power of DeFi.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "420px" }}>
                  <img src="assets/img/web3/web3_wallet.svg" width={60} />
                  <h3>Web3 Wallet Development</h3>
                  <p>
                    Our Web3 wallet development services focus on creating
                    user-friendly, secure wallets that support a wide array of
                    assets and blockchain networks. These wallets are ideal for
                    businesses looking to enhance accessibility for their users
                    in a decentralized environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
