import React from "react";

export default function CustomLayer2Ethereum() {
  return (
    <>
      <section className="contact-info-section centred  p_80">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Xeer’s Cardano Blockchain Development Services</h2>
            <p className="pt-3">
              At Xeer Technology, we leverage Cardano’s advanced infrastructure
              to provide secure, scalable, and efficient blockchain solutions
              tailored to your business needs.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="">
                  <img src="assets/img/dapp.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Cardano DApp Development</h3>
                  <p className="">
                    We develop decentralized applications on Cardano, ensuring
                    security, scalability, and a user-friendly experience that
                    is adaptable to multiple industries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="">
                    <img src="assets/img/custom_smart_contract_development.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">
                    Smart Contract Development
                  </h3>
                  <p className="">
                    Our experts create robust smart contracts using Plutus and
                    Marlowe, Cardano’s smart contract programming languages, to
                    deliver transparent and reliable automation for your
                    business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="">
                  <img src="assets/img/defi-solutions.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Cardano DeFi Solutions</h3>
                  <p className="">
                    Xeer’s Cardano DeFi services include decentralized exchanges
                    (DEX), lending platforms, and staking solutions that tap
                    into the strengths of the Cardano blockchain.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="">
                  <img src="assets/img/token_development.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">
                    Token Development (ADA, Native Tokens)
                  </h3>
                  <p className="">
                    We facilitate the creation of ADA-based tokens and native
                    assets on Cardano, providing options for tokenized
                    applications and fungible/non-fungible tokens (NFTs).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="">
                  <img src="assets/img/blockchain_selection.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Blockchain Consulting</h3>
                  <p className="">
                    Our team offers consulting to guide you through Cardano’s
                    ecosystem, ensuring you make strategic decisions for
                    development, integration, and optimization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
