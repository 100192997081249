import React from "react";

export default function SecurityDevelopment() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-71.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our Security Token Trading Exchange</h2>
            <p>
              Following are some of the features of Security Token Trading
              Exchange that we offer developing for our clients.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/regulatory_compliance.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Regulatory Compliance</h3>
                    <p>
                      Ensure that your platform adheres to the necessary legal
                      and regulatory frameworks, allowing for seamless trading
                      of security tokens while protecting both issuers and
                      investors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/tokenization_solutions.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Tokenization Solutions</h3>
                    <p>
                      Providing comprehensive tokenization services, converting
                      physical assets into digital security tokens. This process
                      enhances liquidity and opens up investment opportunities
                      to a broader audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/advanced_trading_features.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Advanced Trading Features</h3>
                    <p>
                      Integrating sophisticated trading tools, including order
                      types, real-time market data, and analytical tools,
                      enabling users to make informed trading decisions and
                      optimize their investment strategies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/high_security_standards.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">High Security Standards</h3>
                    <p>
                      Security is our top priority. We implement multi-signature
                      wallets, multi-level authentication, and high-level
                      encryption to protect user assets and sensitive
                      information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/user_friendly_interface.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">User-Friendly Interface</h3>
                    <p>
                      Designed with user experience in mind, providing an
                      intuitive interface that simplifies navigation and trading
                      for both novice and experienced investors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"400px"}}>
                  <div className="">
                    <img
                      style={{ width: "25%" }}
                      src="assets/img/exchange/se/liquidity_management.svg"
                    />
                  </div>
                  <div className="table-header mb_40">
                    <h3 className="pt-3">Liquidity Management</h3>
                    <p>
                      We help establish liquidity pools to ensure that your
                      security token trading exchange maintains sufficient
                      liquidity for smooth transactions and market stability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
