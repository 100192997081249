import React from "react";

export default function PolygonNetworkBlockchain() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer for Polygon zkEVM Development?</h2>
              <p>
                At Xeer Technology, our expert developers utilize Polygon zkEVM
                to build resilient, scalable applications that align with your
                business needs. With a deep understanding of zero-knowledge
                proofs and Ethereum-compatible scaling, we ensure seamless
                deployment, security, and interoperability across networks.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
