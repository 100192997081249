import React from "react";
import Header from "../Coman/Header";
import NFTHero from "./NFTHero";
import Footar from "../Coman/Footar";
import Popularity from "./Popularity";
import Dedicated from "./Dedicated";
import Ensuring from "./Ensuring";
import LeadingBlockNET from "./LeadingBlockNET";
import InitialMeet from "./InitialMeet";
import Arts from "./Arts";
import BlogsNFT from "./BlogsNFT";
import LookingD from "../About-Us/LookingD";
import WhyChoNFTDev from "./WhyChoNFTDev";

export default function NFT() {
  return (
    <div>
      <Header />
      <NFTHero />
      <Popularity />
      <Dedicated />
      <Ensuring />
      <LeadingBlockNET />
      <InitialMeet />
      <Arts />
      <WhyChoNFTDev />
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
