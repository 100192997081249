import React from "react";

export default function OurCompany() {
  return (
    <>
      <section className="feature-section pt_120 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box">
                <img style={{width:"100%"}} src="assets/img/who_we_are_img(1).png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Who We Are</h2>
                </div>
                <div className="text-box">
                  <p>
                    At Xeer Technology, we are passionate about driving
                    innovation in the blockchain space.
                  </p>
                  <p>
                    As a leading blockchain development company, we specialize
                    in creating secure, scalable, and customized blockchain
                    solutions for businesses of all sizes.
                  </p>
                  <p>
                    Our team of expert developers, architects, and consultants
                    works tirelessly to deliver cutting-edge decentralized
                    applications (dApps), smart contracts, and blockchain
                    platforms that meet the unique needs of our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
