import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import DexAggregatorHero from './DexAggregatorHero'
import WhatInch from './WhatInch'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../About-Us/LookingD'
import CloneApp from './CloneApp'
import CloneSoftware from './CloneSoftware'
import Supported from './Supported'
import HighLiquidity from './HighLiquidity'
import WhyChoDexA from './WhyChoDexA'
import PartnerWithXeer from './PartnerWithXeer'

export default function DexAggregator() {
  return (
    <div>
      <Header/>
      <DexAggregatorHero/>
      <WhatInch/>
      <CloneApp/>
      <CloneSoftware/>
      <HighLiquidity/>
      <Supported/>
      <PartnerWithXeer/>
      <WhyChoDexA/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
