import React from "react";
import Header from "../Coman/Header";
import ArbitrageTradingBotsHero from "./ArbitrageTradingBotsHero";
import Footar from "../Coman/Footar";
import CryptoABot from "./CryptoABot";
import Harness from "./Harness";
import StatisticsDYK from "./StatisticsDYK";
import Stunning from "./Stunning";
import CryArbitrageTrad from "./CryArbitrageTrad";
import ProjectwithUs from "./ProjectwithUs";
import ArbitrageTBots from "./ArbitrageTBots";
import SkilledDevelopers from "./SkilledDevelopers";
import MultiExchange from "./MultiExchange";

import LookingD from "../About-Us/LookingD";
import BlogsNFT from "../NFT/BlogsNFT";

export default function ArbitrageTradingBots() {
  return (
    <div>
      <Header />
      <ArbitrageTradingBotsHero />
      <CryptoABot />
      <StatisticsDYK />
      <Harness />
      <ProjectwithUs />
      <Stunning />
      {/* add */}
      <MultiExchange />
      <ArbitrageTBots />
      <CryArbitrageTrad />
      <SkilledDevelopers />
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
