import React from "react";

export default function ArbitrageTradingBotsHero() {
  return (
    <>
      <section
        className="faq-style-three p_150"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Arbitrage-Trading-Bot-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box  mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    Arbitrage Trading Bot Development
                  </h2>
                  <p className="text-white pt-3">
                    Automate profit opportunities by leveraging price
                    differences across multiple exchanges with real-time
                    precision.
                  </p>
                </div>

                <a href="/" className="theme-btn btn-one mr_20 mt-5">
                  Talk To Our Experts
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column pb_150">
              {/* <div className="image-box ml_30">
                <figure className="image">
                  <img
                  className="vert-move"
                    src="assets/img/arbitrage-trading-bot-development.png"
                    alt=""
                  />
                </figure>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
