import React from "react";

import HarshgraphDevelopmentHero from "./HarshgraphDevelopmentHero";
import WhatisHedera from "./WhatisHedera";
import HashgraphServicesOffered from "./HashgraphServicesOffered";
import WithHashgraph from "./WithHashgraph";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Header from "../../Coman/Header";

export default function HarshgraphDevelopment() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <HarshgraphDevelopmentHero />
        <WhatisHedera />
        <HashgraphServicesOffered />
        {/* <WithHashgraph /> */}
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
