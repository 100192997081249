import React from "react";

export default function Letushelp() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_40">
              <h3 className="text-white">
                Let us help you empower your users with a seamless mobile wallet
                that meets the evolving demands of the cryptocurrency landscape.
              </h3>
            </div>
            <a href="/contact-us" className="theme-btn btn-one m_20">
              Get a quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
