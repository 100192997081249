import React from "react";

export default function Mastering() {
  return (
    <>
      {/* <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology for Your Crypto Token Development?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    At Xeer Technology, we combine cutting-edge blockchain
                    innovation with industry expertise to deliver tailored
                    solutions that drive success in the decentralized finance
                    world. With Xeer Technology, you’re not just getting a
                    service provider – you’re partnering with a team dedicated
                    to helping your project succeed in the rapidly evolving
                    blockchain landscape. Let us help you unlock new
                    opportunities and deliver impactful, future-proof solutions.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Blockchain Expertise</h4>
                      <p>
                        Our team brings extensive experience in developing
                        crypto powered solutions, ensuring your project is
                        secure, scalable, and future-proof.
                      </p>
                    </li>
                    <li>
                      <h4>User-Centric Design</h4>
                      <p>
                        We prioritize creating a seamless, intuitive user
                        experience that makes it easy for both new and
                        experienced users to engage with your project.
                      </p>
                    </li>
                    <li>
                      <h4>End-to-End Development</h4>
                      <p>
                        From initial concept design to post-launch support, we
                        handle every aspect of the development process to ensure
                        the success of your project.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for Your Crypto Token Development?
            </h2>
            <p className="p-3">
              At Xeer Technology, we combine cutting-edge blockchain innovation with industry expertise to deliver tailored solutions that drive success in the decentralized finance world. With Xeer Technology, you’re not just getting a service provider – you’re partnering with a team dedicated to helping your project succeed in the rapidly evolving blockchain landscape. Let us help you unlock new opportunities and deliver impactful, future-proof solutions.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/blockchain_development.svg" />
                  </div>
                  <h3 className="pt-4">Blockchain Expertise
                  </h3>
                  <p>Our team brings extensive experience in developing decentralized finance solutions, ensuring your staking platform is secure, scalable, and future-proof.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/dde/robust_admin_and_user_dashboard.svg" />
                  </div>
                  <h3 className="pt-4">User-Centric Design
                  </h3>
                  <p>
                    We prioritize creating a seamless, intuitive user experience that makes it easy for both new and experienced users to engage with your platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              ><div className="inner-box" style={{ height: "320px" }}>
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/wallet/dw/end_to_end_services.svg" />
                  </div>
                  <h3 className="pt-4">End-to-End Development
                  </h3>
                  <p>
                    From initial concept design to post-launch support, we handle every aspect of the development process to ensure the success of your DeFi staking platform
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
