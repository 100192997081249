import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import DefiYienldFarmingHero from './DefiYienldFarmingHero'
import Yield from './Yield'
import BlogsNFT from '../NFT/BlogsNFT'
import LookingD from '../About-Us/LookingD'
import DeFiConS from './DeFiConS'
import Forward from './Forward'
import Protocols from './Protocols'
import IconCurve from './IconCurve'
import WhyYield from './WhyYield'

export default function DefiYienldFarming() {
  return (
    <div>
      <Header/>
      <DefiYienldFarmingHero/>
      <Yield/>
      <DeFiConS/>
      <Forward/>
      <Protocols/>
      <IconCurve/>
      <WhyYield/>
      <BlogsNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
