import React from "react";

export default function OurBestSelling() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Best Selling White Label Crypto Exchange Scripts</h2>
          </div>

          <div className="d-flex gap-2 flex-wrap  justify-content-end justify-content-sm-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img src="https://www.antiersolutions.com/wp-content/uploads/2023/05/Binance-Clone-Script.png" />
                    </div>
                    <h5>Binance </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img src="https://www.antiersolutions.com/wp-content/uploads/2023/05/Crypto.com-clone-script.png" />
                    </div>
                    <h5>Coinbase</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img src="https://www.antiersolutions.com/wp-content/uploads/2023/05/MDEX.png" />
                    </div>
                    <h5>ByBit</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img src="https://www.antiersolutions.com/wp-content/uploads/2023/05/Coinbase-Wallet.png" />
                    </div>
                    <h5>WazirX</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img src="https://www.antiersolutions.com/wp-content/uploads/2023/05/Remitano-clone-script.png" />
                    </div>
                    <h5>Paxful</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
