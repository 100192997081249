import React from "react";

export default function CryptoABot() {
  return (
    <>
      <section className="feature-section p_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className=" mr_40 pr_50">
                  <div className="image-inner">
                   
                    <figure className="image">
                      <img
                        src="assets/img/crypto-arbitrage-bot-development-to-maximize-your-crypto-trading-profit.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>
                    Crypto Arbitrage Bot Development to Maximize Your Crypto
                    Trading Profit
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    Unlock the potential of cryptocurrency markets with an
                    advanced arbitrage trading bot designed to automatically
                    detect and capitalize on price discrepancies across multiple
                    exchanges. These bots use sophisticated algorithms to track
                    real-time price variations for the same asset and execute
                    buy and sell orders in a matter of seconds, ensuring you
                    capture profit from market inefficiencies without manual
                    intervention.
                  </p>
                  <p>
                    By automating the entire arbitrage process, these bots
                    minimize the risks associated with human error and slow
                    decision-making, allowing for seamless, round-the-clock
                    trading.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
