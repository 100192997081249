import React from "react";

export default function Fintech() {
  return (
    <>
      <section className="integrations-section centred p_150 ">
        <div className="">
          <div className="sec-title mt_50 mb_40">
            <div className="inner_wrap">
              <h2>
                Discover Our Exceptional Expertise Across Multiple Industries
              </h2>
              <p className="pt-3">
                Trust our experienced DeFi wallet development specialists,
                renowned for delivering successful projects across the globe.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/fintech.svg"
                      />
                    </div>
                    <h5 className="pt-4">Fintech</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/e_commerce.svg"
                      />
                    </div>
                    <h5 className="pt-4">e-Commerce</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/gaming.svg"
                      />
                    </div>
                    <h5 className="pt-4">Gaming</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/education.svg"
                      />
                    </div>
                    <h5 className="pt-4">Education</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/sports.svg"
                      />
                    </div>
                    <h5 className="pt-4">Sports</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/real_estate.svg"
                      />
                    </div>
                    <h5 className="pt-4">Real-Estate</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/supply_chain.svg"
                      />
                    </div>
                    <h5 className="pt-4">Supply Chain</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/logistics.svg"
                      />
                    </div>
                    <h5 className="pt-4">Logistics</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "70px" }}
                        src="assets/img/wallet/dw/government.svg"
                      />
                    </div>
                    <h5 className="pt-4">Government</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block-smallbox py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/wallet/dw/insurance.svg"
                      />
                    </div>
                    <h5 className="pt-4">Insurance</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
