import React from "react";

export default function Automated() {
  return (
    <>
      <section className="contact-info-section centred p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our Crypto Trading Bots</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"370px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/Multi-Exchange Integration.svg" />
                  </div>
                  <h3>Multi-Exchange Integration</h3>
                  <p>
                    Trade across multiple exchanges simultaneously, such as
                    Binance, Coinbase, and Kraken, to capture opportunities
                    across diverse markets and pairs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"370px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/24-7Automated Trading.svg" />
                  </div>
                  <h3>24/7 Automated Trading</h3>
                  <p>
                    Our bots operate round-the-clock, allowing you to benefit
                    from market movements any time of the day, even while you
                    sleep.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"370px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/Backtesting and Simulation Tools.svg" />
                  </div>
                  <h3>Backtesting and Simulation Tools</h3>
                  <p>
                    Test your strategies against historical data to optimize
                    performance and refine parameters before live trading,
                    minimizing risk.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/Risk Management  Stop-Loss Features.svg" />
                  </div>
                  <h3>Risk Management & Stop-Loss Features</h3>
                  <p>
                    Configure custom risk parameters, including stop-loss,
                    take-profit, and position sizing, to protect your capital
                    and enhance long-term profitability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/User-Friendly Interface.svg" />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    An intuitive dashboard enables traders to set up, monitor,
                    and adjust bot settings in real-time, simplifying the
                    trading experience for all users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"70px"}} src="assets/img/crypto-g-dev/svg/Custom Alerts  Notifications.svg" />
                  </div>
                  <h3>Custom Alerts & Notifications</h3>
                  <p>
                    Receive real-time alerts and notifications on trade
                    executions, market changes, and bot performance directly to
                    your device, keeping you informed on the go.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
