import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MarginTradingExchangeHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/exchange/Margin-Trading-Crypto-Exchange-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box pb-4">
                <h1 className="text-white pb-3">Margin Trading Crypto Exchange Development</h1>
                <p className="text-white">
                  Xeer Technology provides comprehensive margin trading crypto
                  exchange development, enabling traders to leverage their
                  positions with advanced security, real-time market data, and
                  seamless user experience.
                </p>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              {/* <div className=" ml_40">
                <figure className="">
                  <img
                    className="vert-move"
                    src="assets/img/white-label-crypto-exchange-development.png"
                    alt=""
                  />
                </figure>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
