import React from "react";

export default function ConnectwithUsExpert() {
  return (
    <>
      <section
        className="integrations-section centred pb_150"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white pt-5">
                Application Range with Substrate Blockchain Technology
              </h2>
              <p className="text-white pt-3">
                With Substrate, we create applications across diverse
                industries, including:
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "100px" }}
                        src="assets/img/framework/SVG/defi_platforms.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">DeFi Platforms</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "100px" }}
                        src="assets/img/framework/SVG/supply_chain_management_systems.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Supply Chain Management Systems
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "100px" }}
                        src="assets/img/framework/SVG/voting_and_governance_solutions.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Voting and Governance Solutions
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "100px" }}
                        src="assets/img/framework/SVG/identity.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Identity and Access Management Systems
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img style={{ width: "100px" }} src="assets/img/framework/SVG/gaming.svg" />
                    </div>
                    <h5 className="pt-4 text-white">
                      Gaming and Gambling Platforms
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "20%" }}
                        src="assets/img/framework/SVG/social_media.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Social Media and Content Sharing Networks
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
