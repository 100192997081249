import React from "react";

export default function Dedicated() {
  return (
    <>
      <section className="feature-section p_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="image_block_four">
                <div className="image-inner">
                  <figure className="image">
                    <img
                      src="assets/img/nft_dev/nft-development-service-by-xeer-technology.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>NFT Development Service by Xeer Technology</h2>
                </div>
                <p>
                  At Xeer Technology, we specialize in offering comprehensive
                  NFT development services, tailored to meet the unique needs of
                  creators, businesses, and developers looking to enter the
                  fast-growing world of non-fungible tokens. From
                  conceptualization to deployment, we help you build and launch
                  NFTs that stand out in the marketplace.
                </p>
                <div className="text-box pt-4">
                  <ul className="list-style-one clearfix">
                    <li>Designing Unique Digital Arts</li>
                    <li>Minting Tokens on Blockchain </li>
                    <li>Managing NFT Collections</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
