import React from "react";

export default function Widen() {
  return (
    <>
      <section className="faq-style-three p_140">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="">
                <img
                  src="assets/img/how-is-it-evolving.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30 pt-5">
                <h1>What is Web3 & how is it evolving?</h1>
                <p className="py-3">
                  Web3, often referred to as the decentralized web, represents
                  the next evolution of the internet, transitioning from a
                  centralized model to one that emphasizes user ownership,
                  privacy, and decentralized applications (dApps). At its core,
                  Web3 leverages blockchain technology to create an ecosystem
                  where users have greater control over their data and digital
                  assets, enabling peer-to-peer interactions without
                  intermediaries.
                </p>
                <p>
                  This shift is fueled by advancements in technologies such as
                  smart contracts, decentralized finance (DeFi), and
                  non-fungible tokens (NFTs), which are redefining how value and
                  ownership are perceived online. As Web3 continues to evolve,
                  it promises to enhance transparency, security, and
                  accessibility, paving the way for innovative business models
                  and transforming various industries, including finance,
                  gaming, and social media. With ongoing developments and
                  increasing adoption, Web3 is set to reshape the digital
                  landscape, empowering individuals and fostering a more
                  equitable internet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
