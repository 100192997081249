import React from "react";

export default function Yield() {
  return (
    <>
      {/* <section className="faq-style-three p_150">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ms-5">
                <figure className="image">
                  <img style={{width:"250px"}}
                    src="assets/img/defi/defi-y-f/What-is-DeFi-Yield-Farming-Section-Image.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div data-animation-box className="sec-title mb_55 ">
                <h2>What is DeFi Yield Farming</h2>
                <p className="pt-3">
                  DeFi Yield Farming is a decentralized finance practice that
                  allows users to earn passive income by providing liquidity to
                  decentralized platforms. By staking or lending their crypto
                  assets in liquidity pools, participants receive rewards,
                  typically in the form of interest or additional tokens. Yield
                  farming has become a popular way for crypto holders to
                  maximize their returns without active trading, as platforms
                  use automated smart contracts to distribute yields. This
                  innovative approach to earning has made DeFi yield farming a
                  cornerstone of decentralized finance, attracting users with
                  its potential for high returns and flexibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is DeFi Yield Farming</h2>
              <p>
                DeFi Yield Farming is a decentralized finance practice that
                allows users to earn passive income by providing liquidity to
                decentralized platforms. By staking or lending their crypto
                assets in liquidity pools, participants receive rewards,
                typically in the form of interest or additional tokens. Yield
                farming has become a popular way for crypto holders to
                maximize their returns without active trading, as platforms
                use automated smart contracts to distribute yields. This
                innovative approach to earning has made DeFi yield farming a
                cornerstone of decentralized finance, attracting users with
                its potential for high returns and flexibility.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
