import React from "react";

export default function DecentralizedES() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Get Decentralized Exchange Script</h2>
            <h5 className="p-3">
              Kickstart your own platform with our scripts & launch a DEX within
              minutes!
            </h5>
            <p>
              A Decentralized Exchange (DEX) Script is a pre-built software
              package designed to facilitate the launch of a decentralized
              cryptocurrency exchange. This script includes core features,
              functionalities, and smart contract integration, enabling
              peer-to-peer trading without the need for intermediaries. The DEX
              script allows customization to meet specific project requirements
              and ensures compliance with decentralized finance standards.
            </p>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Uniswap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/pancakeswap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/MDEX-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/sushiswap-sushi-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/BakerySwap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/1inch-Exchange-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/DyDx-Exchange-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Layer-2-2.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Dodo-1-1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt_100">
            <a href="/contact-us" className="theme-btn btn-one">
              Get Your DEX Script
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
