import React from "react";

export default function WhyChooseCMMS() {
  return (
    <>
      {/* <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology for Crypto Market Making Services?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    We prioritize compliance with regulatory standards through
                    seamless KYC and AML integration, ensuring your market
                    making operations run smoothly and legally across multiple
                    jurisdictions. Our scalable architecture is designed to
                    handle high-frequency trading, maintaining liquidity and
                    stabilizing prices across various exchanges. With Xeer
                    Technology's end-to-end market making services, you can
                    trust us to deliver a reliable, user-friendly, and
                    future-proof solution that enhances liquidity, minimizes
                    volatility, and optimizes trading performance for your
                    digital assets.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Dedicated Support</h4>
                      <p>
                        Our team provides round-the-clock support, ensuring that
                        any issues or questions are promptly addressed to keep
                        your platform running smoothly.
                      </p>
                    </li>
                    <li>
                      <h4>Experienced Team</h4>
                      <p>
                        With years of expertise in blockchain and exchange
                        development, our skilled developers create reliable,
                        high-performance margin trading platforms tailored to
                        your needs.
                      </p>
                    </li>
                    <li>
                      <h4>Standard Development Practice</h4>
                      <p>
                        We follow industry-standard development practices,
                        ensuring secure, compliant, and scalable platforms with
                        robust functionality and future-ready infrastructure.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for Crypto Market Making Services?
            </h2>
            <p className="p-3">
              We prioritize compliance with regulatory standards through
              seamless KYC and AML integration, ensuring your market
              making operations run smoothly and legally across multiple
              jurisdictions. Our scalable architecture is designed to
              handle high-frequency trading, maintaining liquidity and
              stabilizing prices across various exchanges. With Xeer
              Technology's end-to-end market making services, you can
              trust us to deliver a reliable, user-friendly, and
              future-proof solution that enhances liquidity, minimizes
              volatility, and optimizes trading performance for your
              digital assets.
            </p>

          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/dedicated_support.svg" />
                  </div>
                  <h3 className="pt-4">Dedicated Support</h3>
                  <p>
                    Our team provides round-the-clock support, ensuring that any
                    issues or questions are promptly addressed to keep your
                    platform running smoothly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/experienced_team.svg" />
                  </div>
                  <h3 className="pt-4">Experienced Team</h3>
                  <p>
                    With years of expertise in blockchain and exchange
                    development, our skilled developers create reliable,
                    high-performance margin trading platforms tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/standard_development_practice.svg" />
                  </div>
                  <h3 className="pt-4">Standard Development Practice</h3>
                  <p>
                    We follow industry-standard development practices, ensuring
                    secure, compliant, and scalable platforms with robust
                    functionality and future-ready infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
