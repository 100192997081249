import React from "react";

export default function FosteringThriving() {
  return (
    <>
      <section className="contact-info-section centred  p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title m_55">
            <h2 className="">
              Key Features of Our Crypto Market Making Services
            </h2>
            <p className="pt-3">
              Explore our advanced, data-driven solutions designed to enhance
              liquidity, stabilize prices, and optimize trading performance
              across multiple exchanges, ensuring seamless market operations for
              your digital assets.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/advanced_algorithmic_trading.svg"
                    />
                  </div>
                  <h3 className="pt-3">Advanced Algorithmic Trading</h3>
                  <p>
                    Utilizing sophisticated algorithms to provide consistent
                    liquidity and maintain tight bid-ask spreads across multiple
                    exchanges.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/customizable_liquidity_strategies.svg"
                    />
                  </div>
                  <h3 className="pt-2">Customizable Liquidity Strategies</h3>
                  <p>
                    Tailored solutions to meet the unique liquidity needs of
                    each token or platform, ensuring optimal trading
                    performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "100px" }}
                      src="assets/img/exchange/mms/price_stability.svg"
                    />
                  </div>
                  <h3 className="pt-4">Price Stability</h3>
                  <p>
                    Active order placement minimizes volatility, creating a more
                    stable trading environment for both new and existing tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/market_monitoring.svg"
                    />
                  </div>
                  <h3 className="pt-3">24/7 Market Monitoring</h3>
                  <p>
                    Around-the-clock monitoring and adjustments to keep
                    liquidity flowing smoothly, even during off-peak trading
                    hours.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/multi_exchange_support.svg"
                    />
                  </div>
                  <h3 className="pt-3">Multi-Exchange Support</h3>
                  <p>
                    Providing liquidity across a wide range of cryptocurrency
                    exchanges, ensuring broad market coverage and accessibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"313px"}}>
                  <div className="">
                    <img
                      style={{ width: "80px" }}
                      src="assets/img/exchange/mms/data_driven_optimization.svg"
                    />
                  </div>
                  <h3 className="pt-3">Data-Driven Optimization</h3>
                  <p>
                    Leveraging real-time data analytics to continuously refine
                    strategies, improving market efficiency and trading
                    outcomes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
