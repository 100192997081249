import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import P2PExchangeHero from './P2PExchangeHero'
import P2PCryptocurrency from './P2PCryptocurrency'
import Features from './Features'
import HowP2PWork from './HowP2PWork'
import BenefitsP2P from './BenefitsP2P'
import BenefitsOfOur from './BenefitsOfOur'
import P2PBlogs from './P2PBlogs'
import LookingD from '../About-Us/LookingD'
import WhyPtowP from './WhyPtowP'

export default function P2PExchange() {
  return (
    <div>
      <Header />
      <P2PExchangeHero />
      <WhyPtowP />
      <P2PCryptocurrency />
      <Features />
      <HowP2PWork />
      <BenefitsP2P />
      <BenefitsOfOur />
      <P2PBlogs />
      <LookingD />
      <Footar />
    </div>
  )
}
