import React from "react";

export default function Stunning() {
  return (
    <>
      <section className="contact-info-section centred  p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2 className="pb-3">
              Stunning Features of Our Crypto Arbitrage Trading Bot
            </h2>
            <p>
              Check out some of the stunning features of our crypto arbitrage
              bot development solutions listed below.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/automated_trading.svg" />
                  </div>
                  <h3>Automated Trading</h3>
                  <p>
                    Our arbitrage trading bots are completely automated and are
                    crafted to make proper trading decisions. The crypto
                    arbitrage bots can be configured to track different crypto
                    exchanges and automatically place trade orders.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/arbitrage_trading.svg" />
                  </div>
                  <h3>Arbitrage Trading</h3>
                  <p>
                    Our crypto arbitrage bot development solutions allow users
                    to conveniently configure their bots to carry out arbitrage
                    trading as per their bespoke strategy, allowing them to make
                    better profits in changing market conditions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"363px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/backtesting.svg" />
                  </div>
                  <h3>Backtesting</h3>
                  <p>
                    Users can easily and conveniently backtest their customized
                    strategies with the help of the trading bots to ensure the
                    strategies’ potential as well as accuracy before entering
                    into live trading activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"363px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/stop_loss.svg" />
                  </div>
                  <h3>Stop Loss</h3>
                  <p>
                    Crypto arbitrage trading bots developed by our team allow
                    users to modify and edit their existing trades at the time
                    of active trading sessions with the help of the integrated
                    stop-loss feature in the bots.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/push_notifications.svg" />
                  </div>
                  <h3>Push Notifications</h3>
                  <p>
                    Our crypto trading bot development solutions come with the
                    feature of push notifications that allow users to get live
                    updates regarding profits, signals, losses, and many more as
                    per their preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"363px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/atb/crypto_portfolio.svg" />
                  </div>
                  <h3>Crypto Portfolio</h3>
                  <p>
                    The crypto portfolio feature allows users to easily track
                    their spending, active trading, the success of PNLs, cancel
                    trades, and several other things that provide them with a
                    clear picture of their activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
