import React from "react";

export default function PartnerWithXeer() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">
              Partner with Xeer Technology for your DEX Aggregator Innovation
            </h2>
            <p className="text-white py-4">
              Xeer Technology delivers high-performance DEX aggregators tailored
              to the demands of today’s DeFi markets. Whether you’re building a
              new DeFi platform or enhancing an existing ecosystem, our
              expertise ensures your DEX aggregator stands out, offering a
              superior trading experience and <br /> access to the best liquidity
              across decentralized exchanges.
            </p>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
