import React from "react";

export default function ReadyToLaunch() {
  return (
    <>
      <section className="clients-section alternat-2 p_30 centred" style={{backgroundColor:"#071522"}}>
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white py-4">
            Ready to launch? Talk to us to get your crypto exchange
            functioning the very next day!
            </h2>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
          Get your white label Crypto Exchange
          </a>
        </div>
      </section>
    </>
  );
}