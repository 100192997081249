import React from 'react'
import Header from '../Coman/Header'
import NFTMarketplaceHero from './NFTMarketplaceHero'
import Footar from '../Coman/Footar'
import FullNFTMarketplace from './FullNFTMarketplace'
import Platform from './Platform'
import NFTmd from './NFTmd'
import NFTServices from './NFTServices'
import NftMDevelopment from './NftMDevelopment'
import NFTDigitalBusiness from './NFTDigitalBusiness'
import ScratchNFT from './ScratchNFT'
import LookingD from '../About-Us/LookingD'
import BuildingNFT from './BuildingNFT'

export default function NFTMarketplace() {
  return (
    <div>
      <Header/>
      <NFTMarketplaceHero/>
      <FullNFTMarketplace/>
      <Platform/>
      {/* <NFTmd/> */}
      <NFTServices/>
      {/* <NftMDevelopment/> */}
      {/* <NFTDigitalBusiness/> */}
      <ScratchNFT/>
      <BuildingNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
