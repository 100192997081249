import React from "react";

export default function ScratchNFT() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h2 className="text-white">Ready to Build Your NFT Marketplace?</h2>
            <p className="text-white py-3">
              Take the first step toward establishing your own NFT marketplace
              and becoming a leader in the digital asset revolution. Contact
              Xeer Technology today for expert NFT marketplace development
              services and unlock the full potential of your business in the NFT
              ecosystem.
            </p>
          </div>
          <a href="#" className="theme-btn btn-one m_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
