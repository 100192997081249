import React from "react";

export default function Custodia() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features of Our NFT Wallet</h2>
            <p className="pt-2">
              With advanced features like secure storage, easy asset transfers,
              multi-device synchronization, and intuitive interfaces, Xeer
              Technology’s NFT wallets enable users to easily store, buy, sell,
              and trade NFTs.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                    </div>
                    <img src="assets/img/multi_blockchain_support.svg" style={{height:"60px",width:"60px"}} /> 
                     <h3>Multi-Blockchain Support</h3>
                  <p>
                    Ability to handle NFTs across multiple blockchain networks
                    like Ethereum, Solana, Binance Smart Chain, Polygon, and
                    others, offering versatility for users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/secure_storage.svg" style={{height:"60px",width:"60px"}} /> 
                   </div>
                  <h3>Secure Storage</h3>
                  <p>
                    High-level security features such as encrypted private keys,
                    biometric authentication, and multi-signature support to
                    safeguard NFTs from unauthorized access.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/user_friendly_interface.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    A simple and intuitive UI/UX that allows users to easily
                    view, store, transfer, and manage their NFT collections
                    without technical complications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/seamless_nft_transactions.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Seamless NFT Transactions</h3>
                  <p>
                    Enable users to send and receive NFTs effortlessly with low
                    transaction fees and fast confirmation times across
                    supported blockchains.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/cross_device.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Cross-Device Synchronization</h3>
                  <p>
                    Users can access their NFT wallet from multiple devices,
                    ensuring convenience without compromising security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/integrated_marketplace_access.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Integrated Marketplace Access</h3>
                  <p>
                    Direct integration with NFT marketplaces, allowing users to
                    buy, sell, or auction NFTs directly within the wallet
                    interface.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/customizable_wallet_features.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Customizable Wallet Features</h3>
                  <p>
                    Support for custom features like gas fee management,
                    transaction history, and token swaps within the wallet.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/secure_storage.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>NFT Display & Metadata Viewing</h3>
                  <p>
                    Visual representation of owned NFTs, including detailed
                    metadata, properties, and provenance information to enhance
                    user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/multi_token_support.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Multi-Asset Support</h3>
                  <p>
                    Beyond NFTs, the wallet can also store other digital assets
                    like cryptocurrencies, making it a comprehensive solution
                    for managing both fungible and non-fungible tokens.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="">
                  <img src="assets/img/backup_recovery_options.svg" style={{height:"60px",width:"60px"}} /> 
                  </div>
                  <h3>Backup & Recovery Options</h3>
                  <p>
                    Secure backup and recovery options, such as mnemonic phrases
                    or hardware wallet integration, to prevent loss of access to
                    the wallet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
