import React from "react";

export default function WhatisHedera() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Hedera Hashgraph</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/gossip_protocol.svg" />
                  </div>
                  <h3>Gossip Protocol</h3>
                  <p>
                    Hedera’s unique protocol enables quick data sharing across
                    nodes, ensuring high network performance and reliability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/virtual_voting.svg" />
                  </div>
                  <h3>Virtual Voting</h3>
                  <p>
                    Virtual voting allows nodes to achieve consensus without
                    traditional voting, increasing efficiency and security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/high_throughput.svg" />
                  </div>
                  <h3>High Throughput</h3>
                  <p>
                    Capable of handling 250,000+ transactions per second, Hedera
                    Hashgraph is designed for low latency and high scalability,
                    ideal for large-scale applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/enhanced_security.svg" />
                  </div>
                  <h3>Enhanced Security</h3>
                  <p>
                    Using an Asynchronous Byzantine Fault Tolerant (aBFT) model,
                    Hedera Hashgraph achieves secure consensus even under
                    adversarial conditions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/fairness.svg" />
                  </div>
                  <h3>Fairness</h3>
                  <p>
                    The platform ensures fair transaction ordering and
                    timestamps, fostering transparency and trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"305px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/blockchain-dev/SVG/scalability.svg" />
                  </div>
                  <h3>Scalability</h3>
                  <p>
                    Hedera’s Java SDK and DAG structure allow it to scale
                    effortlessly with transaction demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
