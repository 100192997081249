import React from "react";

export default function WhyDEX() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Decentralized Exchange?</h2>
              <p>
                A Decentralized Exchange (DEX) is a cryptocurrency trading
                platform that operates without a central authority or
                intermediary, allowing users to trade directly with each other
                (peer-to-peer) on the blockchain. Instead of relying on a
                central entity to manage trades and hold assets, DEXs utilize
                smart contracts – self-executing agreements written in code – to
                automatically facilitate and record transactions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
