import React from "react";

export default function Disintermediation() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Smart Contract Development Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/custom_smart_contracts.svg" />
                  </div>
                  <h3>Custom Smart Contracts</h3>
                  <p>
                    We build smart contracts tailored to your business needs,
                    ensuring functionality, security, and reliability. Our
                    expertise spans across various blockchain platforms,
                    including Ethereum, Binance Smart Chain, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/token_development.svg" />
                  </div>
                  <h3>Token Development</h3>
                  <p>
                    Xeer Technology’s team creates standard and custom tokens
                    (ERC-20, BEP-20, ERC-721) for a wide array of applications,
                    enabling you to launch secure, compliant digital assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/smart_contract_integration.svg" />
                  </div>
                  <h3>Smart Contract Integration</h3>
                  <p>
                    From integrating smart contracts with existing systems to
                    developing DeFi protocols, we ensure smooth functionality
                    and compatibility with your business ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/dapp.svg" />
                  </div>
                  <h3>Decentralized Application (dApp) Development</h3>
                  <p>
                    Our team designs and deploys smart contracts to power dApps,
                    enabling seamless user experiences, secure transactions, and
                    decentralized interactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"350px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/defi_nft_solutions.svg" />
                  </div>
                  <h3>DeFi & NFT Solutions</h3>
                  <p>
                    We specialize in creating DeFi protocols and NFT
                    marketplaces, ensuring the security and scalability of these
                    high-value, high-demand solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
