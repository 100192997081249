import React from "react";
import Header from "../../Coman/Header";
import SubStrateHero from "./SubStrateHero";
import InnovationPartner from "./InnovationPartner";
import SubstrateBlockchai from "./SubstrateBlockchai";
import PowerApplications from "./PowerApplications";
import ApplicationDevelopmentProcess from "./ApplicationDevelopmentProcess";
import ConnectwithUsExpert from "./ConnectwithUsExpert";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";

export default function SubStrate() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header/>
        <SubStrateHero/>
        {/* <InnovationPartner/> */}
        <SubstrateBlockchai/>
        {/* <PowerApplications/> */}
        {/* <ApplicationDevelopmentProcess/> */}
        <ConnectwithUsExpert/>
        <BlogsNFT/>
        <LookingD/>
        <Footar/>
        </div>
    </>
  );
}
