import React from "react";

export default function AntierSemi() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>P2P Lending Development Services</h2>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/custom_p2p_lending_platform.svg" />
                  </div>
                  <h3>Custom P2P Lending Platform</h3>
                  <p>
                    Develop tailored platforms that allow borrowers and lenders
                    to connect directly, minimizing intermediaries and enhancing
                    returns.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/smart_contract_integration.svg" />
                  </div>
                  <h3>Smart Contract Integration</h3>
                  <p>
                    Automate lending contracts and ensure trustless transactions
                    with secure, transparent smart contracts to manage loan
                    terms and repayments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/risk_management_scoring_algorithms.svg" />
                  </div>
                  <h3>Risk Management & Scoring Algorithms</h3>
                  <p>
                    Implement custom algorithms for borrower risk assessment,
                    enabling safe lending through data-driven scoring models.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/compliance_security.svg" />
                  </div>
                  <h3>Compliance & Security</h3>
                  <p>
                    Our platforms are built to comply with KYC, AML, and
                    financial regulations, incorporating multi-factor
                    authentication and secure data storage.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/multi_currency_payment_gateway_support.svg" />
                  </div>
                  <h3>Multi-Currency & Payment Gateway Support</h3>
                  <p>
                    Enable multiple currencies, both fiat and crypto, along with
                    payment gateways to facilitate seamless fund transfers
                    between users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-3">
                    <img style={{width:"70px"}} src="assets/img/securitization/svg/user_friendly_interface.svg" />
                  </div>
                  <h3>User-Friendly Interface</h3>
                  <p>
                    Design an intuitive UI/UX, ensuring ease of use for both
                    borrowers and lenders with features like loan tracking,
                    payment alerts, and real-time analytics.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
