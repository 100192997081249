import React from "react";

export default function TopTierDeFi() {
  return (
    <>
      <section
        className="feature-section pt_80 pb_150"

      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="">
                <img
                  src="assets/img/defi/DeFi-Development-Service-by-Xeer-Technology-Section-Image-File.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>DeFi Development Service by Xeer Technology</h2>
                  <p className="pt-3">
                    At Xeer Technology, we offer comprehensive DeFi development
                    services designed to empower businesses and entrepreneurs to
                    harness the potential of decentralized finance. Our expert
                    team leverages cutting-edge blockchain technology to create
                    innovative, secure, and scalable DeFi solutions that meet
                    the evolving needs of the market. Whether you’re looking to
                    launch a decentralized exchange, a lending platform, or a
                    yield farming solution, we provide end-to-end services that
                    cover every aspect of DeFi development.
                  </p>
                </div>
                <div className="pt-5">
                  <a href="/contact-us" className="theme-btn btn-one">
                    Talk to us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
