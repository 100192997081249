import React from "react";

export default function WhyYield() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology For Yield Farming Platform Development?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    Xeer Technology stands out as a trusted partner for yield
                    farming platform development due to our deep expertise in
                    decentralized finance (DeFi) and our commitment to
                    delivering secure, scalable, and innovative solutions. We
                    tailor platforms to your specific needs, incorporating
                    advanced features like automated smart contracts,
                    multi-asset support, and cross-chain compatibility.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Dedicated Support</h4>
                      <p>
                        Our team provides round-the-clock support, ensuring that
                        any issues or questions are promptly addressed to keep
                        your platform running smoothly.
                      </p>
                    </li>
                    <li>
                      <h4>Experienced Team</h4>
                      <p>
                        With years of expertise in blockchain and exchange
                        development, our skilled developers create reliable,
                        high-performance margin trading platforms tailored to
                        your needs.
                      </p>
                    </li>
                    <li>
                      <h4>Standard Development Practice</h4>
                      <p>
                        We follow industry-standard development practices,
                        ensuring secure, compliant, and scalable platforms with
                        robust functionality and future-ready infrastructure.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
