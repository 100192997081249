import React, { useState } from "react";

export default function MultiExchange() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      <section className="faq-section testimonial-page-one pt_140 pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title centred mb_55">
            <h2 className="">
              What Features Would You Get In Arbitrage <br /> Bot Developed By Us
            </h2>
            <p className="">
              Check out features of the crypto arbitrage bot offered by us.
            </p>
          </div>
          <div className="inner-box mr_30">
            <ul className="accordion-box">
              <li className="accordion block active-block">
                <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(0)} />
                  <h4 className="">Multi-Exchange Integration</h4>
                </div>
                <div className={`acc-content ${toggle === 0 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Our bot supports seamless integration with a wide range of
                      cryptocurrency exchanges through APIs, allowing it to
                      monitor and trade across multiple platforms
                      simultaneously. Developers can easily add or remove
                      exchanges using modular connectors.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(1)} />
                  <h4 className="">Real-Time Data Processing</h4>
                </div>
                <div className={`acc-content ${toggle === 1 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      The bot is designed to handle real-time price tracking
                      with minimal latency, ensuring that it identifies
                      arbitrage opportunities as they arise. Developers can
                      optimize data pipelines to fetch, process, and act on live
                      market data instantly.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(2)} />
                  <h4 className="">Customizable Trading Algorithms</h4>
                </div>
                <div className={`acc-content ${toggle === 2 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Built with flexibility in mind, the bot allows developers
                      to implement custom trading strategies. It supports
                      various arbitrage methods, such as triangular and spatial
                      arbitrage, giving room for experimentation with different
                      profit-maximizing techniques.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(3)} />
                  <h4 className="">High-Speed Order Execution</h4>
                </div>
                <div className={`acc-content ${toggle === 3 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Optimized for fast order placement, our bot minimizes the
                      risk of missed opportunities due to lag. Developers can
                      fine-tune execution speed to align with exchange-specific
                      requirements for API calls and ensure timely trades.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(4)} />
                  <h4 className="">Risk Management and Safeguards</h4>
                </div>
                <div className={`acc-content ${toggle === 4 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Integrated risk management features help minimize
                      potential losses from factors like slippage, network
                      delays, or market crashes. Developers can configure
                      stop-loss triggers, maximum exposure limits, and other
                      safeguard mechanisms.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(5)} />
                  <h4 className="">Advanced Logging and Analytics</h4>
                </div>
                <div className={`acc-content ${toggle === 5 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      The bot includes comprehensive logging capabilities for
                      all trade activities, making it easier for developers to
                      track performance, debug issues, and generate detailed
                      reports. Analytics modules offer insights into success
                      rates and potential optimizations.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 6 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(6)} />
                  <h4 className="">Scalability and Load Balancing</h4>
                </div>
                <div className={`acc-content ${toggle === 6 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Designed to handle high volumes of transactions and
                      large-scale deployments, the bot’s architecture supports
                      horizontal scaling. Developers can easily scale operations
                      to run on multiple servers or virtual machines, ensuring
                      performance stability as trading volume increases.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 7 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(7)} />
                  <h4 className="">
                    Secure Authentication and API Key Management
                  </h4>
                </div>
                <div className={`acc-content ${toggle === 7 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      Developers benefit from built-in security features,
                      including encrypted storage for API keys and secure
                      authentication methods like OAuth, ensuring sensitive
                      information is protected from unauthorized access.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 8 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(8)} />
                  <h4 className="">Cross-Platform Compatibility</h4>
                </div>
                <div className={`acc-content ${toggle === 8 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      The bot is compatible with multiple programming languages
                      and operating systems, enabling developers to deploy it in
                      diverse environments. This flexibility allows for smooth
                      integration into existing trading infrastructure.
                    </p>
                  </div>
                </div>
              </li>
              <li className="accordion block">
                <div className={`acc-btn ${toggle === 9 ? "active" : ""}`}>
                  <div className="icon-box" onClick={() => settoggle(9)} />
                  <h4 className="">Backtesting and Simulation Tools</h4>
                </div>
                <div className={`acc-content ${toggle === 9 ? "current" : ""}`}>
                  <div className="text">
                    <p>
                      To ensure strategy optimization before live deployment,
                      the bot offers backtesting capabilities. Developers can
                      simulate trades based on historical market data and
                      fine-tune parameters to evaluate the performance of
                      different strategies.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
