import React from "react";
import Header from "../Coman/Header";
import AMMDexHero from "./AMMDexHero";
import Footar from "../Coman/Footar";
import Advantageous from "./Advantageous";
import Providers from "./Providers";
import AMMSolution from "./AMMSolution";
import AmmDEXDev from "./AmmDEXDev";
import WhyChooAMM from "./WhyChooAMM";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import WhatIsAmmDEX from "./WhatIsAmmDEX";

export default function AMMDex() {
  return (
    <div>
      <Header />
      <AMMDexHero />
      <WhatIsAmmDEX />
      <AMMSolution />
      <Advantageous />
      <AmmDEXDev />
      <Providers />
      <WhyChooAMM />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
