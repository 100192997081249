import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import ExchangeHero from './ExchangeHero'
import InvestCrypto from './InvestCrypto'
import Decade from './Decade'
import Delivering from './Delivering'
import Cryptocurrency from './Cryptocurrency'
import BoostBottom from './BoostBottom'
import TypesCrypto from './TypesCrypto'
import CryptoBuild from './CryptoBuild'
import LookingD from '../About-Us/LookingD'
import ExchangeCBlog from './ExchangeCBlog'
import TechnologiesweWork from '../Home/TechnologiesweWork'

export default function Exchange() {
  return (
    <div class="boxed_wrapper ltr">
        <Header/>
        <ExchangeHero/>
        <InvestCrypto/>
        <Decade/>
        <Delivering/>
        <Cryptocurrency/>
        {/* <BoostBottom/> */}
        <TypesCrypto/>
        <TechnologiesweWork/>
        {/* <CryptoBuild/> */}
        <ExchangeCBlog/>
        <LookingD/>
        <Footar/>
      </div>
  )
}
