import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CoinDevelopmentHero() {
  return (
    <>
      <section
        className="feature-section p_150"
       style={{backgroundImage:"url(assets/img/coin-dev/crypto-token-development.jpg)"}}
      >
        <div className="auto-container py-4">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Crypto Token Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    We offer end-to-end Coin or Token Development services that
                    empower businesses to leverage blockchain technology for
                    secure, scalable, and innovative digital payments!
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40">
                <div className="image-box">
                  <img
                    className="vert-move"
                    src="assets/images/resource/feature-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
