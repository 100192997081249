import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DefiStakingHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/defi/DeFi-Staking-Platform-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container ">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">DeFi Staking Platform Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Unlock the full potential of DeFi with our Staking Platform
                    Development service! We specialize in building secure,
                    scalable, and user-friendly staking platforms that allow
                    users to earn rewards by locking their digital assets in a
                    decentralized environment.
                  </p>
                </div>
              </div>
              <TalkToOurTeamBTN/>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              {/* <div className="image_block_two ml_40">
                <div className="image-box">
                  <figure className="image image-1">
                    <img
                      className="vert-move"
                      src="assets/images/resource/feature-1.jpg"
                      alt=""
                    />
                  </figure>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
