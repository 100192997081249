import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MarketMakingServicesHero() {
  return (
    <>
      <section
        className="faq-style-three p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/exchange/Crypto-Market-Making-Services-Hero-Banner-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row clearfix pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Crypto Market Making Services</h2>
                  <p className="text-white pt-3">
                    Boost your crypto liquidity and stabilize your asset prices
                    today with our expert market making services!
                  </p>
                </div>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
