import React from "react";

export default function DetailedListing() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our Token Listing Service</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="pb-3">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/detailed_listing_preparation.svg" />
                  </div>
                  <h3>Detailed Listing Preparation</h3>
                  <p>
                    End-to-end guidance in assembling the required documentation
                    and aligning with platform listing standards for a
                    successful submission.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="pb-3">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/platform_specific_compliance.svg" />
                  </div>
                  <h3>Platform-Specific Compliance</h3>
                  <p>
                    Support in navigating the unique requirements of various
                    listing platforms, ensuring regulatory compliance and
                    adherence to each platform's criteria.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="pb-3">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/market_positioning_strategy.svg" />
                  </div>
                  <h3>Market Positioning Strategy</h3>
                  <p>
                    Development of a customized listing strategy to maximize
                    your token’s visibility and traction across the market.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="pb-3">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/liquidity_solutions.svg" />
                  </div>
                  <h3>Liquidity Solutions</h3>
                  <p>
                    Ongoing liquidity management solutions designed to stabilize
                    trading, promote market confidence, and attract investor
                    interest.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="pb-3">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/community_engagement_marketing.svg" />
                  </div>
                  <h3>Community Engagement & Marketing</h3>
                  <p>
                    Comprehensive marketing plans and engagement strategies to
                    drive token visibility and build a loyal community.
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </>
  );
}
