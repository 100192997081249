import React from "react";

import BlockchainGameDevelopmentHero from "./BlockchainGameDevelopmentHero";
import BlockchainGameDevelopmentEdge from "./BlockchainGameDevelopmentEdge";
import CryptoGameDevelopment from "./CryptoGameDevelopment";
import Web3GameDevelopment from "./Web3GameDevelopment";
import BestGameDevelopers from "./BestGameDevelopers";
import IscussYourGame from "./IscussYourGame";
import GameDevelopmentCompany from "./GameDevelopmentCompany";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import Header from "../../Coman/Header";

export default function BlockchainGameDevelopment() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <BlockchainGameDevelopmentHero />
        {/* <BlockchainGameDevelopmentEdge /> */}
        <CryptoGameDevelopment />
        <Web3GameDevelopment />
        {/* <BestGameDevelopers /> */}
        {/* <IscussYourGame /> */}
        {/* <GameDevelopmentCompany /> */}
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
