import React from "react";
import Marquee from "react-fast-marquee";

export default function Integrations() {
  return (
    <>
      <section className="integrations-section centred pb_150 teg-img-bgimg">
        <div className="">
          <div className="sec-title mb_60">
            <div className="inner_wrap">
              <h2>Technologies we work with</h2>
              <span class="border_btm">
                We have worked on several leading blockchain networks including
                Ethereum, Solana, Polygon, BNB Chain, Tron, Arbitrum & many
                more.
              </span>
            </div>
          </div>
          <Marquee pauseOnHover="false">
            <ul className=" d-flex gap-3 align-items-center ">
              <div className="img-box-home" style={{ marginLeft: "0.5%" }}>
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/bsc.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/btc.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/cardano.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/eth.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/hedera.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/near.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/polkadot.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/polygon.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/solana.svg"
                    alt=""
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/steller.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/tron.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/waves.svg"
                    alt=""
                  />
                </li>
              </div>
            </ul>
          </Marquee>

          <Marquee direction="right" speed="150" pauseOnHover="false">
            <ul className=" d-flex gap-3 align-items-center ">
              <div className="img-box-home" style={{ marginLeft: "0.5%" }}>
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Amazon.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Angular.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Drupal.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Express_JS.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Flutter.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Github.svg"
                    alt=""
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/GO.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/java.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Laravel.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/MongoDB.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/MySQL.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Next.JS.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Node_JS.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Nuxt.JS.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="">
                  <img
                    style={{ width: "55%" }}
                    src="assets/img/icon/icon1/Oracle.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/python.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/react.svg"
                    alt=""
                  />
                </li>
              </div>

              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Solidity.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className=" pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Swift.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Three_JS.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/Typescript.svg"
                    alt=""
                  />
                </li>
              </div>
              <div className="img-box-home">
                <li className="pt-3">
                  <img
                    style={{ width: "30%" }}
                    src="assets/img/icon/icon1/WebRTC.svg"
                    alt=""
                  />
                </li>
              </div>
            </ul>
          </Marquee>
        </div>
      </section>
    </>
  );
}
