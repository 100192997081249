import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function AMMDexHero() {
  return (
    <>
      <section className="feature-section p_150"
       style={{backgroundPosition: "50% 50%", backgroundSize: "cover",backgroundImage:"url(assets/img/dex/Automated-Market-Maker-DEX-Development-Hero-Section-Image.jpg)",}}
       >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Automated Market Maker (AMM) DEX Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology offers comprehensive development services
                    for Automated Market Maker (AMM) DEX platforms, empowering
                    DeFi projects to provide users with seamless, peer-to-peer
                    trading experiences without the need for traditional order
                    books.
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
          
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
