import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DexAggregatorHero() {
  return (
    <>
      <section className="feature-section p_150" style={{
        backgroundPosition: "50% 50%", backgroundSize: "cover",
        backgroundImage:
          "url(assets/img/dex/DEX-Aggregator-Development-Hero-Section-Image.jpg)",
      }}>
        <div className="auto-container py-3">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box">
                <h1 className="text-white">DEX Aggregator Development</h1>
                <p className="text-white pt-2">
                  Xeer Technology specializes in developing powerful DEX
                  aggregators that streamline decentralized trading by
                  connecting users with the best liquidity and optimal prices
                  across multiple decentralized exchanges (DEXs)!
                </p>
              </div>
              <div className="btn-box py-5">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
