import React from 'react'
import Header from '../../Coman/Header'
import BlogWeb from '../../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import CryptoAssetManHero from './CryptoAssetManHero'
import WhyArentYou from './WhyArentYou'
import Handpicking from './Handpicking'
import OurCryptoAsset from './OurCryptoAsset'
import TakeControl from './TakeControl'

export default function CryptoAssetManagement() {
  return (
    <div>
      <Header/>
      <CryptoAssetManHero/>
      <WhyArentYou/>
      <Handpicking/>
      <OurCryptoAsset/>
      <TakeControl/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
