import React from "react";
import Header from "../Coman/Header";
import Web3GameDevlopmentHero from "./Web3GameDevlopmentHero";
import Footar from "../Coman/Footar";
import DailyWith from "./DailyWith";
import WebGame from "./WebGame";
import WebGameDevS from "./WebGameDevS";
import WhyChooseWebdevGame from "./WhyChooseWebdevGame";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import Partner from "./Partner";

export default function Web3GameDevlopment() {
  return (
    <div>
      <Header />
      <Web3GameDevlopmentHero />
      <DailyWith />
      <WebGame />
      <WebGameDevS />
      <Partner />
      <WhyChooseWebdevGame />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
