import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import CMCListingHero from "./CMCListingHero";
import CoinMarketCap from "./CoinMarketCap";
import CompleteListing from "./CompleteListing";

export default function CMCListing() {
  return (
    <div>
      <Header />
      <CMCListingHero/>
      <CoinMarketCap/>
      <CompleteListing/>
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
