import React from "react";

export default function Exquisite() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container pb-3">
            <h1 className="text-center p-3">
              How Do We Develop a Clone Crypto Wallet App?
            </h1>
            <p className="px-5 text-center">
              At Xeer Technology, we follow a systematic approach to develop a
              robust and fully customizable crypto wallet clone.
            </p>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>01</h1> */}
                      </div>
                      <img
                      src="assets/img/requirement_analysis.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                    </div>

                    <h5 className="py-3">Requirement Analysis</h5>
                    <p>
                      We begin by understanding your specific business
                      requirements, target audience, and the features you want
                      to replicate from popular wallets like MetaMask, Trust
                      Wallet, or Coinbase Wallet. We also discuss any additional
                      functionality you need, such as multi-currency support,
                      staking, or custom branding.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>02</h1> */}
                      </div>
                      <img
                      src="assets/img/ui_ux_design_customization.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                    </div>
                    <h5 className="py-3">UI/UX Design Customization</h5>
                    <p>
                      Our team customizes the user interface and design based on
                      your brand identity, ensuring a sleek, user-friendly
                      experience. We replicate the intuitive designs of popular
                      wallets and tailor them to suit your preferences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>03</h1> */}
                      </div>
                      <img
                      src="assets/img/core_features_development.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                    </div>
                    <h5 className="py-3">Core Features Development</h5>
                    <p>
                      We clone the essential features of leading crypto wallets,
                      such as private key management, transaction history, QR
                      code scanning, in-app token swaps, and multi-signature
                      security. These features ensure seamless transactions and
                      secure storage of assets.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>04</h1> */}
                      </div>
                      <img
                      src="assets/img/security_enhancements.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                    </div>
                    <h5 className="py-3">Security Enhancements</h5>
                    <p>
                      Security is at the core of our wallet clone development.
                      We implement encryption protocols, biometric
                      authentication, multi-factor authentication (MFA), and
                      seamless integration of KYC/AML protocols to ensure
                      compliance and safeguard user assets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>05</h1> */}
                      </div>
                      <div className="image-box">
                 
                   <img
                      src="assets/img/testing_&_quality_assurance.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                  
                </div>
                    </div>
                    <h5 className="py-3">Testing & Quality Assurance</h5>
                    <p>
                      Our team conducts extensive testing to identify and fix
                      any bugs or issues. We ensure the wallet operates smoothly
                      across different platforms and performs securely under
                      high-volume transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className="">
                    <div className="icon-box">
                      <div className="num-box">
                        {/* <h1>06</h1> */}
                      </div>
                      <img
                      src="assets/img/deployment.svg"
                      className="img-fluid"
                      alt=""
                      style={{height:"40px",width:"40px"}}
                    /> 
                    </div>
                    <h5 className="py-3">Deployment</h5>
                    <p>
                      Once the wallet is thoroughly tested and approved, we
                      deploy it across platforms, including web, Android, and
                      iOS, ensuring a smooth launch and compatibility with
                      various operating systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
