import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function LeverageAndMargin() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>What Security We Offer With Our Margin Trading Exchange</h2>
            <p>
              At Xeer Technology, we prioritize the security of our crypto
              margin trading exchange by implementing advanced features designed
              to safeguard our platform and its users.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div >
                    <img style={{width:"75px"}} src="assets/img/exchange/mte/wallet_security.svg" />
                  </div>
                  <h3>Wallet Security</h3>
                  <p>
                    We employ multi-signature withdrawal mechanisms to enhance
                    wallet security, ensuring that only authorized users can
                    access and transfer funds, significantly reducing the risk
                    of unauthorized transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"331px"}}>
                  <div >
                    <img style={{width:"75px"}} src="assets/img/exchange/mte/system_security.svg" />
                  </div>
                  <h3>System Security</h3>
                  <p>
                    Our platform utilizes multi-level authentication protocols,
                    providing an added layer of protection against unauthorized
                    access and ensuring that user accounts remain secure at all
                    times.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"331px"}}>
                  <div >
                    <img style={{width:"75px"}} src="assets/img/exchange/mte/communication_security.svg" />
                  </div>
                  <h3>Communication Security</h3>
                  <p>
                    We implement high-level encryption for all communications
                    within the platform, safeguarding sensitive data and
                    ensuring that user interactions remain confidential and
                    protected from potential threats.
                  </p>
                </div>
              </div>
            </div>
          </div>
      <TalkToOurTeamBTN/>
        </div>
      </section>
    </>
  );
}
