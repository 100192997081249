import React from "react";

export default function PitchDeckDesign() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">Pitch Deck Design</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/marketing/pitch_deck_design.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h4>Impress Investors with Compelling Pitch Decks</h4>
                  <p className="py-3">
                    We create visually appealing and persuasive pitch decks to
                    help you communicate your project’s potential effectively to
                    investors. Our designs focus on clarity, structure, and
                    impact.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Clear Messaging</h4>
                      <p>
                        Simplify complex information for investor understanding.
                      </p>
                    </li>
                    <li>
                      <h4>Visual Storytelling</h4>
                      <p> Use visuals to make your pitch more engaging.</p>
                    </li>
                    <li>
                      <h4>Investor Focused</h4>
                      <p>Highlight what matters most to potential investors.</p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Get a Custom Pitch Deck
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
