import React from "react";
import Marquee from "react-fast-marquee";

export default function InvestCrypto() {
  return (
    <>
      {/* <section className="integrations-section centred py_150">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-20.png)" }}
        />
        <div className="">
          <div className="sec-title mb_60">
            <div className="inner_wrap">
              <h2>Why build your own crypto exchange?</h2>
              <p>
                The cryptocurrency adoption is increasing rapidly with millions
                of new users signing-up at crypto exchanges regularly. With this{" "}
                <br />
                much exposure and potential for wider expansion, launching a
                crypto exchange seems feasible in the ever-increasing market
                demand.
              </p>
            </div>
          </div>
          <Marquee pauseOnHover="false">
            <ul className=" d-flex gap-3 align-items-center ">
              <div className="box-white-hover" style={{marginLeft:"1%"}}>
                <h4>Crypto Adoption Growth Rate (2024)</h4>
                <p className="p-3">
                  Global crypto adoption is expected to grow by 13-15% in 2024,
                  with an estimated 500 million users globally by the end of the
                  year.
                </p>
              </div>
              <div className="box-white-hover">
                <h4>Investments in Crypto Startups</h4>
                <p className="p-3">
                  Venture capital investment in cryptocurrency and blockchain
                  startups is projected to surpass $30 billion in 2024, fueled
                  by growing institutional interest and innovations in
                  decentralized finance (DeFi) and blockchain technology.
                </p>
              </div>
              <div className="box-white-hover">
                <h4>Crypto Exchange Earnings</h4>
                <p className="p-3">
                  Major crypto exchanges like Binance, Coinbase, and Kraken are
                  projected to earn a combined total of $15-20 billion in
                  revenue by the end of 2024, driven by increased trading
                  volumes and expanded services.
                </p>
              </div>
        
              <div className="box-white-hover">
                <h4 className="pt-2">Total Cryptocurrency Market Value</h4>
                <p className="p-3">
                  The global market capitalization of cryptocurrencies is
                  projected to reach $2.5 trillion by the end of 2024, with
                  Bitcoin and Ethereum maintaining dominance but increasing
                  competition from newer blockchains and altcoins.
                </p>
              </div>
              <div className="box-white-hover">
                <h4>Institutional Adoption</h4>
                <p className="p-3">
                  Over 40% of institutional investors are expected to have
                  exposure to cryptocurrency assets in 2024, showing increasing
                  mainstream acceptance of digital currencies as part of
                  investment portfolios.
                </p>
              </div>
            </ul>
          </Marquee>
        </div>
      </section> */}
      
      <section className="about-style-three p_100">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Why build your own crypto exchange?</h2>
            <p className="p-3">
              The cryptocurrency adoption is increasing rapidly with millions
              of new users signing-up at crypto exchanges regularly. With this             
              much exposure and potential for wider expansion, launching a
              crypto exchange seems feasible in the ever-increasing market
              demand.
            </p>
          </div>
          <div className="content_block_four">
            <div className="content-box ml_40">
              <div className="inner-box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-item my_10">
                      <div className="icon-box">
                        <img src="assets/img/exchange/svg/crypto_adoption_growth_rate.svg" />
                      </div>
                      <h4>Crypto Adoption Growth Rate (2024)</h4>
                      <p className="pt-2">
                        Global crypto adoption is expected to grow by 13-15% in 2024,
                        with an estimated 500 million users globally by the end of the
                        year.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/exchange/svg/investments_in_crypto_startups.svg" />
                      </div>
                      <h4>Investments in Crypto Startups</h4>
                      <p className="pt-2">
                        Venture capital investment in cryptocurrency and blockchain
                        startups is projected to surpass $30 billion in 2024, fueled
                        by growing institutional interest and innovations in
                        decentralized finance (DeFi) and blockchain technology.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/exchange/svg/crypto_exchange_earnings.svg" />
                      </div>
                      <h4>Crypto Exchange Earnings</h4>
                      <p className="pt-2">
                        Major crypto exchanges like Binance, Coinbase, and Kraken are
                        projected to earn a combined total of $15-20 billion in
                        revenue by the end of 2024, driven by increased trading
                        volumes and expanded services.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/exchange/svg/total_cryptocurrency_market_value.svg" />
                      </div>
                      <h4>Total Cryptocurrency Market Value</h4>
                      <p className="pt-2">
                        The global market capitalization of cryptocurrencies is
                        projected to reach $2.5 trillion by the end of 2024, with
                        Bitcoin and Ethereum maintaining dominance but increasing
                        competition from newer blockchains and altcoins.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-item mb_50">
                      <div className="icon-box">
                        <img src="assets/img/exchange/svg/institutional_adoption.svg" />
                      </div>
                      <h4>Institutional Adoption</h4>
                      <p className="pt-2">
                        Over 40% of institutional investors are expected to have
                        exposure to cryptocurrency assets in 2024, showing increasing
                        mainstream acceptance of digital currencies as part of
                        investment portfolios.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
