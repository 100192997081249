import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import TransportandLogisticsHero from "./TransportandLogisticsHero";
import BlockchainforTransportation from "./BlockchainforTransportation";
import IntegratingBlockchain from "./IntegratingBlockchain";
import BlockchaininTransport from "./BlockchaininTransport";

export default function TransportandLogistics() {
  return (
    <>
        <Header/>
        <TransportandLogisticsHero/>
        <BlockchainforTransportation/>
        {/* <IntegratingBlockchain/> */}
        <BlockchaininTransport/>       
        <BlogsNFT/>
        <LookingD/>
        <Footar/>
    </>
  );
}
