import React from "react";

export default function TheGlobal() {
  return (
    <>
      {/* <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="image_block_two ml_40">
                <div
                  className="image-shape-2"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-14.png)",
                  }}
                />
                <div className="image-box">
                  <figure className="image image-1">
                    <img src="assets/images/resource/feature-1.jpg" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Crypto Wallet Development Service by Xeer Technology</h2>
                </div>
                <div className="text-box">
                  <p>
                    Xeer Technology offers customized crypto wallet development
                    services, creating secure, scalable, and user-friendly
                    wallets for businesses and individuals. Our wallets support
                    multiple cryptocurrencies, providing seamless integration
                    with blockchain networks. Key features include:
                  </p>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-section pt_120 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                style={{width:"100%"}}
                  src="assets/img/Crypto-Wallet-Development-Service-by-Xeer-Technology-Section-Image.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Crypto Wallet Development Service by Xeer Technology</h2>
                  <p className="pt-3">
                    Xeer Technology offers customized crypto wallet development
                    services, creating secure, scalable, and user-friendly
                    wallets for businesses and individuals. Our wallets support
                    multiple cryptocurrencies, providing seamless integration
                    with blockchain networks. Key features include:
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <div className="row">
                      <div className="col">
                        <li>
                          <h4>Multi-Currency Support</h4>
                          <p>Manage various digital assets in one place.</p>
                        </li>
                        <li>
                          <h4>Robust Security</h4>
                          <p>
                            Enhanced security through encryption, two-factor
                            authentication, and multi-signature protection.
                          </p>
                        </li>
                        <li>
                          <h4>Cross-Platform Compatibility</h4>
                          <p>
                            Available on mobile, desktop, and web platforms.
                          </p>
                        </li>
                        <li>
                          <h4>Cold and Hot Wallet Solutions</h4>
                          <p>
                            Options for both high-security offline (cold)
                            wallets and online (hot) wallets for easy access.
                          </p>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
