import React from "react";

export default function CosmosSuite() {
  return (
    <>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h3>
                    Benefits of Cosmos SDK with
                    <span className="xeer-color"> Xeer Technology</span>
                  </h3>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className=" mb-0">
                            <img style={{width:"50px"}} src="assets/img/blockchain-dev/SVG/scalability.svg" />
                          </div>
                          <h5 className="ms-3">Scalability</h5>
                        </div>
                        <p>
                          Cosmos SDK is designed for highly scalable
                          applications, supporting business expansion.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className=" mb-0">
                            <img style={{width:"50px"}} src="assets/img/framework/SVG/sidechain_integration.svg" />
                          </div>
                          <h5 className="ms-3">Interoperability</h5>
                        </div>
                        <p>
                          The IBC protocol allows easy integration with other
                          blockchains in the Cosmos network.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className=" mb-0">
                            <img style={{width:"50px"}} src="assets/img/blockchain-dev/SVG/enhanced_security.svg" />
                          </div>
                          <h5 className="ms-3">Security</h5>
                        </div>
                        <p>
                          With built-in security protocols and Xeer’s
                          experienced developers, we ensure robust data
                          protection.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                    <div
                      className="feature-block-one aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration={700}
                    >
                      <div className="inner-box">
                        <div className="d-flex align-items-center">
                          <div className=" mb-0">
                            <img style={{width:"50px"}} src="assets/img/framework/SVG/decentralized.svg" />
                          </div>
                          <h5 className="ms-3">Decentralization</h5>
                        </div>
                        <p>
                          Cosmos SDK supports distributed ledgers, promoting
                          decentralized governance for your project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className>
                <img
                  src="assets/img/framework/Benefits-of-Cosmos-SDK-with-Xeer-Technology-Section-Image.png"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
