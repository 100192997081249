import React from 'react'
import Header from '../Coman/Header'
import DefiDevelopmentHero from './DefiDevelopmentHero'
import Footar from '../Coman/Footar'
import Growing from './Growing'
import YearsOfBS from './YearsOfBS'
import TheParadigm from './TheParadigm'
import DevGlobalS from './DevGlobalS'
import TopTierDeFi from './TopTierDeFi'
import Simplification from './Simplification'
import AssetManagement from './AssetManagement'
import BusinessGoals from './BusinessGoals'
import Uniswap from './Uniswap'
import AssetMan from './AssetMan'
import ByPartnering from './ByPartnering'
import Blog from '../Home/Blog'
import LookingD from '../About-Us/LookingD'

export default function DefiDevelopment() {
  return (
    <div>
        <Header/>
        <DefiDevelopmentHero/>
        {/* <YearsOfBS/> */}
        <TheParadigm/>
        <DevGlobalS/>
        <TopTierDeFi/>
        <Simplification/>
        {/* <Growing/> */}
        <AssetManagement/>
        <BusinessGoals/>
        {/* <Uniswap/> */}
        {/* <AssetMan/> */}
        <ByPartnering/>
        <Blog/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
