import React from "react";

export default function InfluenceMarketing() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">Influence Marketing</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h4>Boost Engagement with Trusted Industry Influencers</h4>
                  <p className="py-3">
                    Our influence marketing service leverages the power of
                    social proof. By working with respected influencers, we help
                    increase your project’s visibility, boost credibility, and
                    drive user engagement.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Curated Influencer Network</h4>
                      <p> Access to a network of high-reach influencers.</p>
                    </li>
                    <li>
                      <h4>Influencer-Driven Content</h4>
                      <p>
                        Create authentic content that resonates with audiences.
                      </p>
                    </li>
                    <li>
                      <h4>Brand Visibility Boost</h4>
                      <p>
                        Increase brand awareness through effective influencer
                        marketing.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Engage with Influencers for Impact
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/marketing/influence_marketing.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
