import React from 'react'
import Header from '../Coman/Header'
import DecentralizedExchangeHero from './DecentralizedExchangeHero'
import Footar from '../Coman/Footar'
import CryptoNew from './CryptoNew'
import CentralizedS from './CentralizedS'
import OurCentralized from './OurCentralized'
import DerivativesTrading from './DerivativesTrading'
import DerivativesTradingS from './DerivativesTradingS'
import OurCDEDevProcess from './OurCDEDevProcess'
import WhyChoseXEERed from './WhyChoseXEERed'
import Platform from './Platform'
import LookingD from '../About-Us/LookingD'

export default function DecentralizedExchange() {
  return (
    <div>
      <Header/>
      <DecentralizedExchangeHero/>
      <Platform/>
      <CryptoNew/>
      <CentralizedS/>
      <OurCentralized/>
      <DerivativesTrading/>
      {/* <DerivativesTradingS/> */}
      {/* <OurCDEDevProcess/> */}
      {/* <WhyChoseXEERed/> */}
      <LookingD/>
      <Footar/>
    </div>
  )
}
