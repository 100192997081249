import React from "react";

export default function Hero() {
  return (
    <>
      <section
        className="banner-style-six"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/hero_section_image.png)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30">
                <h5 className="text-white">Your Friendly</h5>
                <h1 className="text-white">Blockchain Development Company</h1>

                <p className="text-white">
                  Connect with Experienced Developers & Professionals From Web3
                  Industry
                </p>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li className="text-white">7+ Years of Experience</li>
                    <li className="text-white">100+ Clients Served</li>
                    <li className="text-white">120+ Projects Developed</li>
                  </ul>
                </div>
                <div className="btn-box mt_40">
                  <a href="/contact-us" className="theme-btn btn-one mr_30">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              {/* <div className="image-box ml_40 mr_50">
                <div className="image-shape">
                  <div
                    className="shape-1"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-47.png)",
                    }}
                  />
                  <div
                    className="shape-2 rotate-me"
                    style={{
                      backgroundImage: "url(assets/images/shape/shape-48.png)",
                    }}
                  />
                </div>
                <figure className="image">
                  <img src="assets/images/resource/dashboard-6.jpg" alt="" />
                </figure>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
