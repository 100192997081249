import React from "react";

export default function KnowYour() {
  return (
    <>
      <section className="contact-info-section centred pt_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features of a Crypto Launchpad</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  
                    <img
                      src="assets/img/coin-dev/early-access-to-token-sales.svg"
                      height={60}
                      width={60}
                    />
                  
                  <h3>Early Access to Token Sales</h3>
                  <p>
                    Investors gain access to promising projects at lower prices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  
                    <img
                      src="assets/img/coin-dev/community-engagement.svg"
                      height={60}
                      width={60}
                    />
                
                  <h3>Community Engagement</h3>
                  <p>
                    Projects can build a community and generate interest before
                    their full launch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                 
                    <img
                      src="assets/img/coin-dev/secure-fundraising-process.svg"
                      height={60}
                      width={60}
                      className="mb-2"
                    />
                  
                  <h3>Secure Fundraising Process</h3>
                  <p>
                    Most launchpads include KYC/AML protocols and smart contract
                    audits to ensure a secure fundraising.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  
                    <img
                      src="assets/img/coin-dev/multi-blockchain-support.svg"
                      height={60}
                      width={60}
                    />
                  
                  <h3>Multi-Blockchain Support</h3>
                  <p>
                    Many launchpads support different blockchains, allowing
                    projects to choose the best network for their needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
