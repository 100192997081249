import React from "react";

export default function OurOPStackSer() {
  return (
    <>
      <section className="feature-style-two pt-5 pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our OP Stack Development Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                    <img
                      src="./assets/img/polygon/custom_layer_solutions.svg"
                      className="img-fluid mb-2"
                      width={40}
                    />
                  </div>
                  <h3>Custom Layer-2 Solutions</h3>
                  <p>
                    We build custom layer-2 applications using OP Stack to
                    enhance scalability, reduce costs, and ensure high
                    transaction throughput.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/blockchain-dev/SVG/smart_contract_development_audit.svg"
                      className="img-fluid mb-2"
                      width={40}
                    />
                  </div>
                  <h3>Smart Contract Development & Audit</h3>
                  <p>
                    Xeer’s team creates secure, reliable smart contracts
                    tailored to your specific needs, rigorously tested for
                    performance and security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                  <img src="assets/img/Optimistic Rollup Implementation.svg" style={{width:"44px"}} />
                  </div>
                  <h3>Optimistic Rollup Implementation</h3>
                  <p>
                    With OP Stack, we deploy optimistic rollups to minimize gas
                    fees and support high transaction volume, improving user
                    experience and network efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                  <img src="assets/img/cross_platform_access.svg" style={{width:"44px"}} />
                  </div>
                  <h3>Cross-Chain Integrations</h3>
                  <p>
                    Our cross-chain solutions enable secure data and asset
                    transfers across blockchains, maximizing your project’s
                    reach and interoperability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                  <img src="assets/img/nfts.svg" style={{width:"44px"}} />
                  </div>
                  <h3>NFT Platform Development</h3>
                  <p>
                    Develop NFT marketplaces optimized with OP Stack, offering
                    users fast, cost-effective trading solutions with Ethereum
                    compatibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "320px" }}>
                  <div className="icon-box">
                  <img src="assets/img/defi_app.svg" style={{width:"44px"}} />
                  </div>
                  <h3>DeFi Platform Development</h3>
                  <p>
                    We design scalable DeFi applications with staking, lending,
                    and yield farming, providing a seamless, low-cost user
                    experience on Ethereum-compatible layer-2 networks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
