import React from "react";

export default function OurCryptoAsset() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Crypto Asset Management Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"360px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/asset-tok/svg/multi_asset_portfolio_management.svg" />
                  </div>
                  <h3>Multi-Asset Portfolio Management</h3>
                  <p>
                    Manage diverse portfolios of cryptocurrencies and digital
                    assets across multiple wallets and exchanges, all in one
                    unified dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"360px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/asset-tok/svg/institutional_grade_security.svg" />
                  </div>
                  <h3>Institutional-Grade Security</h3>
                  <p>
                    Implement advanced security protocols including
                    multi-signature wallets, hardware wallets, and two-factor
                    authentication to safeguard your assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"360px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/asset-tok/svg/automated_reporting_and_analytics.svg" />
                  </div>
                  <h3>Automated Reporting and Analytics</h3>
                  <p>
                    Gain insights with automated performance tracking, real-time
                    analytics, and custom reporting to make informed investment
                    decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"360px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/asset-tok/svg/risk_management_and_compliance.svg" />
                  </div>
                  <h3>Risk Management and Compliance</h3>
                  <p>
                    Navigate regulatory complexities with our tailored
                    compliance tools that ensure adherence to AML, KYC, and
                    other financial regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"360px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/asset-tok/svg/defi_and_staking_management.svg" />
                  </div>
                  <h3>DeFi and Staking Management</h3>
                  <p>
                    Maximize returns with staking, yield farming, and liquidity
                    provision services for DeFi assets, integrated directly into
                    your management dashboard.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
