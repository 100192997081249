import React from "react";

export default function AntiersExpertise() {
  return (
    <>
      <section
        className="feature-section  p_100 "
        // style={{ backgroundColor: "#f5f5f5" }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box">
                <div data-animation-box className="sec-title mb_25">
                  <h2>NFT Development Service by Xeer Technology</h2>
                  <p className="py-3">
                    At Xeer Technology, we specialize in offering comprehensive
                    NFT development services, tailored to meet the unique needs
                    of creators, businesses, and developers looking to enter the
                    fast-growing world of non-fungible tokens. From
                    conceptualization to deployment, we help you build and
                    launch NFTs that stand out in the marketplace.
                  </p>
                </div>
                <div className="text-box ">
                  <ul className="list-style-one clearfix">
                    <li>Designing Unique Digital Arts</li>
                    <li>Minting Tokens on Blockchain </li>
                    <li>Managing NFT Collections</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <img style={{height:"625px"}}
                  src="assets/img/nft_dev/nft_development_service_by_xeer_technology.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
