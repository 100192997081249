import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function WhiteLabelWalletHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/wallet/White-Label-Crypto-Wallet-Hero-Section-Image-File.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">White Label Crypto Wallet</h2>
                  <p className="text-white pt-3">
                    Our White Label Crypto Wallet solution enables businesses to
                    launch their own branded digital wallets! These wallets are
                    fully customizable to meet unique requirements and provide a
                    seamless way to integrate blockchain technology into your
                    ecosystem while offering secure, reliable & efficient
                    transactions!
                  </p>
                </div>
                <div className="text-box ">
                  <ul className="list-style-one clearfix">
                    <li className="text-white">Fully Customizable</li>
                    <li className="text-white">East Integrations</li>
                    <li className="text-white">Quick Launch</li>
                  </ul>
                </div>
                <div className="btn-box pt-5">
                 <TalkToOurTeamBTN/>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  src="assets/img/wallet/White-Label-Crypto-Wallet-Hero-Section-Image-File.jpg"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
