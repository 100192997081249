import React from 'react'
import Header from '../Coman/Header'
import DerivativesExchangeHero from './DerivativesExchangeHero'
import Footar from '../Coman/Footar'
import DerivativesBlogs from './DerivativesBlogs'
import Decentralized from './Decentralized'
import WhiteLabelE from './WhiteLabelE'
import DEXExchange from './DEXExchange'
import LetFirstClass from './LetFirstClass'
import ExchangeWeOffer from './ExchangeWeOffer'
import TypesOFDCrypto from './TypesOFDCrypto'
import OrderBook from './OrderBook'
import BookBasedDEX from './BookBasedDEX'
import DEXAggregator from './DEXAggregator'
import DecentralizedES from './DecentralizedES'
import LetsGetStarted from './LetsGetStarted'
import WhatMakesUsStand from './WhatMakesUsStand'
import WhyChooseXeer from './WhyChooseXeer'
import WhyDEX from './WhyDEX'
import CustodialTrading from './CustodialTrading'
import LowerMaintenance from './LowerMaintenance'
import LookingD from '../About-Us/LookingD'

export default function DerivativesExchange() {
  return (
    <div>
      <Header/>
      <DerivativesExchangeHero/>
      <WhyDEX/>
      <Decentralized/>
      <WhiteLabelE/>
      <DEXExchange/>
      {/* <LetFirstClass/> */}
      {/* <ExchangeWeOffer/> */}
      {/* <TypesOFDCrypto/> */}
      {/* <OrderBook/> */}
      <BookBasedDEX/>
      {/* add */}
      <CustodialTrading/>
      <LowerMaintenance/>
      {/* <DEXAggregator/> */}
      <DecentralizedES/>
      {/* <LetsGetStarted/> */}
      {/* <WhatMakesUsStand/> */}
      <WhyChooseXeer/>
      <DerivativesBlogs/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
