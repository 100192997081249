import React from "react";

export default function BlockchainDevelopmentPlatforms() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Blockchain Development Platforms We Support</h2>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Uniswap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/pancakeswap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/MDEX-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/sushiswap-sushi-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/BakerySwap-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/1inch-Exchange-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/DyDx-Exchange-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_100">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Layer-2-2.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/08/Dodo-1-1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt_100">
            <a href="contact-us" className="theme-btn btn-one">
              Get Your DEX Script
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
