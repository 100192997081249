import React from "react";

export default function HeroBlogs() {
  return (
    <>
      <section className="page-title centred pt_130 pb_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-64.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-65.png)" }}
          />
        </div>
        <div className="auto-container">
          <div className="content-box">
            <h1>Our Expert Insights</h1>
            <p>Deep dive into current technologies, industry updates, best strategies, trending and latest in the web3 space, all from an expert viewpoint.</p>
          </div>
        </div>
      </section>
    </>
  );
}
