import React from "react";

export default function DidYouKnow() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is White Label Crypto Exchange ?</h2>
              <p>
                A white label crypto exchange is a ready-made, customizable
                software solution that allows businesses to launch their own
                cryptocurrency exchange platform quickly. Essentially, it’s a
                pre-built crypto exchange platform that can be branded and
                tailored to meet specific requirements without having to develop
                the technology from scratch.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
