import React from "react";

export default function BuildingNFT() {
  return (
    <>
      {/* <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">
            Why Choose Xeer Technology for NFT Collection Launch?
          </h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <p>
                    With our expertise in blockchain development and a passion
                    for innovation, Xeer Technology is committed to delivering
                    NFT marketplaces that are not only functional but also
                    aligned with the latest market trends. Whether you’re an
                    artist looking to showcase your work or a business seeking
                    to enter the NFT space, our team is ready to bring your
                    vision to life.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Dedicated Support</h4>
                      <p>
                        Our team provides round-the-clock support, ensuring that
                        any issues or questions are promptly addressed to keep
                        your platform running smoothly.
                      </p>
                    </li>
                    <li>
                      <h4>Experienced Team</h4>
                      <p>
                        With years of expertise in blockchain and exchange
                        development, our skilled developers create reliable,
                        high-performance margin trading platforms tailored to
                        your needs.
                      </p>
                    </li>
                    <li>
                      <h4>Standard Development Practice</h4>
                      <p>
                        We follow industry-standard development practices,
                        ensuring secure, compliant, and scalable platforms with
                        robust functionality and future-ready infrastructure.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/why_choose_xeer_technology_for_margin_trading(1).svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why Choose Xeer Technology for NFT Collection Launch?
            </h2>
            <p className="p-3">
              With our expertise in blockchain development and a passion for innovation, Xeer Technology is committed to delivering NFT marketplaces that are not only functional but also aligned with the latest market trends. Whether you’re an artist looking to showcase your work or a business seeking to enter the NFT space, our team is ready to bring your vision to life.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/dedicated_support.svg" />
                  </div>
                  <h3 className="pt-4">Dedicated Support</h3>
                  <p>
                    Our team provides round-the-clock support, ensuring that any
                    issues or questions are promptly addressed to keep your
                    platform running smoothly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/experienced_team.svg" />
                  </div>
                  <h3 className="pt-4">Experienced Team</h3>
                  <p>
                    With years of expertise in blockchain and exchange
                    development, our skilled developers create reliable,
                    high-performance margin trading platforms tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/standard_development_practice.svg" />
                  </div>
                  <h3 className="pt-4">Standard Development Practice</h3>
                  <p>
                    We follow industry-standard development practices, ensuring
                    secure, compliant, and scalable platforms with robust
                    functionality and future-ready infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
