import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import SupplyChainHero from "./SupplyChainHero";
import BlockchainSupply from "./BlockchainSupply";
import SupplyChainManagement from "./SupplyChainManagement";
import TraditionalSupply from "./TraditionalSupply";
import SupplyChainSolutions from "./SupplyChainSolutions";
import BlockchaininSupply from "./BlockchaininSupply";

export default function SupplyChain() {
  return (
    <>
      <div>
        <Header />
        <SupplyChainHero />
        <BlockchainSupply />
        {/* <SupplyChainManagement /> */}
        <SupplyChainSolutions />
        <BlockchaininSupply />
        <TraditionalSupply />
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
