import React from "react";

export default function SubstrateBlockchai() {
  return (
    <div>
      <section className="feature-style-two pt_50 pb_50 centred">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Our Substrate Development Services</h2>
            <p className="pt-2">
              Explore our range of Substrate-based services designed to
              accelerate your project’s success:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/cross_chain_bridge.svg" />
                  </div>
                  <h3>Cross-Chain Bridge</h3>
                  <p>
                    Facilitate seamless asset and data exchanges across
                    blockchains using Substrate’s XCMP (Cross-Chain Message
                    Passing) for secure cross-chain interoperability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/sidechain_integration.svg" />
                  </div>
                  <h3>Sidechain Integration</h3>
                  <p>
                    Integrate your Substrate relay chain or dApp with other
                    networks, including Polkadot, to enhance connectivity and
                    interoperability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"310px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/relaychain_development.svg" />
                  </div>
                  <h3>Relaychain Development</h3>
                  <p>
                    Create a secure, Substrate-based blockchain customized for
                    specific use cases, ensuring efficient, scalable
                    development.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/parachain_development.svg" />
                  </div>
                  <h3>Parachain Development</h3>
                  <p>
                    Our team brings expertise in building relay chains and
                    parachains using Rust and Substrate, maximizing each layer’s
                    potential for optimized blockchain solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/pallets_development.svg" />
                  </div>
                  <h3>Pallets Development</h3>
                  <p>
                    Develop custom pallets for diverse functionalities—from new
                    consensus algorithms to NFT creation. Our Rust developers
                    are skilled in creating modular, reusable pallets within
                    Substrate’s framework.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="pb-2">
                    <img style={{width:"80px"}} src="assets/img/framework/SVG/smart_contract_development.svg" />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    Using Parity’s ink! framework, we design and deploy smart
                    contracts on Substrate-based blockchains, enabling business
                    logic execution on a relay chain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
