import React from "react";

export default function BuildingAndManagement() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">Community Building & Management</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h4>Cultivate and Engage a Loyal Community</h4>
                  <p className="py-3">
                    Building a vibrant community is essential for any crypto
                    project. Our community management services ensure active and
                    positive engagement with your users, keeping them informed,
                    engaged, and loyal.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>24/7 Community Support</h4>
                      <p>Round-the-clock community engagement and support.</p>
                    </li>
                    <li>
                      <h4>Community Moderation</h4>
                      <p>
                        Manage and maintain a healthy community environment.
                      </p>
                    </li>
                    <li>
                      <h4>Growth Initiatives</h4>
                      <p>
                        Run community challenges, rewards, and AMAs to keep
                        users engaged.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Start Building Your Community
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img src="assets/img/Community-Building-&-Management.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
