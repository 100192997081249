import React from "react";

export default function EIntegrations() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Crypto Exchange We Work On</h2>
            <p>
              Xeer Technology offers marketing making service across a number of
              cryptocurrency exchanges, ranging from tier-1 to even low
              liquidity markets.
            </p>
          </div>
          <div className="row gap-4 justify-content-center">
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/binance.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_120">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/bitfinnex.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-4 px-3">
                  <img
                    className="rounded mx-auto d-block py-3"
                    src="assets/img/svg/SVG/cicon/bybit.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_120">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/coinbase.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/crypto_com.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_120">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/gate.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/kraken.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 pt_120">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/kucoin.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="box-one-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="assets/img/svg/SVG/cicon/okx.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
