import React from "react";
import Header from "../Coman/Header";
import Web3WalletHero from "./Web3WalletHero";
import Footar from "../Coman/Footar";
import BeAPart from "./BeAPart";
import Unique from "./Unique";
import Remunerative from "./Remunerative";
import OurWorldClass from "./OurWorldClass";
import OfferAnExhilarating from "./OfferAnExhilarating";
import Gathering from "./Gathering";
import OurUniqueOfferings from "./OurUniqueOfferings";
import Established from "./Established";
import GlanceBlogs from "./GlanceBlogs";
import LookingD from "../About-Us/LookingD";
import WhatisWeb3Wallet from "./WhatisWeb3Wallet";
import UnleashNew from "./UnleashNew";
import WhyChoosWeb3 from "./WhyChoosWeb3";

export default function Web3Wallet() {
  return (
    <div>
      <Header />
      <Web3WalletHero />
      <BeAPart />
      <WhatisWeb3Wallet />
      {/* <Unique/> */}
      <Remunerative />
      <UnleashNew />
      <OurWorldClass />

      {/* <OfferAnExhilarating/> */}
      {/* <Gathering/> */}
      {/* <OurUniqueOfferings/> */}
      {/* <Established/> */}

      <WhyChoosWeb3 />
      <GlanceBlogs />
      <LookingD />
      <Footar />
    </div>
  );
}
