import React from "react";

export default function BlockchainDevelopmentServices() {
  return (
    <>
      <section className="rating-section pt_70 pb_150">
        <div className="auto-container">
          <div className="inner-container p-5">
            <div data-animation-box className="sec-title mb_15">
              <h2 className="text-center">
                Xeer's Ethereum Blockchain Development Services
              </h2>
            </div>
            <p className="text-center">
              At Xeer Technology, our expertise in Ethereum enables us to create
              custom blockchain solutions to bring your business vision to life.
            </p>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>01</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Ethereum DApp Development</h5>
                    <p>
                      We build decentralized applications on Ethereum that are
                      scalable, secure, and provide a seamless user experience
                      for various industries, from finance to supply chain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>02</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Ethereum DeFi Development</h5>
                    <p>
                      Our team develops Ethereum-based DeFi solutions such as
                      lending protocols, decentralized exchanges, and yield
                      farming platforms, empowering your business to leverage
                      DeFi's potential.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>03</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Smart Contract Development</h5>
                    <p>
                      Xeer creates secure, efficient smart contracts using
                      Solidity for the Ethereum network, ensuring that your
                      business operations are automated and safe from
                      vulnerabilities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>04</h1>
                      </div>
                    </div>
                    <h5 className="py-3">NFT Marketplace Development</h5>
                    <p>
                      We design and deploy feature-rich NFT marketplaces on
                      Ethereum, providing support for minting, trading, and
                      managing digital assets with ease and reliability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>05</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Ethereum Wallet Development</h5>
                    <p>
                      Developing secure, intuitive wallets for Ethereum, we
                      enable users to store, manage, and transact ETH and ERC-20
                      tokens with ease and security.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>06</h1>
                      </div>
                    </div>
                    <h5 className="py-3">
                      Token Development (ERC-20, ERC-721, ERC-1155)
                    </h5>
                    <p>
                      Our expertise includes creating various Ethereum tokens
                      tailored to your specific business needs, from fungible
                      tokens (ERC-20) to non-fungible tokens (ERC-721) and
                      multi-token standards (ERC-1155).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 rating-block">
                <div className="rating-block-one">
                  <div className>
                    <div className="icon-box">
                      <div className="num-box">
                        <h1>07</h1>
                      </div>
                    </div>
                    <h5 className="py-3">Blockchain Integration Services</h5>
                    <p>
                      We help businesses integrate Ethereum with their existing
                      systems, ensuring smooth blockchain adoption for enhanced
                      transparency and efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
