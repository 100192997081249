import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function PolygonBlockChainHero() {
  return (
    <>
      <section
        className="feature-section p_150 "
        style={{
          backgroundImage: "url(assets/img/polygon/hero-section-image.jpg)",
        }}
      >
        <div className="auto-container pb-4">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25 ">
                  <h2 className="text-white">
                    Build Scalable Solutions with OP Stack
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Xeer Technology empowers businesses to build scalable and
                    secure blockchain applications using the OP Stack, offering
                    seamless integration with the Ethereum ecosystem for
                    enhanced performance and lower costs.
                  </p>
                </div>
              </div>
              <div className="btn-box mt_40">
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Hire Blockchain Expert
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column pt-5"></div>
          </div>
        </div>
      </section>
    </>
  );
}
