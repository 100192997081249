import React from "react";

export default function OurValues() {
  return (
    <>
      <section class="feature-style-two p_50 centred" style={{ backgroundImage: "url(assets/img/Gradient-Shape-BG-02.png)" }}>
        <div class="auto-container">
          <div data-animation-box class="sec-title mb_55">
            <h2>
              We believe in four core principles that <br /> guide everything we
              do!
            </h2>
          </div>
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                class="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="700"
              >
                <div class="inner-box">
                  <div class="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/svg/inclusivity.svg"
                    />
                  </div>
                  <h3>
                    <a href="/">Inclusivity</a>
                  </h3>
                  <p>
                    We ensure that our solutions are accessible, equitable, and
                    designed to foster a decentralized world that benefits
                    everyone.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                class="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="900"
              >
                <div class="inner-box">
                  <div class="">
                    <img
                      style={{ width: "30%" }}
                     src="assets/img/close_collaboration.svg"
                    />
                  </div>
                  <h3>
                    <a href="/">Close Collaboration</a>
                  </h3>
                  <p>
                    We work hand-in-hand with our clients, ensuring their vision
                    is fully realized through our blockchain expertise.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                class="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1300"
              >
                <div class="inner-box">
                  <div class="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/svg/futuristic_approach.svg"
                    />
                  </div>
                  <h3>
                    <a href="/">Futuristic Approach</a>
                  </h3>
                  <p>
                    We stay ahead of industry trends, using forward-thinking
                    strategies to develop innovative and scalable blockchain
                    solutions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                class="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1100"
              >
                <div class="inner-box">
                  <div class="">
                    <img
                      style={{ width: "30%" }}
                      src="assets/img/svg/satisfaction.svg"
                    />
                  </div>
                  <h3>
                    <a href="/">100% Satisfaction</a>
                  </h3>
                  <p>
                    Client satisfaction is our top priority, and we strive to
                    exceed expectations by delivering top-tier results with
                    every project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
