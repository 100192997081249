import React from "react";

export default function Layer0Blockchain() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Binance Smart Chain for Your Project?</h2>
              <p>
                Binance Smart Chain (BSC) has emerged as a powerful blockchain
                platform, known for its low fees, fast block times, and robust
                smart contract capabilities. It enables developers to create
                scalable and efficient decentralized applications (DApps) and
                DeFi solutions without compromising on performance.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
