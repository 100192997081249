import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import ICOdevelopmentHero from "./ICOdevelopmentHero";
import TopRated from "./TopRated";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import LookingD from "../About-Us/LookingD";
import WhyChooseICODev from "./WhyChooseICODev";
import OurICODev from "./OurICODev";
import BenefitsOfICO from "./BenefitsOfICO";
import UnlockYourProject from "./UnlockYourProject";

export default function ICOdevelopment() {
  return (
    <div>
      <Header />
      <ICOdevelopmentHero />
      <TopRated />
      <OurICODev />
      {/* <BenefitsOfICO/> */}
      <UnlockYourProject />
      <WhyChooseICODev />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
