import React from "react";

export default function WhatisSTE() {
  return (
    <>
      <section className="glance-section centred p_150">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a Security Token Exchange?</h2>
              <p>
                A security token exchange is a digital platform that facilitates
                the buying, selling, and trading of security tokens, which
                represent ownership in real-world assets like stocks, bonds,
                real estate, or other financial instruments. Unlike
                cryptocurrencies, security tokens are subject to regulatory
                oversight because they represent regulated financial securities.
                These tokens offer investors legal rights, such as equity
                ownership, dividends, or revenue sharing, depending on the asset
                they are tied to.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
