import React from "react";

export default function BlockchainMarketForecast() {
  return (
    <>
      <section className="feature-section  pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box ">
                <div data-animation-box="true" className="sec-title mb_25">
                  <h2>
                    Blockchain Market Outlook: Future Growth and Opportunities
                  </h2>
                  <p className="mt-3 fs-5">According to Statista:</p>
                </div>
                <ul>
                  <li>
                    <i
                      class="fa-solid fa-circle me-2 "
                      style={{ fontSize: "8px" }}
                    ></i>
                    The blockchain market is projected to reach nearly 1,000
                    trillion USD by 2032.
                  </li>
                  <li>
                    <i
                      class="fa-solid fa-circle me-2 "
                      style={{ fontSize: "8px" }}
                    ></i>
                    The cryptocurrency sector is expected to reach approximately
                    861 million users by 2025, with user penetration growing to
                    11.02%.
                  </li>
                  <li>
                    <i
                      class="fa-solid fa-circle me-2 "
                      style={{ fontSize: "8px" }}
                    ></i>
                    Globally, the U.S. is projected to generate the highest
                    revenue, reaching $9,788 million in 2024.
                  </li>
                  <li>
                    <i
                      class="fa-solid fa-circle me-2 "
                      style={{ fontSize: "8px" }}
                    ></i>
                    The average revenue per user in the crypto market is
                    estimated to reach $66.1 by 2024.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className>
                <img
                  className=""
                  src="assets/img/blockchain-dev/Blockchain-Market-Outlook-Section-Image.png"
                  alt=""
            
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
