import React from "react";

export default function OrderBookDexsHero() {
  return (
    <>
      <section className="feature-section p_150" style={{
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        backgroundImage:
          "url(assets/img/dex/Order-Book-DEX-Development-Hero-Section-Image.jpg)",
      }}>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Order Book DEX Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    At Xeer Technology, we specialize in developing advanced
                    Order Book Decentralized Exchanges (DEXs) that blend the
                    benefits of traditional trading mechanisms with the
                    advantages of blockchain technology.
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <a href="/" className="theme-btn btn-one">
                  Talk To Our Team
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40">
                <div className="image-box">
                  <img
                    className="vert-move"
                    src="assets/images/resource/feature-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
