import React from "react";

export default function GamingPlatform() {
  return (
    <>
      <section className="faq-section testimonial-page-one pt-5 pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title centred mb_55">
            <h2 className="">
              Our Development Service Includes Thorough Approach & Standard
              Development Practices
            </h2>
            <p className="text-white pt-2">
              We employ a meticulous methodology and industry-standard practices
              to ensure the successful delivery of high-quality NFT gaming
              platforms that meet your unique requirements and exceed user
              expectations.
            </p>
          </div>
          <div className="row clearfix px-5">
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated "
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Conceptualization and Design</h3>
                    <p className="text-white pt-3">
                      We work closely with you to conceptualize and design a
                      unique NFT gaming experience. Our team of creative
                      designers and game developers focuses on creating
                      captivating gameplay mechanics and visually stunning
                      graphics that resonate with your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Smart Contract Development</h3>
                    <p className="text-white pt-3">
                      Our developers craft robust smart contracts that ensure
                      secure ownership and trading of NFTs. These contracts are
                      designed to automate various game mechanics, including
                      asset creation, royalties for creators, and transaction
                      processing, providing transparency and security for all
                      users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Blockchain Integration</h3>
                    <p className="text-white pt-3">
                      We offer seamless integration with various blockchain
                      networks, allowing your NFT gaming platform to leverage
                      the advantages of multiple ecosystems. Whether it’s
                      Ethereum, Binance Smart Chain, or others, we ensure that
                      your platform is built on a scalable and reliable
                      infrastructure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix p-5">
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated "
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Play-to-Earn Mechanisms</h3>
                    <p className="text-white pt-3">
                      Our development process includes implementing innovative
                      play-to-earn models that allow players to earn NFTs or
                      cryptocurrency rewards based on their in-game
                      achievements. This incentivizes user engagement and
                      creates a vibrant economy within your game.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Marketplace Development</h3>
                    <p className="text-white pt-3">
                      We integrate a user-friendly marketplace where players can
                      trade, buy, and sell NFTs securely. This feature enhances
                      the community aspect of your game, enabling players to
                      monetize their in-game assets easily.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Cross-Platform Compatibility</h3>
                    <p className="text-white pt-3">
                      Our NFT gaming platforms are developed to be compatible
                      across various devices, including mobile, desktop, and
                      consoles. This ensures a wider reach and better
                      accessibility for players, enhancing their gaming
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated "
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">Security and Compliance</h3>
                    <p className="text-white pt-3">
                      Security is paramount in the development of our NFT gaming
                      platforms. We implement advanced security protocols to
                      protect user data and assets, ensuring compliance with
                      industry standards to foster trust among players.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 glance-block">
              <div
                className="glance-block-one wow fadeInUp animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="box-game" style={{height:"415px"}}>
                  <div className="p-5">
                    <h3 className="text-white">
                      Ongoing Support and Maintenance
                    </h3>
                    <p className="text-white pt-3">
                      Our commitment to your success extends beyond launch. We
                      provide continuous support and maintenance, ensuring your
                      platform remains updated with the latest features,
                      security patches, and market trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}
