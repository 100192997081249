import React from "react";

export default function Diligently() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40">
            
                <div className="image-box">
                  <figure className="image image-1">
                    <img style={{width:"450px"}} src="assets/img/defi/defi-y-f/DeFi-Lottery-Development-By-Xeer-Technology-Section-Image.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>DeFi Lottery Development By Xeer Technology</h2>
                </div>
                <div className="text-box">
                  <p>
                    With Xeer Technology, you get more than just a development
                    partner—you gain a team of experts dedicated to innovation,
                    security, and scalability. Our end-to-end DeFi lottery
                    platform development services guide you from concept through
                    launch and beyond, with continuous support, updates, and
                    maintenance to ensure your platform remains competitive in
                    the dynamic DeFi landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
