import React from "react";

export default function WebMarketing() {
  return (
    <>
      <section className="feature-section p_50"style={{background:"url(assets/img/02.png",padding:"20px"}} >
        <div className="auto-container">
          <h1 className="text-center">Web3 Marketing</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                    <img src="assets/img/Web3-Marketing.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <p className="py-3">
                    <h4>
                      Empower Your Brand in the Decentralized World of Web3
                    </h4>
                    Our Web3 marketing approach leverages the unique aspects of
                    blockchain technology to build long-lasting connections with
                    decentralized communities. We drive engagement and loyalty
                    through various innovative Web3 channels.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Community Incentives</h4>
                      <p>
                        Encourage loyalty with airdrops, token incentives, and
                        NFT rewards.
                      </p>
                    </li>
                    <li>
                      <h4>Influencer Partnerships</h4>
                      <p>
                        Partner with thought leaders and influencers in the Web3
                        space.
                      </p>
                    </li>
                    <li>
                      <h4>Decentralized Platforms</h4>
                      <p>
                        Tap into the power of decentralized platforms for a
                        broader reach.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Explore Web3 Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
