import React from "react";

export default function HowtoGetTez() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Key Benefits of Tezos Blockchain Development with Xeer Technology
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"340px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/asset-tok/svg/institutional_grade_security.svg" />
                  </div>
                  <h3>Institutional-Grade Security</h3>
                  <p>
                    Tezos is built to ensure asset security and reliability at
                    both the protocol and application levels using OCaml and
                    Michelson languages. These advanced languages facilitate
                    formal verification for enhanced protection, ideal for
                    high-value use cases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"340px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/framework/SVG/governance_by_the_community.svg" />
                  </div>
                  <h3>Governance by the Community</h3>
                  <p>
                    Tezos stands out for its governance model, empowering users
                    to drive network upgrades and improvements, making it truly
                    decentralized and community-led. This model aligns Web3 with
                    user-focused principles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"340px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/framework/SVG/easy_branding_settings.svg" />
                  </div>
                  <h3>Energy-Efficient Consensus Mechanism</h3>
                  <p>
                    Tezos employs an energy-efficient proof-of-stake algorithm,
                    minimizing its carbon footprint while offering a robust
                    solution for blockchain applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/smart_contracts.svg" />
                  </div>
                  <h3>Advanced Smart Contracts</h3>
                  <p>
                    Tezos smart contracts prioritize security and reliability,
                    utilizing languages that allow formal verification for
                    critical functions, ideal for high-stakes applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/framework/SVG/seamlessly_upgradeable.svg" />
                  </div>
                  <h3>Seamlessly Upgradeable</h3>
                  <p>
                    Tezos’s unique on-chain governance and upgradeability ensure
                    that the platform remains adaptable, evolving with emerging
                    tech trends without disruptive forks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"300px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/dde/customizable_and_scalable_platform.svg" />
                  </div>
                  <h3>High Scalability</h3>
                  <p>
                    Tezos’s architecture enables it to handle complex
                    transactions and high-volume applications, offering scalable
                    solutions for any industry requirement.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"340px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="" />
                  </div>
                  <h3>Acquiring Tez (XTZ) </h3>
                  <p>
                    Tez (XTZ), the native token of Tezos, powers interactions on
                    the network, from paying fees to securing the network
                    through staking.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
