import React from 'react'
import Header from '../Coman/Header'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../About-Us/LookingD'
import Footar from '../Coman/Footar'
import TradingBotDevCom from './TradingBotDevCom'
import Automated from './Automated'
import MarginTradingBots from './MarginTradingBots'
import TradingBotDevPro from './TradingBotDevPro'
import AutomateYour from './AutomateYour'
import WhyCXeerTforYourCrypto from './WhyCXeerTforYourCrypto'

export default function TradingBot() {
  return (
    <div>
      <Header/>
      <TradingBotDevCom/>
      <Automated/>
      <MarginTradingBots/>
      <TradingBotDevPro/>
      <AutomateYour/>
      <WhyCXeerTforYourCrypto/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
