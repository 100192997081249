import React from "react";

export default function Advantageous() {
  return (
    <>
      <section className="contact-info-section centred  p_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Types of AMM Models We Develop at Xeer Technology</h2>
            <p className="pt-3">
              Our AMM DEX solutions are engineered for optimal performance with
              each customized for specific needs!
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "455px" }}>
                  <img
                    src="assets/img/dex/constant_product_amm.svg"
                    width={80}
                    height={80}
                  />
                  <h3>Constant Product AMM</h3>
                  <p>
                    Designed for simplicity and reliability, this model uses the
                    formula x×y=kx \times y = kx×y=k to provide balanced
                    pricing, suitable for most token pairs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "455px" }}>
                  <img
                    src="assets/img/dex/constant_sum_amm.svg"
                    width={80}
                    height={80}
                  />
                  <h3>Constant Sum AMM</h3>
                  <p>
                    Ideal for stablecoin trading, this model uses a constant sum
                    formula to minimize slippage on stable pairs, creating a
                    more stable trading environment for similar assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "455px" }}>
                  <img
                    src="assets/img/dex/hybrid_amm_models.svg"
                    width={80}
                    height={80}
                  />
                  <h3>Hybrid AMM Models</h3>
                  <p>
                    For platforms seeking to provide diversified trading
                    options, we develop hybrid AMMs that combine different AMM
                    formulas, such as Constant Product and Constant Sum, to
                    support both volatile assets and stablecoins.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "455px" }}>
                  <img
                    src="assets/img/dex/dynamic_amms.svg"
                    width={80}
                    height={80}
                  />
                  <h3>Dynamic AMMs</h3>
                  <p>
                    Advanced models that adjust fees and slippage based on
                    market conditions, providing users with a responsive trading
                    experience that optimizes liquidity management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
