import React, { useState } from "react";

export default function Exceptional() {
  const [toggleTab, setToggleTab] = useState(0);

  console.log("toggleTab", toggleTab);

  return (
    <>
      <section className="service-section p_100">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Exceptional Features Of White Label Crypto Wallet Platform</h2>
            <p className="pt-3">
              Our white label crypto wallet development solutions present an
              array of vital features aimed at boosting security,
              user-friendliness, functionality, and granting users the
              confidence and convenience for managing their digital assets.
            </p>
          </div>
          <div className="tabs-box">
            <div className="tab-btn-box p_relative mb_30 d-flex justify-content-center">
              <ul className="tab-btns tab-buttons ">
                <li
                  className={`tab-btn ${toggleTab === 0 ? "active-btn" : ""}`}
                  data-tab="#tab-1"
                  onClick={() => setToggleTab(0)}
                >
                  Basic Features
                </li>
                <li
                  className={`tab-btn ${toggleTab === 1 ? "active-btn" : ""}`}
                  data-tab="#tab-2"
                  onClick={() => setToggleTab(1)}
                >
                  Advanced Features
                </li>
              </ul>
            </div>
            <div className="tabs-content">
              {toggleTab === 0 && (
                <div
                  className={`tab ${
                    toggleTab === 0 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-1"
                >
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table">
                          <div className="table-header mb_40">
                            <img src="assets/img/add_custom_token.svg" style={{width:"44px"}} />
                            <h3>Add Custom Token</h3>
                            <p>
                              Users can manually add and manage tokens not
                              automatically supported by the wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table">
                          <div className="table-header">
                          <img src="assets/img/address_book.svg" style={{width:"44px"}} />
                            <h3>Address Book</h3>
                            <p>
                              Address book helps users save and manage
                              frequently used cryptocurrency addresses using the
                              platform.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table">
                          <div className="table-header">
                          <img src="assets/img/referral.svg" style={{width:"44px"}} />
                            <h3>Referral</h3>
                            <p>
                              Invite other users & earn rewards out of it using
                              the referral feature incorporated into the wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table">
                          <div className="table-header">
                          <img src="assets/img/wallet_connect.svg" style={{width:"44px"}} />
                            <h3>Wallet Connect</h3>
                            <p>
                              This feature enables secure communication and
                              interaction between a web DApp and a mobile crypto
                              wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"320px"}}>
                          <div className="table-header mb_40">
                          <img src="assets/img/native_fiat_currency_selection.svg" style={{width:"44px"}} />
                            <h3>Native Fiat Currency selection</h3>
                            <p>
                              Users can choose native Fiat currency & value for
                              conversion.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"320px"}}>
                          <div className="table-header">
                          <img src="assets/img/multi_wallet_support.svg" style={{width:"44px"}} />
                            <h3>Multi Wallet support</h3>
                            <p>
                              One can create & manage multiple wallets via the
                              same application, each designated for specific
                              purposes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"320px"}}>
                          <div className="table-header">
                          <img src="assets/img/two_factor.svg" style={{width:"44px"}} />
                            <h3>2-Factor Authentication</h3>
                            <p>
                              An extra layer of security, where users need to
                              enter the one-time password or biometric
                              authentication to access their wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"320px"}}>
                          <div className="table-header">
                          <img src="assets/img/nft_collectables.svg" style={{width:"44px"}} />
                            <h3>NFT Collectables</h3>
                            <p>
                              The wallet offers NFT(Non-Fungible Token) support,
                              representing the ownership of assets or physical
                              items.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header mb_40">
                          <img src="assets/img/crypto_staking.svg" style={{width:"44px"}} />
                            <h3>Crypto Staking</h3>
                            <p>
                              Users can grow their holdings effortlessly by
                              participating in various networks and earning
                              rewards directly from the wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/ledger_wallet_integration.svg" style={{width:"44px"}} />
                            <h3>Ledger wallet integration</h3>
                            <p>
                              Our solutions are integrated with Ledger Hardware
                              wallet integration, enhancing wallet security.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/watch_only_wallet.svg" style={{width:"44px"}} />
                            <h3>Watch-Only wallet</h3>
                            <p>
                              Users can monitor their crypto balance &
                              transactions without private keys via this
                              feature.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/export_import_private_keys.svg" style={{width:"44px"}} />
                            <h3>Export/Import Private Keys</h3>
                            <p>
                              One can safeguard their digital assets by securely
                              exporting and importing private keys for full
                              control and accessibility
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {toggleTab === 1 && (
                <div
                  className={`tab ${
                    toggleTab === 1 ? "active-tab d-block" : "d-none"
                  }`}
                  id="tab-2"
                >
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header mb_40">
                          <img src="assets/img/cross_chain_crypto_swap.svg" style={{width:"44px"}} />
                            <h3>Cross-Chain Crypto Swap</h3>
                            <p>
                              Exchange cryptocurrencies across different
                              blockchain networks without depending on external
                              exchanges.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/multichain_wallets.svg" style={{width:"44px"}} />
                            <h3>Multichain Wallets</h3>
                            <p>
                              Our Wallets support multiple blockchain networks,
                              allowing users to manage cryptos from one place.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/on_chain_crypto_swap.svg" style={{width:"44px"}} />
                            <h3>On-Chain Crypto Swap</h3>
                            <p>
                              An In-built wallet functionality to exchange
                              cryptocurrencies directly on the blockchain.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/fiat_on.svg" style={{width:"44px"}} />
                            <h3>Fiat On/Off Ramp</h3>
                            <p>
                              One can exchange cryptocurrencies to Fiat or Fiat
                              to cryptocurrencies using Fiat on/off ramp
                              feature.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header mb_40">
                          <img src="assets/img/chrome_wallet_extension.svg" style={{width:"44px"}} />
                            <h3>Chrome Wallet Extension</h3>
                            <p>
                              Chrome Wallet Extension is a browser extension
                              that adds wallet functionality to web browsers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/prepaid_cards.svg" style={{width:"44px"}} />
                            <h3>Prepaid Cards</h3>
                            <p>
                              Prepaid debit cards are used for making
                              payments,top up the card with crypto & obtain Fiat
                              equivalent balance.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/real_time_trading_insights.svg" style={{width:"44px"}} />
                            <h3>Real-Time Trading Insights</h3>
                            <p>
                              Users can obtain real-time market data such as
                              price charts and trading analysis to make an
                              informed decision.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/multichain_dapp_browser.svg" style={{width:"44px"}} />
                            <h3>Multichain DApp Browser</h3>
                            <p>
                              The wallet supports decentralized application
                              (DApp) browser for interaction on different
                              blockchains.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header mb_40">
                          <img src="assets/img/p_2_p.svg" style={{width:"44px"}} />
                            <h3>Peer To Peer Trading (P2P)</h3>
                            <p>
                              Users can trade cryptocurrencies directly with
                              each other, often using an Escrow system.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/airdrop.svg" style={{width:"44px"}} />
                            <h3>Group Transfer/Airdrop</h3>
                            <p>
                              Sending cryptos to multiple recipients has become
                              simpler with the Airdrop feature.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/user_chat.svg" style={{width:"44px"}} />
                            <h3>User Chat</h3>
                            <p>
                              Real-time communication, enabling seamless
                              interactions & instant collaboration for enhanced
                              crypto asset management & support.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pricing-block">
                      <div
                        className="pricing-block-two wow fadeInUp animated"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="pricing-table" style={{height:"300px"}}>
                          <div className="table-header">
                          <img src="assets/img/over_the_counter_trading.svg" style={{width:"44px"}} />
                            <h3>Over the Counter Trading</h3>
                            <p>
                              Users can participate in trading and boost their
                              earning capabilities without third-party
                              intervention.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
