import React from "react";

export default function Redefining() {
  return (
    <>
      <section className="contact-info-section centred pt_50 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our DeFi Lottery Platform</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="">
                    <img style={{width:"90px"}} src="assets/img/defi/defi-y-f/SVG/smart_contract_based_random_draws.svg" />
                  </div>
                  <h3>Smart Contract-Based Random Draws</h3>
                  <p>
                    Ensures fairness and randomization, eliminating any
                    potential for manipulation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="">
                    <img style={{width:"90px"}} src="assets/img/defi/defi-y-f/SVG/multiple_lottery_types.svg" />
                  </div>
                  <h3>Multiple Lottery Types</h3>
                  <p>
                    From daily draws and jackpot lotteries to NFT-based
                    lotteries, engage users with diverse game offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="">
                    <img style={{width:"90px"}} src="assets/img/defi/defi-y-f/SVG/staking_&_yield_opportunities.svg" />
                  </div>
                  <h3>Staking & Yield Opportunities</h3>
                  <p>
                    Enable users to stake tokens to participate or earn rewards,
                    creating an incentive to keep assets within the platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="">
                    <img style={{width:"90px"}} src="assets/img/defi/defi-y-f/SVG/referral.svg" />
                  </div>
                  <h3>Referral & Reward Programs</h3>
                  <p>
                    Increase user acquisition and engagement through rewarding
                    referral mechanisms.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"325px"}}>
                  <div className="">
                    <img style={{width:"90px"}} src="assets/img/defi/defi-y-f/SVG/responsive_ui_ux.svg" />
                  </div>
                  <h3>Intuitive & Responsive UI/UX</h3>
                  <p>
                    Our user-friendly interface makes it easy for users to
                    purchase tickets, track winnings, and participate in games
                    across devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
