import React from "react";

export default function DevelopmentSolutions() {
  return (
    <>
      <section className="feature-section p_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className>
                <img
                  src="assets/img/blockchain-dev/Blockchain-Development-Solutions-Image.png"
                  alt=""
                  style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>
                  Blockchain Development Solutions: Building a Scalable Future
                  in the Web3 Economy
                </h2>
                <p className="pt-3">
                  As the demand for sustainable solutions rises, blockchain
                  technology is essential for verifying ethical practices and
                  improving security, efficiency, transparency, and compliance
                  across various industries. Xeer Technology stands out as a
                  premier blockchain development company, having completed over
                  1,000 projects since inception. Our expert team specializes in
                  delivering custom blockchain development solutions for
                  businesses worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
