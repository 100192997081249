import React from 'react'
import Header from '../Coman/Header'
import OrderBookDexsHero from './OrderBookDexsHero'
import Footar from '../Coman/Footar'
import WhatOrderDex from './WhatOrderDex'
import RoleofOrderB from './RoleofOrderB'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../About-Us/LookingD'
import WhyChooseOBD from './WhyChooseOBD'
import WhatOrderBookDEX from './WhatOrderBookDEX'
import XeerDEXDev from './XeerDEXDev'
import OrderBDEXByXeer from './OrderBDEXByXeer'

export default function OrderBookDexs() {
  return (
    <div>
      <Header/>
      <OrderBookDexsHero/>
      <WhatOrderBookDEX/>
      <RoleofOrderB/>
      <OrderBDEXByXeer/>
      <WhatOrderDex/>
      <XeerDEXDev/>
      <WhyChooseOBD/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
