import React from "react";

export default function ExpertstoDiscuss() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light py-5">
              <h2>Why Choose Ethereum for Your Blockchain Project?</h2>
              <p>
                Ethereum is the pioneer of smart contract platforms, offering
                unmatched flexibility and security for developing decentralized
                applications (DApps). With a vast developer community, high
                interoperability, and a strong foundation in decentralized
                finance (DeFi) and NFT ecosystems, Ethereum remains the top
                choice for businesses entering the blockchain space.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
