import React from "react";
import Header from "../Coman/Header";
import DefiLendingAndBorrowingHero from "./DefiLendingAndBorrowingHero";
import Footar from "../Coman/Footar";
import BlogsNFT from "../NFT/BlogsNFT";
import LookingD from "../About-Us/LookingD";
import Strategic from "./Strategic";
import Borrowing from "./Borrowing";
import DefiBCon from "./DefiBCon";
import SelfCustody from "./SelfCustody";
import According from "./According";
import WhyXeerofDefiBorr from "./WhyXeerofDefiBorr";
import LaunchYourDeFi from "./LaunchYourDeFi";

export default function DefiLendingAndBorrowing() {
  return (
    <div>
      <Header />
      <DefiLendingAndBorrowingHero />
      <Borrowing />
      <Strategic />
      <DefiBCon />
      <SelfCustody />
      <LaunchYourDeFi />
      {/* <According /> */}
      <WhyXeerofDefiBorr />
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
