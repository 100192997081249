import React from "react";
import HeroAbout from "./HeroAbout";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import OurCompany from "./OurCompany";
import OurValues from "./OurValues";
import OurVision from "./OurVision";
import LookingD from "./LookingD";


export default function AboutUs() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <HeroAbout />
        <OurCompany/>
        <OurVision/>
        <OurValues/>
        <LookingD/>
        <Footar />
      </div>
    </>
  );
}
