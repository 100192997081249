import React from "react";

export default function SecurityToken() {
  return (
    <>
      <section className="about-style-three pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box mr_80">
                  <figure className="image">
                    <img
                      src="assets/img/why_shiuld_you_Launch_a_security_token1.svg"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="sec-title mb_25">
                    <h2>Why Should You Launch a Security Token Exchange?</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      Launching a security token exchange presents significant
                      benefits for the platform owner, primarily by unlocking
                      new revenue streams and positioning them at the forefront
                      of the evolving digital finance landscape. By providing a
                      compliant platform for trading tokenized securities,
                      owners can tap into the growing demand for tokenization,
                      attracting global investors and issuers. The platform
                      benefits from transaction fees, listing fees for new
                      tokens, and enhanced liquidity by enabling fractional
                      ownership of traditional assets like real estate or
                      stocks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
