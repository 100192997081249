import React from 'react'
import Header from '../../Coman/Header'
import BlogWeb from '../../Web-3-0-Devlopment/BlogWeb'
import Footar from '../../Coman/Footar'
import LookingD from '../../About-Us/LookingD'
import ExchangeListingHero from './ExchangeListingHero'
import ComprehensiveDoc from './ComprehensiveDoc'
import Binance from './Binance'

export default function ExchangeListing() {
  return (
    <div>
      <Header/>
      <ExchangeListingHero/>
      <ComprehensiveDoc/>
      <Binance/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
