import React from "react";

export default function Popularity() {
  return (
    <>
      <section className="glance-section centred p-5">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>The increasing popularity of NFTs</h2>
              <p>
                NFTs (Non-Fungible Tokens) have rapidly gained popularity as
                they revolutionize the way digital assets are created, owned,
                and traded. Unlike traditional cryptocurrencies, each NFT
                represents a unique item, whether it's digital art,
                collectibles, music, virtual real estate, or in-game assets. The
                surge in interest is driven by creators, investors, and
                businesses recognizing the potential of NFTs to authenticate
                ownership and provide scarcity in the digital world. As
                blockchain technology evolves, NFTs are becoming a crucial part
                of the decentralized economy, offering new revenue streams and
                creative opportunities for both individuals and enterprises.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
