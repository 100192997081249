import React from "react";

export default function Navigating() {
  return (
    <>
      <section className="about-style-three p_120">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box mr_80">
                  <figure className="image">
                    <img
                      src="assets/img/wallet/bring-your-crypto-wallet-to-life-today.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="sec-title mb_25">
                    <h2>Bring Your Crypto Wallet to Life Today!</h2>
                  </div>
                  <div className="">
                    <p className="py-3">
                      Leverage the transformative power of blockchain technology
                      by offering your users a secure, fully customizable, and
                      user-friendly crypto wallet solution. Our White Label
                      Crypto Wallet empowers your business to enter the
                      fast-growing world of digital assets with confidence,
                      providing seamless transactions, enhanced security, and a
                      personalized experience.
                    </p>
                    <p>
                      With our ready-made solution, you can quickly launch your
                      own branded wallet, tailored to meet the needs of your
                      customers while saving valuable development time and
                      costs. Whether you're looking to improve customer loyalty,
                      expand your service offerings, or tap into new revenue
                      streams, our White Label Crypto Wallet is designed to help
                      you succeed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
