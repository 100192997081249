import React from "react";

export default function OurRange() {
  return (
    <>
      <section
        className="integrations-section centred p_50"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white pb-3">
                Highlights of our Crypto Payment Services
              </h2>
              <p className="text-white">
                Discover the key features and benefits of our crypto payment
                services, designed to streamline transactions, enhance security,
                and empower businesses to thrive in the digital economy.
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/multi_currency_support.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Multi-Currency Support</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/real_time_transaction_tracking.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Real-Time Conversion</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/seamless_dapp_integration.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Seamless Integration</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img style={{ width: "30%" }} src="assets/img/enhanced_security_protocols.svg" />
                    </div>
                    <h5 className="pt-4 text-white">
                      Enhanced Security Protocols
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/Seamless_ui_ux_01.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">User-Friendly Interface</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/customized_solutions.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Customizable Solutions</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/advanced_charting_&_analytics.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Comprehensive Reporting and Analytics
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/customer_support_chat.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">24/7 Customer Support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
