import React from "react";

export default function DEXExchange() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of Our White Label DEX Exchange:</h2>
          </div>
          <div className="d-flex gap-2 flex-wrap  justify-content-end justify-content-sm-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/advanced_trading_options.svg" />
                    </div>
                    <h5>Advanced Trading Options</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/state_of_the_art_security_mechanisms.svg" />
                    </div>
                    <h5 className="pt-2">State-of-the-art Security Mechanisms</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/impenetrable_smart_contracts.svg" />
                    </div>
                    <h5>Impenetrable Smart Contracts</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/support_multiple_payment_gateways.svg" />
                    </div>
                    <h5>Support Multiple Payment Gateways</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/easy_to_navigate_platform.svg" />
                    </div>
                    <h5>Easy-to-navigate Platform</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 flex-wrap pt-3 justify-content-end justify-content-sm-center">
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/simple_yet_intuitive_user_interface.svg" />
                    </div>
                    <h5>Simple Yet Intuitive User Interface</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/real_time_alerts_and_notifications.svg" />
                    </div>
                    <h5>Real-time Alerts and Notifications </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/multi_crypto_and_fiat_support.svg" />
                    </div>
                    <h5>Multi-Crypto and Fiat Support</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/customizable_and_scalable_platform.svg" />
                    </div>
                    <h5>100% Customizable and Scalable Platform</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className=" feature-block">
                <div
                  className="feature-block-hero"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box" style={{height:"230px"}}>
                    <div className="icon-box">
                      <img src="assets/img/exchange/dde/robust_admin_and_user_dashboard.svg" />
                    </div>
                    <h5>Robust Admin and User Dashboard</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
