import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import NFTGameDev from './NFTGameDev'
import PresentingNFT from './PresentingNFT'
import DIDYOUKNOW from './DIDYOUKNOW'
import RedefinePlayer from './RedefinePlayer'
import FeaturesNFTGame from './FeaturesNFTGame'
import GamingPlatform from './GamingPlatform'
import AppWeUSE from './AppWeUSE'
import RedefineGaming from './RedefineGaming'
import PopularUse from './PopularUse'
import WhyChooseNFTGaming from './WhyChooseNFTGaming'
import LookingD from '../About-Us/LookingD'

export default function NFTGaming() {
  return (
    <div>
      <Header/>
      <NFTGameDev/>
      <PresentingNFT/>
      {/* <DIDYOUKNOW/> */}
      {/* <RedefinePlayer/> */}
      {/* <FeaturesNFTGame/> */}
      <GamingPlatform/>
      <AppWeUSE/>
      {/* <RedefineGaming/> */}
      <PopularUse/>
      <WhyChooseNFTGaming/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
