import React from "react";

export default function Advisory() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Cryptocurrency Token Development Services</h2>
            <p className="pt-3">
              Transform your ideas into reality with our specialized
              cryptocurrency token development services, designed to create
              secure, scalable, and innovative tokens that meet your unique
              business needs and market demands.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/custom-coin-development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Custom Coin Development</h3>
                  <p>
                    Creation of tailored cryptocurrencies tokens or coins
                    designed to meet specific business needs and use cases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/token-ceation.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Token Creation</h3>
                  <p>
                    Development of various types of tokens, including utility
                    tokens, security tokens, and governance tokens.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/smart-contract-development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    Designing and deploying secure smart contracts that automate
                    transactions and enhance functionality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/tokenomics-strategy.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Tokenomics Strategy</h3>
                  <p>
                    Consultation on developing a robust tokenomics model,
                    including supply, distribution, and incentive mechanisms.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/tokenomics-strategy.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Blockchain Integration</h3>
                  <p>
                    Seamless integration of your coin with existing blockchain
                    networks, ensuring optimal performance and scalability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/decentralized-exchange-development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Decentralized Exchange (DEX) Development</h3>
                  <p>
                    Building decentralized exchanges for trading your coin,
                    enhancing liquidity and user accessibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/wallet-development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Wallet Development</h3>
                  <p>
                    Creating secure wallets for storing, managing, and
                    transacting your coins with user-friendly interfaces.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "335px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/coin-dev/security-audits-and-testing.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Security Audits and Testing</h3>
                  <p>
                    Conducting comprehensive security assessments and audits to
                    identify and resolve vulnerabilities before launch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
