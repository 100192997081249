import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import TronWalletHero from './TronWalletHero'
import Effective from './Effective'
import Exclusive from './Exclusive'
import OurTalented from './OurTalented'
import Harness from './Harness'
import HowDoDevTRONw from './HowDoDevTRONw'
import Unparalleled from './Unparalleled'
import HireProfessional from './HireProfessional'
import FuturisticTRON from './FuturisticTRON'
import TronWalletBlog from './TronWalletBlog'
import LookingD from '../About-Us/LookingD'

export default function TronWallet() {
  return (
    <div>
        <Header/>
        <TronWalletHero/>
        <Effective/>
        <Exclusive/>
        <OurTalented/>
        <Harness/>
        {/* <HowDoDevTRONw/> */}
        {/* <Unparalleled/> */}
        {/* <HireProfessional/> */}
        <FuturisticTRON/>
        <TronWalletBlog/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
