import React from "react";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import LookingD from "../About-Us/LookingD";
import BlogsNFT from "../NFT/BlogsNFT";
import MlmHero from "./MlmHero";
import FrequentlyMlm from "./FrequentlyMlm";
import Essential from "./Essential";
import Engaging from "./Engaging";
import MarketingAnalytics from "./MarketingAnalytics";
import MlmCompiler from "./MlmCompiler";
import OurMLMScripts from "./OurMLMScripts";
import MLMDevProcess from "./MLMDevProcess";
import ReadytoMLMPlatform from "./ReadytoMLMPlatform";
import WhyCXeerforMLMPlan from "./WhyCXeerforMLMPlan";

export default function MLM() {
  return (
    <div>
      <Header />
      <MlmHero />
      <Essential />
      <Engaging />
      {/* <MarketingAnalytics/> */}
      <FrequentlyMlm />
      <MlmCompiler />
      <OurMLMScripts />
      <MLMDevProcess />
      <ReadytoMLMPlatform />
      <WhyCXeerforMLMPlan />
      <BlogsNFT />
      <LookingD />
      <Footar />
    </div>
  );
}
