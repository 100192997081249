import React from "react";

export default function WalletApps() {
  return (
    <>
      <section
        className="integrations-section centred pb_150"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="">
          <div className="sec-title mb_40">
            <div className="inner_wrap">
              <h2 className="text-white pt-5">
                Get Your Crypto Wallet Mobile App Developed by Us
              </h2>
              <p className="text-white pt-3">
                Offer your users a powerful, secure, and feature-rich Crypto
                Wallet Mobile App that meets all demands and needs!
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/wallet/mwa/secure_development_practices.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">
                      Secure Development Practices
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/wallet/mwa/fast_deployment.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Fast Deployment</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/wallet/mwa/customized_solutions.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Customized Solutions</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="feature-block">
                <div
                  className="feature-block py-5"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box">
                    <div className="icon-box">
                      <img
                        style={{ width: "30%" }}
                        src="assets/img/wallet/mwa/post_launch_support.svg"
                      />
                    </div>
                    <h5 className="pt-4 text-white">Post-Launch Support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
