import React from 'react'
import Header from '../../Coman/Header'
import BlogWeb from '../../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import SmartContractDevHero from './SmartContractDevHero'
import SCisTheFuture from './SCisTheFuture'
import SConArchitecture from './SConArchitecture'
import DeFiSConDev from './DeFiSConDev'
import Disintermediation from './Disintermediation'

export default function SmartContractDev() {
  return (
    <div>
      <Header/>
      <SmartContractDevHero/>
      {/* <SCisTheFuture/> */}
      {/* <SConArchitecture/> */}
      <Disintermediation/>
      <DeFiSConDev/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
