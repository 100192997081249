import React from "react";
import Header from "../../Coman/Header";
import BlogsNFT from "../../NFT/BlogsNFT";
import LookingD from "../../About-Us/LookingD";
import Footar from "../../Coman/Footar";
import EthereumSolutionHero from "./EthereumSolutionHero";
import EthereumTowards from "./EthereumTowards";
import BlockchainDevelopmentServices from "./BlockchainDevelopmentServices";
import ExpertstoDiscuss from "./ExpertstoDiscuss";
import Industriesthat from "./Industriesthat";
import EthereumDevelopmentServices from "./EthereumDevelopmentServices";

export default function EthereumSolution() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <EthereumSolutionHero />
        <ExpertstoDiscuss/>
        {/* <EthereumTowards /> */}
        <BlockchainDevelopmentServices/>
        <Industriesthat/>
        <EthereumDevelopmentServices/>
        <BlogsNFT />
        <LookingD />
        <Footar />
      </div>
    </>
  );
}
