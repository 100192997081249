import React from "react";
import TalkToOurTeamBTN from "../../Coman/TalkToOurTeamBTN";

export default function SupplyChainHero() {
  return (
    <>
      <section
        className="banner-style-six"
        style={{
          backgroundImage: "url(assets/img/layer/ton-hero-dection-image.jpg)",
        }}
      >
        <div className="pattern-layer">
          <div
            className="pattern-2"
            style={{
              backgroundImage: 'url("assets/images/shape/shape-50.png")',
            }}
          />
        </div>
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30">
                <h1 className="text-white">
                  Revolutionize Your Blockchain Projects with TON (The Open
                  Network)
                </h1>
                <p className="text-white">
                  Unlock the full potential of The Open Network with Xeer
                  Technology’s specialized TON blockchain development services,
                  built for speed, scalability, and a seamless user experience.
                </p>
                <div className="btn-box mt_40">
                  <TalkToOurTeamBTN />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 " />
          </div>
        </div>
      </section>
    </>
  );
}
