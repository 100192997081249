import React from "react";
import Slider from "react-slick";

export default function TechnologiesweWork() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="integrations-section centred pb_150 pt-5">
        <div className="">
          <div className="sec-title mb_60">
            <span className="sub-title-two">
              STANDING ON THE EDGE OF INNOVATION
            </span>
            <h2 className="pb-2">Blockchain Platforms We Work On</h2>
            <p>
              From layer 0 to layer 3, we take pride in our expertise across all
              blockchain layers. However, we particularly excel in a
              cryptocurrency trading software development on the <br /> following 
              blockchains:
            </p>
          </div>
          <div className="slider-container">
            <Slider {...settings}>
              <div className="slider-bgimg-1">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/bnb.svg"
                  />
                  <h3>Binance</h3>
                </div>
              </div>
              <div className="slider-bgimg-2">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/cardano.svg"
                  />
                  <h3>CARDANO</h3>
                </div>
              </div>
              <div className="slider-bgimg-3">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/eth.svg"
                  />
                  <h3>ETHEREUM</h3>
                </div>
              </div>
              <div className="slider-bgimg-4">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/hedera.svg"
                  />
                  <h3>HEDERA</h3>
                </div>
              </div>
              <div className="slider-bgimg-5">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/near.svg"
                  />
                  <h3>NEAR</h3>
                </div>
              </div>
              <div className="slider-bgimg-6">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/polkadot.svg"
                  />
                  <h3>POLKADOT</h3>
                </div>
              </div>
              <div className="slider-bgimg-7">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/polygon.svg"
                  />
                  <h3>POLYGON </h3>
                </div>
              </div>
              <div className="slider-bgimg-8">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/sol.svg"
                  />
                  <h3>SOLANA </h3>
                </div>
              </div>
              <div className="slider-bgimg-9">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/stellar.svg"
                  />
                  <h3>STELLAR</h3>
                </div>
              </div>
              <div className="slider-bgimg-10">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/ton1.svg"
                  />
                  <h3>TON </h3>
                </div>
              </div>
              <div className="slider-bgimg-11">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/tron.svg"
                  />
                  <h3>TRON</h3>
                </div>
              </div>
              <div className="slider-bgimg-12">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "40%" }}
                    src="assets/img/icon/icon2/waves.svg"
                  />
                  <h3>WAVES </h3>
                </div>
              </div>
              {/* <div className="slider-bgimg-12">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://aetsoft.net/wp-content/themes/spacetheme/new-design/img/technologies/corda-icon.svg"
                  />
                  <h3>Corda</h3>
                </div>
              </div> */}
              {/* <div className="slider-bgimg-13">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://aetsoft.net/wp-content/themes/spacetheme/new-design/img/technologies/corda-icon.svg"
                  />
                  <h3>Corda</h3>
                </div>
              </div> */}
              {/* <div className="slider-bgimg-14">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://aetsoft.net/wp-content/themes/spacetheme/new-design/img/technologies/corda-icon.svg"
                  />
                  <h3>Corda</h3>
                </div>
              </div> */}
              {/* <div className="slider-bgimg-15">
                <div className="pt-5">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://aetsoft.net/wp-content/themes/spacetheme/new-design/img/technologies/corda-icon.svg"
                  />
                  <h3>Corda</h3>
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}
