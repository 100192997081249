import React from "react";

export default function PolygonBlockchaiService() {
  return (
    <div>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer for OP Stack Development?</h2>
              <p>
                At Xeer Technology, we specialize in leveraging OP Stack’s
                robust tools to create efficient, Ethereum-compatible solutions.
                Our team brings extensive experience in modular development and
                Ethereum scaling to help your project excel in performance,
                security, and scalability.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
