import React from "react";

export default function Fundraising() {
  return (
    <>
      <section className="centred pt_120 pb_150">
        <div className="auto-container">
          <div className="content-box">
            <div data-animation-box className="sec-title mb_55">
              <h2>Our IDO Development Process</h2>
              <p className="pt-3">
                We will build your project from concept to launch with a
                structured, secure, and scalable IDO process designed for seamless
                fundraising and maximum market impact.
              </p>
            </div>
          </div>
          <div className="py-5">
            <img src="https://www.antiersolutions.com/wp-content/uploads/2022/11/Group-66010.png" />
          </div>
        </div>
      </section>
    </>
  );
}
