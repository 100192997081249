import React, { useState } from "react";

export default function HowP2PWork() {
  const [toggle, settoggle] = useState(0);

  return (
    <>
      <section className="faq-style-three pt_100 pb_140">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55 centred">
            <h3 className="" style={{ fontSize: "40px" }}>
              P2P Exchange Order & Trade Lifecycle
            </h3>
            <p>
              A P2P (peer-to-peer) crypto exchange operates by allowing users to
              trade cryptocurrencies directly with one another without the need
              for an intermediary or centralized authority. Here’s how it works:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_30">
                <figure className="image">
                  <img
                    src="assets/img/p2p_exchange_orde_trade_lifecycle.svg"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="inner-box mr_30">
                <ul className="accordion-box">
                  <li className="accordion block active-block">
                    <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(0)} />
                      <h4>User Registration</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 0 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Traders create an account on the P2P platform and
                          undergo verification through Know Your Customer (KYC)
                          processes, if required.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(1)} />
                      <h4>Posting Offers</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 1 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          Users who wish to buy or sell crypto can post offers
                          specifying the price, amount, and preferred payment
                          method (e.g., bank transfer, PayPal, or even cash).
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(2)} />
                      <h4>Matching Buyers and Sellers</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 2 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          When a buyer finds a suitable offer, they initiate a
                          trade. The platform then locks the cryptocurrency from
                          the seller in an escrow system to ensure security
                          until the transaction is complete.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(3)} />
                      <h4>Payment Processing</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 3 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          The buyer pays the seller using the agreed-upon
                          payment method outside the platform. Once the payment
                          is confirmed by the seller, the cryptocurrency held in
                          escrow is released to the buyer.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(4)} />
                      <h4>Escrow Protection</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 4 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          If there is any dispute or delay in the transaction,
                          the platform’s escrow service steps in to mediate and
                          ensure a fair resolution.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="accordion block">
                    <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                      <div className="icon-box" onClick={() => settoggle(5)} />
                      <h4>Ratings and Reviews</h4>
                    </div>
                    <div
                      className={`acc-content ${toggle === 5 ? "current" : ""}`}
                    >
                      <div className="text">
                        <p>
                          After the transaction is complete, both parties can
                          leave feedback on the platform, building trust within
                          the user community.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
