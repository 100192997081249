import React from "react";

export default function BlockchainDevelopmentServices() {
  return (
    <>
      <section className="working-section home-4 centred pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_60">
            <h2>Our Blockchain Development Services</h2>
            <p className="mt-3">
              Xeer Technology offers a full range of blockchain development
              services to help businesses leverage the power of blockchain in
              the evolving web3 space.
            </p>
          </div>
          <div className="inner-container">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={700}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/blockchain_consultation_&_integration.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Blockchain Consultation & Integration</h3>
                      <p className="mx-0">
                        From strategy to implementation, our blockchain experts
                        provide comprehensive guidance throughout your
                        blockchain application journey, ensuring successful and
                        seamless integration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={900}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/layer_2_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Layer 2 Development</h3>
                      <p className="mx-0">
                        We design innovative Layer 2 solutions that enhance the
                        scalability of base-layer blockchains. Our team
                        specializes in developing Layer 2 protocols, including
                        rollups and state channels.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/blockchain_protocol_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Blockchain Protocol Development</h3>
                      <p className="mx-0">
                        Our capabilities span the complete protocol development
                        lifecycle, from consensus mechanisms to governance
                        structures. We deliver scalable, customized blockchain
                        protocols suited to your unique requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/centralized.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Centralized/Decentralized Exchange Development</h3>
                      <p className="mx-0">
                        Xeer creates high-performance, secure trading platforms
                        tailored for seamless cryptocurrency transactions,
                        catering to both centralized and decentralized
                        exchanges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/nft_marketplace_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>NFT Marketplace Development</h3>
                      <p className="mx-0">
                        Our customizable NFT marketplaces feature multi-chain
                        support, smart contract integration, and secure payment
                        gateways, providing safe and efficient token trading
                        experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/smart_contract_development_audit.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Smart Contract Development & Audit</h3>
                      <p className="mx-0">
                        We design, develop, and audit smart contracts across
                        major blockchain platforms, rigorously testing to ensure
                        compliance and reliability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/crypto_wallet_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Crypto Wallet Development</h3>
                      <p className="mx-0">
                        Xeer’s crypto wallet development services prioritize
                        security with advanced encryption and multi-signature
                        authentication for safe digital asset management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/decentralized_app_(dapp)_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Decentralized App (dApp) Development</h3>
                      <p className="mx-0">
                        Our dApp development services cover the full lifecycle,
                        ensuring high-quality, value-driven solutions that
                        enhance digital presence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 working-block">
                <div
                  className="working-block-one"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration={1100}
                >
                  <div className="inner-box text-start">
                    <div>
                      <img style={{width:"80px"}} src="assets/img/blockchain-dev/SVG/metaverse_development.svg"/>
                    </div>
                    <div className="lower-content">
                      <h3>Metaverse Development</h3>
                      <p className="mx-0">
                        We craft immersive metaverse solutions, from avatars to
                        AR/VR applications, aligning with clients' business
                        objectives to bridge digital and physical experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <a href="/contact-us" className="theme-btn btn-one m_20">
              Get a quote
            </a>
          </div>
          </div>
        </div>
      </section>
    </>
  );
}
