import React from 'react'
import Header from '../../Coman/Header'
import LookingD from '../../About-Us/LookingD'
import Footar from '../../Coman/Footar'
import CryptoMarketingHero from './CryptoMarketingHero'
import MarketingGlance from './MarketingGlance'
import ATaGLANCE from './ATaGLANCE'
import TokenMarketing from './TokenMarketing'
import PRandMedia from './PRandMedia'
import MarketingStrategies from './MarketingStrategies'
import WebMarketing from './WebMarketing'
import WebCampaigns from './WebCampaigns'
import SocialMediaMarketing from './SocialMediaMarketing'
import InfluenceMarketing from './InfluenceMarketing'
import ContentMarketing from './ContentMarketing'
import BuildingAndManagement from './BuildingAndManagement'
import AirdropsMarketing from './AirdropsMarketing'
import RebrandingM from './RebrandingM'
import PitchDeckDesign from './PitchDeckDesign'

export default function CryptoMarketing() {
  return (
    <div>
      <Header/>
      <CryptoMarketingHero/>
      {/* <ATaGLANCE/> */}
      <TokenMarketing/>
      {/* <MarketingGlance/> */}
      <PRandMedia/>
      <MarketingStrategies/>
      <WebMarketing/>
      <WebCampaigns/>
      <SocialMediaMarketing/>
      <InfluenceMarketing/>
      <ContentMarketing/>
      <BuildingAndManagement/>
      <AirdropsMarketing/>
      <RebrandingM/>
      <PitchDeckDesign/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
