import React from "react";

export default function CloneApp() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Features of a DEX Aggregator</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/dex/SVG/best_pricing_with_smart_routing.svg" />
                  </div>
                  <h3>Best Pricing with Smart Routing</h3>
                  <p>
                    DEX aggregators use algorithms to find the most efficient
                    route for trades. By splitting orders across multiple DEXs,
                    they secure the best possible rates, minimizing price
                    impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/dex/SVG/reduced_slippage.svg" />
                  </div>
                  <h3>Reduced Slippage</h3>
                  <p>
                    Aggregators help minimize slippage (the difference between
                    expected and actual prices) by pulling liquidity from
                    different sources to fulfill large orders more effectively.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/dex/SVG/gas_efficiency.svg" />
                  </div>
                  <h3>Gas Efficiency</h3>
                  <p>
                    Some aggregators optimize routes not only for price but also
                    to minimize gas fees, ensuring more cost-effective
                    transactions for users.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/dex/SVG/cross_chain_capabilities.svg" />
                  </div>
                  <h3>Cross-Chain Capabilities</h3>
                  <p>
                    Advanced DEX aggregators can operate across multiple
                    blockchain networks, allowing users to swap assets across
                    ecosystems, such as Ethereum, BNB Chain, and others.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div className="pb-2">
                    <img style={{width:"70px"}} src="assets/img/dex/SVG/enhanced_liquidity.svg" />
                  </div>
                  <h3>Enhanced Liquidity</h3>
                  <p>
                    By aggregating liquidity from numerous DEXs, these platforms
                    provide deeper liquidity pools, making it easier to complete
                    larger trades without significant price impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
