import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CryptocurrencyWalletHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/Wallet/Cryptocurrency-Wallet-Development-Hero-Section-Image-File.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center py-4">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Cryptocurrency Wallet Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Building advanced and secure cryptocurrency wallets for
                    effortless digital asset management while offering seamless
                    integration, multi-currency support, and robust security
                    features.
                  </p>
                </div>
              </div>
              <div className="btn-box pt-3">
            <TalkToOurTeamBTN/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box">
                  <figure className="image image-1">
                    <img
                      className="vert-move"
                      src="crypto_currency_wallet_development_hero_img.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
