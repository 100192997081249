import React from "react";

export default function DevGlobalS() {
  return (
    <>
      <section className="feature-section pt_60 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>DeFi Industry Growth in 2024</h2>
                  <p className="py-3">
                    As of 2024, the global DeFi (Decentralized Finance) market
                    is projected to reach an estimated market size of $230
                    billion to $250 billion in total value locked (TVL). The
                    market has been growing at a rapid pace with a compound
                    annual growth rate (CAGR) of approximately 40% over recent
                    years.
                  </p>
                  <p>
                    This significant growth is fueled by the rising adoption of
                    decentralized exchanges (DEXs), lending platforms, and yield
                    farming, along with increased interest from institutional
                    investors. With ongoing innovations and broader acceptance,
                    the DeFi sector is set to play a key role in reshaping the
                    future of finance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ps-5">
              <div className="">
                <img style={{width:"70%"}}
                  src="assets/img/defi/defi_industry_growth_2024.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
