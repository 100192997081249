import React from "react";

export default function GetAQuote() {
  return (
    <>
      <section className="clients-section alternat-2 p_30 centred" style={{backgroundColor:"#071522"}}>
        <div className="auto-container">
          <div className="inner-container">
            <h3 className="text-white">
              At Xeer Technology, we specialize in creating robust, secure, and
              feature-rich Bitcoin wallets that provide seamless access to the
              world’s leading cryptocurrency. Whether you are an individual
              looking to store your assets safely or a business aiming to
              integrate Bitcoin payments, we offer tailored wallet development
              services to suit your needs.
            </h3>
          </div>
          <a href="/contact-us" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
