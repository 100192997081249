import React from "react";

export default function InsightsOn() {
  return (
    <>
      <section className="about-style-three p_150">
        
        <div className="auto-container">
          <div className="sec-title mb_50 text-center">
            <h2>IDO Development Service by Xeer Technology</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <img src="assets/img/coin-dev/unlocking-seamless-fundraising-with-customized-ido-development.png"/>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <h2>
                    Unlocking Seamless Fundraising with Customized IDO
                    Development
                  </h2>
                  <div className="text-box my_40">
                    <p>
                      Xeer Technology specializes in full-scale IDO (Initial DEX
                      Offering) development services, providing blockchain
                      projects with the tools and support they need to
                      successfully launch tokens and attract a global investor
                      base. From smart contract development to security audits
                      and marketing strategies, we deliver a streamlined,
                      end-to-end IDO development experience tailored to your
                      project’s goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
