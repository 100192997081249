import React, { useState } from "react";

export default function OurServices() {
  const [toggleTab, setToggleTab] = useState(0);

  console.log("toggleTab", toggleTab);

  return (
    <>
      <div className="">
        <div className="service-style-three pb_150 pt-5">
          <div className="auto-container">
            <div data-animation-box className="sec-title mb_55 centred">
              <div className="inner_wrap">
                <h2>Let’s build the app on your idea!</h2>
              </div>
              <p>
                Hire us to get your blockchain application developed in weeks.
              </p>
            </div>
            <div className="tabs-box">
              <div className="row">
                <div className="col-lg-2 btn-bg-img">
                  <div className="tab-btn-box p_relative d-flex justify-content-center">
                    <ul className="hero-btn tab-buttons clearfix">
                      <li
                        className={`tab-btn ${
                          toggleTab === 0 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-1"
                        onClick={() => setToggleTab(0)}
                      >
                        Blockchain
                      </li>
                      <li
                        className={`tab-btn ${
                          toggleTab === 1 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-2"
                        onClick={() => setToggleTab(1)}
                      >
                        Listing
                      </li>
                      <li
                        className={`tab-btn ${
                          toggleTab === 2 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-3"
                        onClick={() => setToggleTab(2)}
                      >
                        NFT
                      </li>
                      <li
                        className={`tab-btn ${
                          toggleTab === 3 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-4"
                        onClick={() => setToggleTab(3)}
                      >
                        Content
                      </li>
                      <li
                        className={`tab-btn ${
                          toggleTab === 4 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-5"
                        onClick={() => setToggleTab(4)}
                      >
                        Design
                      </li>
                      <li
                        className={`tab-btn ${
                          toggleTab === 5 ? "active-btn" : ""
                        }`}
                        data-tab="#tab-6"
                        onClick={() => setToggleTab(5)}
                      >
                        Social Media
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  {/* <dv className="bg-gradient-1"> */}

                  <div className="tabs-content ">
                    {toggleTab === 0 && (
                      <div
                        className={`tab ${
                          toggleTab === 0 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-1"
                      >
                        <div className="pb-3 text-center">
                          <h1>Blockchain</h1>
                          <p>
                            Blockchain applications like smart contracts,
                            cryptocurrency token, DeFi application or even a
                            whole new blockchain network.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/smart_contract.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Smart Contract</h4>
                                    <p>
                                      Launch your own smart contracts on
                                      blockchain.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Blockchain Network.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Blockchain Network</h4>
                                    <p className="">
                                      Custom blockchain network like Ethereum &
                                      Solana.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Crypto Token.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Crypto Token</h4>
                                    <p>
                                      Crypto token on existing blockchain for
                                      payments
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/DeFi App.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>DeFi App</h4>
                                    <p>
                                      Custom Decentralized finance (DeFi)
                                      application.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleTab === 1 && (
                      <div
                        className={`tab ${
                          toggleTab === 1 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-2"
                      >
                        <div className="pb-3 text-center">
                          <h1>Listing</h1>
                          <p>
                            Need listing service? We have got you covered in
                            expanding your brand to masses.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Exchange Listing.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Exchange Listing </h4>
                                    <p>
                                      List your cryptocurrency on leading
                                      exchanges.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Token Listing.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Token Listing</h4>
                                    <p>
                                      Token listing on tracking platforms like
                                      CoinGecko.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Platform Listing.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Platform Listing </h4>
                                    <p>
                                      List your crypto platform on web
                                      directories.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Website Listing.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Website Listing</h4>
                                    <p>
                                      List your website on standard platforms
                                      for backlinks.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleTab === 2 && (
                      <div
                        className={`tab ${
                          toggleTab === 2 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-3"
                      >
                        <div className="pb-3 text-center">
                          <h1>NFT</h1>
                          <p>
                            Explore our standard services ranging from simple
                            NFT launch to building a marketplace for NFT
                            trading.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/NFT Collection.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>NFT Collection</h4>
                                    <p>
                                      Launch your own NFT collection with our
                                      resources.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/NFT Marketplace.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>NFT Marketplace</h4>
                                    <p>
                                      Developing your customized NFT
                                      marketplace.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/NFT Launch.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>NFT Launch</h4>
                                    <p>
                                      Designing & launching single or multiple
                                      NFTs.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Buying or Selling NFTs.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Buying or Selling NFTs</h4>
                                    <p>
                                      Consult us before buying or selling NFTs.{" "}
                                      <br />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleTab === 3 && (
                      <div
                        className={`tab ${
                          toggleTab === 3 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-4"
                      >
                        <div className="pb-3 text-center">
                          <h1>Content</h1>
                          <p>
                            We provide content for all-kind of blockchain
                            projects including whitepapers, technical papers,
                            developer docs, project plan PDF & more.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Whitepaper.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Whitepaper </h4>
                                    <p>
                                      Describing theoretical concepts of your
                                      project in detail.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Technical Documents.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Technical Documents</h4>
                                    <p>
                                      For describing guides & technical details
                                      of your projects.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Website Content.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Website Content</h4>
                                    <p>
                                      Website copies & content for your
                                      blockchain project website.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/PDFs & Pitch-decks.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>PDFs & Pitch-decks</h4>
                                    <p>
                                      PDF content to present your project in
                                      memorable format.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleTab === 4 && (
                      <div
                        className={`tab ${
                          toggleTab === 4 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-5"
                      >
                        <div className="pb-3 text-center">
                          <h1>Design</h1>
                          <p>
                            Designing impressive & presentable brand assets for
                            blockchain projects including logos, website themes,
                            app modules, & more.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/logo(1).svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Logo</h4>
                                    <p>
                                      Eye-catching & remarkable logo for your
                                      blockchain project.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/website.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Website</h4>
                                    <p>
                                      Website design that describes everything
                                      about your project
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/pdfs.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>PDFs</h4>
                                    <p>
                                      Designing PDF files to present your
                                      project plan to the public.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {toggleTab === 5 && (
                      <div
                        className={`tab ${
                          toggleTab === 5 ? "active-tab d-block" : "d-none"
                        }`}
                        id="tab-6"
                      >
                        <div className="pb-3 text-center">
                          <h1>Social Media</h1>
                          <p>
                            Providing all kind of social media services
                            including posting content, account management,
                            influencer marketing & paid campaigns.
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Account Management.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Account Management</h4>
                                    <p>
                                      Handling your social media account with
                                      everyday activities.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Designing & Posting.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Designing & Posting </h4>
                                    <p>
                                      Creating posts and posting it across all
                                      your social accounts.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Influencer Marketing.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Influencer Marketing</h4>
                                    <p>
                                      Boosting social media presence using
                                      influencer outreach.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="box-white-wrap4"
                              data-wow-delay="0s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0s",
                                animationName: "fadeInUpX",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-3">
                                  <img
                                    src="assets/img/Followers & Members.svg"
                                    style={{ width: "80%" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col">
                                  <div className="fugu--iconbox-data">
                                    <h4>Followers & Members</h4>
                                    <p>
                                      Increasing followers & community counts
                                      with paid campaigns.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* </dv> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
