import React from 'react'
import Header from '../Coman/Header'
import WhiteLabelWalletHero from './WhiteLabelWalletHero'
import Footar from '../Coman/Footar'
import Navigating from './Navigating'
import Glimpse from './Glimpse'
import Branded from './Branded'
import Bold from './Bold'
import Powerful from './Powerful'
import GrowYourB from './GrowYourB'
import Comprehensive from './Comprehensive'
import Exceptional from './Exceptional'
import MerchantPayments from './MerchantPayments'
import EverRunning from './EverRunning'
import CWDProcess from './CWDProcess'
import SwiftDevProcess from './SwiftDevProcess'
import LookingD from '../About-Us/LookingD'
import Dedicated from './Dedicated'

export default function WhiteLabelWallet() {
  return (
    <div>
        <Header/>
        <WhiteLabelWalletHero/>
        <Navigating/>
        {/* <Branded/> */}
        {/* <Glimpse/> */}
        <Powerful/>
        <GrowYourB/>
        <Bold/>
        {/* <Comprehensive/> */}
        <Exceptional/>
        {/* <MerchantPayments/> */}
        {/* <EverRunning/> */}
        {/* <CWDProcess/> */}
        {/* <SwiftDevProcess/> */}
        <Dedicated/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
