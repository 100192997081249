import React from 'react'

export default function WhyPtowP() {
    return (
        <>
            <section className="glance-section centred p_100">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="pattern-layer">
                            <div
                                className="pattern-1"
                                style={{
                                    backgroundImage: "url(assets/images/shape/shape-5.png)",
                                }}
                            />
                            <div
                                className="pattern-2"
                                style={{
                                    backgroundImage: "url(assets/images/shape/shape-6.png)",
                                }}
                            />
                            <div
                                className="pattern-3 rotate-me"
                                style={{
                                    backgroundImage: "url(assets/images/shape/shape-7.png)",
                                }}
                            />
                            <div
                                className="pattern-4 rotate-me"
                                style={{
                                    backgroundImage: "url(assets/images/shape/shape-8.png)",
                                }}
                            />
                            <div
                                className="pattern-5 rotate-me"
                                style={{
                                    backgroundImage: "url(assets/images/shape/shape-9.png)",
                                }}
                            />
                        </div>
                        <div className="sec-title light mb_80">
                            <h2>What is a P2P Crypto Exchange?</h2>
                            <p>
                                A P2P (peer-to-peer) crypto exchange is a decentralized platform
                                that allows users to trade cryptocurrencies without the
                                involvement of any intermediaries or third parties. Instead of
                                relying on a centralized exchange to facilitate transactions,
                                buyers and sellers interact directly in secondary market selling.
                                The platform typically acts as an escrow service to ensure the
                                safe exchange of assets. Users can post buy or sell offers, set
                                their own prices, and negotiate terms.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
