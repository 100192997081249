import React from "react";

export default function Logos() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Get Script & Design of Popular Crypto Wallets</h2>
            <p>
              Launch your wallet with confidence by leveraging the proven script
              and design of the top crypto wallets in the industry.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/trust_wallet.svg"
                  />
                  <h5 className="py-3">Trust Wallet</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/meya.svg"
                  />
                  <h5 className="py-3">MetaMask</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/phantom.svg"
                  />
                  <h5 className="py-3">Phantom</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/tronlink.svg"
                  />
                  <h5 className="py-3">TronLink</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/coinbase_wallet.svg"
                  />
                  <h5 className="py-2">Coinbase Wallet</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block" style={{width:"60%"}}
                    src="assets/img/svg/exodus_wallet.svg"
                  />
                  <h5 className="py-2">Exodus Wallet</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
