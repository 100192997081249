import React from 'react'
import Header from '../Coman/Header'
import LookingD from '../About-Us/LookingD'
import Footar from '../Coman/Footar'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import CryptoGamingAppHero from './CryptoGamingAppHero'
import Resource from './Resource'
import Finance from './Finance'
import ProjectSerum from './ProjectSerum'
import Corporates from './Corporates'
import TakeYourGame from './TakeYourGame'
import CentricDesign from './CentricDesign'

export default function CryptoGamingApp() {
  return (
    <div>
      <Header/>
      <CryptoGamingAppHero/>
      <Finance/>
      <Resource/>
      <Corporates/>
      <ProjectSerum/>
      <TakeYourGame/>
      <CentricDesign/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
