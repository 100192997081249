import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import WhiteLabelHero from './WhiteLabelHero'
import LookingD from '../About-Us/LookingD'
import DidYouKnow from './DidYouKnow'
import WhiteLabelCrypto from './WhiteLabelCrypto'
import MarketReady from './MarketReady'
import KeyFeatures from './KeyFeatures'
import SecurityWLabel from './SecurityWLabel'
import SlashDown from './SlashDown'
import BusinessBenefits from './BusinessBenefits'
import CloneScript from './CloneScript'
import CloneTheSuccess from './CloneTheSuccess'
import OurBestSelling from './OurBestSelling'
import ChooseUsWhiteLabel from './ChooseUsWhiteLabel'
import WhiteLabelBlog from './WhiteLabelBlog'
import ReadyToLaunch from './ReadyToLaunch'

export default function WhiteLabelExchange() {
  return (
    <>
      <Header/>
      <WhiteLabelHero/>
      <DidYouKnow/>
      {/* <WhiteLabelCrypto/> */}
      <MarketReady/>
      <ReadyToLaunch/>
      <KeyFeatures/>
      <CloneTheSuccess/>
      <SecurityWLabel/>
      {/* <SlashDown/> */}
      {/* <BusinessBenefits/> */}
      {/* <CloneScript/> */}
      <OurBestSelling/>
      {/* <ChooseUsWhiteLabel/> */}
      <WhiteLabelBlog/>
      <LookingD/>
      <Footar/>
    </>
  )
}
