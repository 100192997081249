import React from "react";

export default function BenefitsNFT() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our Approach For NFT Development</h2>
            <p className="pt-2">
              The NFT development process involves several key stages that
              transform your concept into a fully functional non-fungible token,
              ready for deployment on the blockchain. Here's an overview of the
              steps involved:
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/conceptualization_consultation.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Conceptualization & Consultation</h3>
                  <p>
                    The process begins with understanding your vision and
                    objectives for the NFT project. Whether you’re creating
                    digital art, tokenizing real-world assets, or building an
                    NFT-based platform, we work with you to refine the idea and
                    outline the technical requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/blockchain_selection.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Blockchain Selection</h3>
                  <p>
                    Based on your project’s needs, we help you select the most
                    suitable blockchain network (e.g., Ethereum, Binance Smart
                    Chain, Solana, Polygon). This decision depends on factors
                    like transaction speed, cost, scalability, and the target
                    audience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/nft_design_metadata_creation.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>NFT Design & Metadata Creation</h3>
                  <p>
                    Our team collaborates with you to design custom NFTs,
                    focusing on the visual elements, asset attributes, and
                    metadata (information that defines the NFT’s unique
                    properties, such as its name, description, and traits). This
                    ensures your NFTs are one-of-a-kind and recognizable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/smart_contract_development.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Smart Contract Development</h3>
                  <p>
                    We develop secure, tamper-proof smart contracts that handle
                    the minting, ownership transfers, and other functionalities
                    of your NFTs. We ensure compliance with leading token
                    standards like ERC-721, ERC-1155, or other relevant
                    protocols.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/nft_minting.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>NFT Minting</h3>
                  <p>
                    Once the design and smart contract are ready, the NFTs are
                    minted (created) on the chosen blockchain. Each minted NFT
                    is linked to its corresponding metadata and becomes a
                    verifiable digital asset that can be traded, sold, or
                    transferred.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "360px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/nft_dev/launch_support.svg"
                      height={45}
                      width={45}
                      className="mb-2"
                    />
                  </div>
                  <h3>Launch & Support</h3>
                  <p>
                    After successful testing, your NFTs are deployed to the
                    blockchain and made available to the public. We provide
                    post-launch support to ensure smooth operation and address
                    any issues that arise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

{
  /* <div className="row clearfix">
<div className="col-lg-4 col-md-6 col-sm-12 pricing-box">
  <div
    className="pricing-box-two wow fadeInUp animated"
    data-wow-delay="00ms"
    data-wow-duration="1500ms"
  >
    <div className="pricing-table">
      <div className="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-code-slash"
          viewBox="0 0 16 16"
        >
          <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0m6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0" />
        </svg>
        <h3>Greater transparency</h3>
        <p>
          The NFT lending platform provides complete transparency to
          lenders and borrowers.
        </p>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-4 col-md-6 col-sm-12 pricing-box">
  <div
    className="pricing-box-two wow fadeInUp animated"
    data-wow-delay="00ms"
    data-wow-duration="1500ms"
  >
    <div className="pricing-table">
      <div className="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-coin"
          viewBox="0 0 16 16"
        >
          <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
        </svg>
        <h3>Better security</h3>
        <p>
        Our motive is to ensure tighter security and privacy of your data and digital assets.
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div className="row clearfix pt-5">
<div className="col-lg-4 col-md-6 col-sm-12 pricing-box">
  <div
    className="pricing-box-two wow fadeInUp animated"
    data-wow-delay="400ms"
    data-wow-duration="1500ms"
  >
    <div className="pricing-table">
      <div className="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-cup-hot-fill"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M.5 6a.5.5 0 0 0-.488.608l1.652 7.434A2.5 2.5 0 0 0 4.104 16h5.792a2.5 2.5 0 0 0 2.44-1.958l.131-.59a3 3 0 0 0 1.3-5.854l.221-.99A.5.5 0 0 0 13.5 6zM13 12.5a2 2 0 0 1-.316-.025l.867-3.898A2.001 2.001 0 0 1 13 12.5"
          />
          <path d="m4.4.8-.003.004-.014.019a4 4 0 0 0-.204.31 2 2 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 0 1-.202.388 5 5 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 3.6 4.2l.003-.004.014-.019a4 4 0 0 0 .204-.31 2 2 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.6.6 0 0 0-.09-.252A4 4 0 0 0 3.6 2.8l-.01-.012a5 5 0 0 1-.37-.543A1.53 1.53 0 0 1 3 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 4.4.8m3 0-.003.004-.014.019a4 4 0 0 0-.204.31 2 2 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 0 1-.202.388 5 5 0 0 1-.253.382l-.018.025-.005.008-.002.002A.5.5 0 0 1 6.6 4.2l.003-.004.014-.019a4 4 0 0 0 .204-.31 2 2 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.6.6 0 0 0-.09-.252A4 4 0 0 0 6.6 2.8l-.01-.012a5 5 0 0 1-.37-.543A1.53 1.53 0 0 1 6 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 7.4.8m3 0-.003.004-.014.019a4 4 0 0 0-.204.31 2 2 0 0 0-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 0 0 .091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 0 1-.202.388 5 5 0 0 1-.252.382l-.019.025-.005.008-.002.002A.5.5 0 0 1 9.6 4.2l.003-.004.014-.019a4 4 0 0 0 .204-.31 2 2 0 0 0 .141-.267c.026-.06.034-.092.037-.103a.6.6 0 0 0-.09-.252A4 4 0 0 0 9.6 2.8l-.01-.012a5 5 0 0 1-.37-.543A1.53 1.53 0 0 1 9 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 0 1 .253-.382l.025-.035A.5.5 0 0 1 10.4.8" />
        </svg>
        <h3>Liquidity of NFTs</h3>
        <p>For bloggers, freelancers and businesses</p>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-4 col-md-6 col-sm-12 pricing-box">
  <div
    className="pricing-box-two wow fadeInUp animated"
    data-wow-delay="600ms"
    data-wow-duration="1500ms"
  >
    <div className="pricing-table">
      <div className="table-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-gem"
          viewBox="0 0 16 16"
        >
          <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6zm11.386 3.785-1.806-2.41-.776 2.413zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82zm7.889 6.817 5.123-6.83-2.928.002z" />
        </svg>
        <h3>Immutability</h3>
        <p>
          The NFT platform is backed by boxchain technology, it is
          immutable & impenetrable by default.
        </p>
      </div>
    </div>
  </div>
</div>
</div> */
}
