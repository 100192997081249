import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function Cryptocurrency() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Crypto Exchange Script</h2>
                  <p className="pt-3">
                    Our Crypto Exchange Script is a pre-built, highly
                    customizable solution that allows you to launch a fully
                    functional cryptocurrency exchange platform in no time.
                    Equipped with multi-layer security protocols, seamless API
                    integrations, and advanced trading features, our Crypto
                    Exchange Script offers a cost-effective and time-efficient
                    way to enter the crypto exchange market.
                  </p>
                  <p>
                    Whether you're looking to launch a basic trading platform or
                    a complex exchange with multiple features like margin
                    trading, liquidity management, and wallet support, our
                    Crypto Exchange Script is built to meet your specific
                    requirements. With an intuitive user interface and support
                    for a wide range of cryptocurrencies, our script ensures an
                    optimal trading experience for both novice and seasoned
                    traders.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <div className="row">
                      <div className="col">
                        <li>Multi-Currency Support</li>
                        <li>Customizable User Interface</li>
                        <li>Robust Security</li>
                      </div>
                      <div className="col">
                        <li>Liquidity Integration</li>
                        <li>Real-Time Data & Analytics</li>
                      </div>
                    </div>
                  </ul>
                </div>
                <h4 className="py-3">
                  Use our script & quickly launch your crypto <br /> exchange 🚀
                </h4>
                <TalkToOurTeamBTN/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="pb-5 ml_40">
                <figure className="">
                  <img src="assets/img/exchange/Crypto-Exchange-Script-Section-Image.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
