import React from "react";

export default function PopularofUse() {
  return (
    <>
      <section className="about-style-four  p_100">
        <div className="auto-container">
          <div className="text-center pb-5">
            <div data-animation-box className="sec-title ">
            <h2>Popular of Use Cases of DeFi Lottery</h2>
            </div>
            <p className="pt-3">
              Here are some popular use cases of DeFi lottery platforms that
              showcase the innovative ways decentralized finance is transforming
              traditional lottery systems
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_six">
                <div className="content-box ml_40">
                  <div className="inner-box mb_40">
                    <div className="single-item mb_30">
                      <h3>NFT Lottery</h3>
                      <p>
                        Users can participate in lotteries to win exclusive
                        NFTs, making it appealing to the growing NFT community.
                      </p>
                    </div>
                    <div className="single-item mb_30">
                      <h3>Yield-Generating Lotteries</h3>
                      <p>
                        Part of the ticket purchase may be staked in
                        yield-generating pools, with proceeds shared among all
                        participants or added to a jackpot over time.
                      </p>
                    </div>
                    <div className="single-item ">
                      <h3>Community-Based Lotteries</h3>
                      <p>
                        DAOs and communities may host lotteries as part of their
                        engagement activities, creating unique lotteries funded
                        by the community and distributed transparently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_five">
                <figure className="image-box">
                  <img
                    src="assets/img/defi/defi-y-f/Popular-of-Use-Cases-of-DeFi-Lottery-Section-Image.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
