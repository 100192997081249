import React from "react";

export default function Gateways() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
            Start Accepting a Wide Range of Cryptocurrencies
            </h2>
            <p className="pt-3">
            Easily expand your payment options by accepting multiple cryptocurrencies, from popular assets like Bitcoin and Ethereum to stablecoins, allowing your customers the flexibility to pay with their preferred digital currency.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/trust_wallet.svg"
                  />
                  <h5 className="py-3">Bitcoin</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/meya.svg"
                  />
                  <h5 className="py-3">Ethereum</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/phantom.svg"
                  />
                  <h5 className="py-3">USDT</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/tronlink.svg"
                  />
                  <h5 className="py-3">Solana</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/coinbase_wallet.svg"
                  />
                  <h5 className="py-2">XRP</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="assets/img/svg/exodus_wallet.svg"
                  />
                  <h5 className="py-2">USDC </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
