import React from "react";

export default function TokenMarketing() {
  return (
    <>
      {/* <section className="faq-style-three p_120">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-44.png)" }}
        />
        <div
          className="pattern-layer-2"
          style={{ backgroundImage: "url(assets/images/shape/shape-70.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_50 centred">
            <h2>Our Marketing Services</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image-box ml_30">
                <figure className="image">
                  <img src="assets/images/resource/faq-1.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">Our Marketing Services</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="inner-box mr_30">
                  <h2>Crypto Token Marketing</h2>
                  <p className="py-3">
                    <span>Drive Adoption with Strategic Token Marketing</span>
                    Xeer Technology specializes in designing and executing
                    strategic token marketing campaigns aimed at increasing
                    awareness, driving adoption, and enhancing user engagement.
                    Our services include everything from pre-launch awareness to
                    community building and influencer partnerships, ensuring
                    your token stands out in the crowded market.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Targeted Campaigns</h4>
                      <p>
                        Reach the right audience with focused campaigns tailored
                        to your token’s vision.
                      </p>
                    </li>
                    <li>
                      <h4>Community Growth</h4>
                      <p>
                        Build a robust community of loyal supporters around your
                        token.
                      </p>
                    </li>
                    <li>
                      <h4>Influencer Collaborationst</h4>
                      <p>
                        Leverage industry influencers to amplify brand reach and
                        establish trust.
                      </p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Explore Token Marketing with Xeer
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                  <figure className="image">
                  <img
                      src="assets/img/marketing/boost-visibility.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
