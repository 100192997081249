import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function IDPDevelopmentHero() {
  return (
    <>
      <section className="feature-section p_150"   style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: "url(assets/img/coin-dev/initial-dex-offering-ido-services.jpg)",
        }}>
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Initial DEX Offering (IDO) Services</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    We offer comprehensive Initial DEX Offering (IDO) services
                    designed to empower blockchain startups, enabling them to
                    access secure and efficient decentralized fundraising!
                  </p>
                </div>
              </div>
              <div className="btn-box">
              <TalkToOurTeamBTN/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
