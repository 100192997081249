import React from "react";

export default function WebGameDevS() {
  return (
    <>
      <section className="contact-info-section centred pt_50 pb_120">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Key Highlights of our Web3 Game Development Service</h2>
            <p className="pt-3">
              By harnessing blockchain technology, our team empowers developers
              and players alike with innovative gaming ecosystems that offer
              true ownership, play-to-earn models, and engaging metaverse
              integrations!
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "440px" }}>
                 
                    <img
                      src="assets/img/web3/decentralized-gaming-infrastructure.svg"
                      width={60}
                      className=" mb-2"
                    />{" "}
                  
                  <h3>Decentralized Gaming Infrastructure</h3>
                  <p>
                    We build games on decentralized networks, ensuring that
                    players have full ownership and control over their in-game
                    assets. This transparency promotes fair play and provides a
                    more immersive and secure experience for users.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "440px" }}>
                  
                    <img
                      src="assets/img/web3/nft-and-digital-asset-integration.svg"
                      width={60}
                      className=" mb-2"
                    />{" "}
                  
                  <h3>NFT and Digital Asset Integration</h3>
                  <p>
                    Our team incorporates NFTs and digital assets, allowing
                    players to trade, sell, and monetize unique items they
                    acquire or create. Xeer Technology’s NFT integration ensures
                    secure, verifiable ownership and enhances in-game economies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "440px" }}>
                 
                    <img
                      src="assets/img/web3/play-to-earn-models.svg"
                      width={60}
                      className=" mb-2"
                    />{" "}
                
                  <h3>Play-to-Earn (P2E) Models</h3>
                  <p>
                    Revolutionize your game’s economy with play-to-earn models
                    that reward users with cryptocurrency or in-game tokens.
                    Xeer Technology’s P2E solutions create real value for
                    players, fostering engagement and loyalty.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "440px" }}>
                 
                    <img
                      src="assets/img/web3/metaverse-interactivity.svg"
                      width={60}
                      className=" mb-2"
                    />{" "}
                
                  <h3>Metaverse Interactivity</h3>
                  <p>
                    Enter the future of gaming with metaverse-ready features,
                    where players can explore interconnected virtual worlds. We
                    develop immersive experiences, customizable avatars, and
                    social interaction tools, bringing life to virtual worlds.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "440px" }}>
                  
                    <img
                      src="assets/img/web3/multi-chain-and-cross-chain-compatibility.svg"
                      width={60}
                      className=" mb-2"
                    />{" "}
                
                  <h3>Multi-Chain and Cross-Chain Compatibility</h3>
                  <p>
                    We build Web3 games that are compatible with various
                    blockchain platforms, including Ethereum, BNB Chain, Solana,
                    and more. Our multi-chain solutions give players the freedom
                    to interact across ecosystems, increasing the value and
                    usability of in-game assets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
