import React from "react";

export default function Bold() {
  return (
    <>
      <section className="integrations-style-four centred pb_100">
        <div className="auto-container">
          <div className="inner-container py_120">
            <div data-animation-box className="sec-title mb_55">
              <h2>Glimpse of Our White Label Crypto Wallet</h2>
              <p>
                Take a look at the design & feel of our white label crypto
                wallet! It could be designed, customized & crafted as you want.
              </p>
            </div>
            <div className="inner-box">
              <img src="assets/img/wallet/glimpse-of-our-white-label-crypto-wallet.png" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
