import React from "react";

export default function HashgraphServicesOffered() {
  return (
    <div>
      <section className="feature-section pt_100 pb_150">
        <div className="auto-container">
          <div className="row justify-content-center">
            <div data-animation-box="true" className="sec-title mb_25">
              <h2 className="text-center">
                Services Offered by Xeer in Hedera Hashgraph Development
              </h2>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  src="assets/img/blockchain-dev/Custom-dApp-Development-Image.png"
                  alt=""
                  // style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Custom dApp Development</h2>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Requirement Analysis and Feasibility Study:-
                  </span>
                  Comprehensive assessment of client needs.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Architecture & Smart Contract Development
                  </span>
                  Creating secure architectures and smart contracts.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Full-Stack Development
                  </span>
                  Front-end and back-end integration for cohesive applications.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    System Integration{" "}
                  </span>
                  Ensuring seamless compatibility with current infrastructure.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Smart Contract Development</h2>

                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Design & Development:
                  </span>
                  Building efficient, secure contracts with Hedera SDK.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Security Audits & Testing
                  </span>
                  Robust security checks to identify vulnerabilities.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Deployment & Maintenance:
                  </span>
                  Reliable deployment and ongoing support for optimal
                  performance.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  className="img-fluid"
                  src="assets/img/blockchain-dev/Smart-Contract-Development-Image.png"
                  alt=""
                  // style={{ width: "75%" }}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  src="assets/img/blockchain-dev/Token-Development-Image.png"
                  alt=""
                  // style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Token Development</h2>

                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Utility Tokens for dApps:
                  </span>
                  Facilitating transactions within decentralized applications.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Security Tokens for Fundraising:
                  </span>
                  Tokenized securities for compliant fundraising initiatives.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Comprehensive Support:
                  </span>
                  Front-end and back-end integration for seamless token
                  functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Hedera Consulting</h2>

                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Technology Assessment:
                  </span>
                  Determining Hedera’s suitability for specific business goals.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Architecture Design:
                  </span>
                  Best practices for designing efficient, effective solutions.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Performance Optimization:
                  </span>
                  Enhancing efficiency and operational success.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  className="img-fluid"
                  src="assets/img/blockchain-dev/Performance-Optimization-Image.png"
                  alt=""
                  // style={{ width: "75%" }}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  src="assets/img/blockchain-dev/Migration-Services-Image.png"
                  alt=""
                  // style={{ width: "80%" }}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Migration Services</h2>

                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Updates & Upgrades:
                  </span>
                  Keeping applications current with new features.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Performance Monitoring:
                  </span>
                  Ensuring applications function at peak performance.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Troubleshooting & Bug Fixing:
                  </span>
                  Timely resolution of issues for uninterrupted functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="inner-container">
                <h2>Why Choose Xeer?</h2>
                <h6 className="mt-3">
                  Xeer is a leader in Hedera Hashgraph development,
                  distinguished by:
                </h6>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Proven Track Record:
                  </span>
                  Successful project delivery across diverse industries.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Technical Expertise:
                  </span>
                  Highly skilled team with deep expertise in Hedera Hashgraph
                  and DLT.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Client-Centric Approach:
                  </span>
                  Dedicated to understanding and meeting client needs.
                </p>
                <p className="mt-3">
                  <span style={{ fontWeight: "600" }} className="text-black">
                    Innovation:
                  </span>
                  Constantly exploring new solutions to drive value and success.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div>
                <img
                  className="img-fluid"
                  src="assets/img/blockchain-dev/Why-Choose-Xeer-Image.png"
                  alt=""
                  // style={{ width: "75%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
