import React from "react";

export default function Turbocharge() {
  return (
    <>
      <section className="clients-section alternat-2 pt_50 pb_50 centred" style={{backgroundColor:"#071522"}}>
        <div className="auto-container">
          <div className="inner-container">
            <h3 className="text-white">
              Turbocharge Your IDO Development Journey With Us! Turn Your
              Fundraising Dreams Into Reality By Attracting Global Investors For
              Capital Raising.
            </h3>
          </div>
          <a href="#" className="theme-btn btn-one mr_20">
            Connect on Whatsapp
          </a>
        </div>
      </section>
    </>
  );
}
