import React from "react";

export default function BookBasedDEX() {
  return (
    <>
      <section className="feature-section pt_150 pb_150">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25">
            <h2 className="text-center">Enter DeFi Realm with Trading on DEX</h2>
            <p className="text-center pt-3">
              Step into the world of decentralized finance (DeFi) by
              trading on a Decentralized Exchange (DEX). Enjoy the freedom
              to trade cryptocurrencies directly from your wallet, without
              intermediaries, and experience the security and transparency
              of blockchain-powered transactions. DEX platforms offer
              access to a variety of DeFi features, including liquidity
              pools, yield farming, and cross-chain trading, all while
              ensuring privacy and lower fees. Empower yourself with full
              control over your assets as you engage in seamless,
              peer-to-peer trading on the decentralized web.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <h3>What a DEX offers?</h3>
                    <div className="row py-3">
                      <div className="col-lg-4">
                        <li>Smart Contract-Based Transactions</li>
                        <li>Automated Market Maker (AMM)</li>
                        <li>Interoperability with DeFi Protocols</li>
                        <li>On-Chain Order Books (for some DEXs)</li>
                        <li>High Security and Resistance to Hacks</li>
                      </div>
                      <div className="col-lg-4">
                        <li>Non-Custodial Trading</li>
                        <li>Liquidity Pools</li>
                        <li>Cross-Chain Trading</li>
                        <li>Token Swaps</li>
                        <li>Peer-to-Peer Trading</li>
                      </div>
                      <div className="col-lg-4">
                        <li>Decentralized Governance</li>
                        <li>Multi-Signature Wallets</li>
                        <li>Low Transaction Fees</li>
                        <li>Privacy and Anonymity</li>
                        <li>Yield Farming and Staking</li>
                      </div>

                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className=" ml_40">
                <figure className="image image-1">
                  <img
                    src="assets/img/Enter-DeFi-Realm-with-Trading-on-DEX-Section-Image.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
