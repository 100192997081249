import React from "react";

export default function Features() {
  return (
    <>
      <section className="pricing-style-three pricing-page-2 pt-5 pb_120 centred">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-71.png)" }}
        />
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Features of P2P Crypto Exchange</h2>
            <p>
              Our P2P crypto exchange offers various features & functionalities.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated  d-flex justify-content-between"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/powerful_trading_engine.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">Powerful Trading Engine</h3>
                    <p>
                      Facilitate direct transactions between buyers and sellers,
                      eliminating the need for centralized authorities and
                      reducing operational risks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/secure_escrow_system.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">Secure Escrow System</h3>
                    <p>
                      Our built-in escrow service ensures that funds are held
                      securely during the transaction, providing a safe and
                      reliable environment for users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/customizable_platform.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">Customizable Platform</h3>
                    <p>
                      Tailor the P2P exchange platform to your specific business
                      requirements with advanced features, a user-friendly
                      interface, and flexible design options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/multi_currency_support.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">Multi-Currency Support</h3>
                    <p>
                      Support a wide range of cryptocurrencies to attract a
                      diverse user base and provide traders with a comprehensive
                      marketplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/robust_security.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">Robust Security</h3>
                    <p>
                      We implement advanced security features like two-factor
                      authentication (2FA), end-to-end encryption, and
                      anti-phishing measures to protect users and their assets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
              <div
                className="pricing-block-two wow fadeInUp animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="pricing-table" style={{height:"345px"}}>
                  <div className="">
                    <img style={{width:"70px"}} src="assets/img/exchange/ptop/kyc.svg" />
                  </div>
                  <div className="table-header my_20">
                    <h3 className="">KYC/AML Integration</h3>
                    <p>
                      Seamlessly incorporate Know Your Customer (KYC) and
                      Anti-Money Laundering (AML) processes to ensure regulatory
                      compliance and safeguard user identities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
