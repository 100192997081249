import React from 'react'
import Header from '../Coman/Header'
import WhiteLabelNFTHero from './WhiteLabelNFTHero'
import Footar from '../Coman/Footar'
import WithExpert from './WithExpert'
import TheAverage from './TheAverage'
import Hassle from './Hassle'
import BuildARobust from './BuildARobust'
import WeGoBeyond from './WeGoBeyond'
import OpenseaClone from './OpenseaClone'
import TheRevenue from './TheRevenue'
import EventTicketing from './EventTicketing'
import BlogsNFT from '../NFT/BlogsNFT'
import LookingD from '../About-Us/LookingD'

export default function WhiteLabelNFT() {
  return (
    <div>
      <Header/>
      <WhiteLabelNFTHero/>
      <TheAverage/>
      {/* <WithExpert/> */}
      <Hassle/>
      <BuildARobust/>
      <WeGoBeyond/>
      {/* <OpenseaClone/> */}
      {/* <TheRevenue/> */}
      <EventTicketing/>
      <BlogsNFT/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
