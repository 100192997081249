import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function DefiLotteryHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/defi/DeFi-Lottery-Platform-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    DeFi Lottery Platform Development
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Unlock the future of lottery systems with Xeer Technology’s
                    advanced DeFi lottery platform development services.
                    Combining the excitement of lottery games with the
                    transparency, security, and decentralization of blockchain
                    technology, our DeFi lottery platforms offer a unique,
                    trustless gaming experience that maximizes user engagement
                    and revenue potential.
                  </p>
                </div>
              </div>
              <div className="btn-box">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
