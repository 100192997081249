import React from "react";
import HeroBlogs from "./HeroBlogs";
import Header from "../Coman/Header";
import Footar from "../Coman/Footar";
import BlogsStwo from "./BlogsStwo";
import ContactUs from "../Coman/ContactUs";

export default function Blogs() {
  return (
    <>
      <div class="boxed_wrapper ltr">
        <Header />
        <HeroBlogs />
        <BlogsStwo/>
        <ContactUs/>
        <Footar />
      </div>
    </>
  );
}
