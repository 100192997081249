import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import MarketMakingServicesHero from './MarketMakingServicesHero'
import Pioneers from './Pioneers'
import Propelling from './Propelling'
import ByHarnessing from './ByHarnessing'
import FosteringThriving from './FosteringThriving'
import WeConstruct from './WeConstruct'
import ASophisticated from './ASophisticated'
import Exclusive from './Exclusive'
import EIntegrations from './EIntegrations'
import XeerIsOne from './XeerIsOne'
import LookingD from '../About-Us/LookingD'
import WhatIsMarket from './WhatIsMarket'
import WhyChooseCMMS from './WhyChooseCMMS'
import Blog from '../Home/Blog'

export default function MarketMakingServices() {
  return (
    <div>
      <Header/>
      <MarketMakingServicesHero/>
      <Pioneers/>
      <WhatIsMarket/>
      {/* <Propelling/> */}
      <FosteringThriving/>
      {/* <WeConstruct/> */}
      <ByHarnessing/>
      {/* <ASophisticated/> */}
      <Exclusive/>
      <EIntegrations/>
      <WhyChooseCMMS/>
      {/* <XeerIsOne/> */}
      <Blog/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
