import React from "react";

export default function BeforeChoosing() {
  return (
    <>
      <section className="about-style-three p_150">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-67.png)" }}
        />
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box mr_80">
                  <figure className="image">
                    <img
                      src="assets/img/crypto-g-dev/cypto-payment-gateway-development-by-xeer-technology.png"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_40">
                  <div className="sec-title mb_25">
                    <h2>
                      Crypto Payment Gateway Development by Xeer Technology
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      At Xeer Technology, we specialize in developing robust and
                      efficient Crypto Payment Gateways that empower businesses
                      to embrace the future of finance. Our solutions are
                      designed to facilitate seamless cryptocurrency
                      transactions, providing a secure and user-friendly
                      experience for both merchants and customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
