import React from "react";

export default function RebrandingM() {
  return (
    <>
      <section className="feature-section p_50">
        <div className="auto-container">
          <h1 className="text-center">Rebranding</h1>
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                
                <div className="inner-box mr_30">
                  
                  <h4>Transform Your Brand for Maximum Impact</h4>
                  <p className="py-3">
                    Whether you’re pivoting or just looking for a fresh
                    identity, our rebranding services refine your visual
                    identity and messaging to better resonate with target
                    audiences. We make your brand relevant, recognizable, and
                    ready to connect.
                  </p>
                </div>
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h4>Logo & Identity Design</h4>
                      <p>Modernize your brand’s visual elements.</p>
                    </li>
                    <li>
                      <h4>Messaging Overhaul</h4>
                      <p> Align brand messaging with target audience needs.</p>
                    </li>
                    <li>
                      <h4>Consistent Brand Voice</h4>
                      <p> Establish a cohesive and memorable brand identity.</p>
                    </li>
                  </ul>
                </div>
                <a href="/contact-us" className="theme-btn btn-one mr_20">
                  Rebrand with Xeer
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ml_40">
                <div className="image-box">
                <div className="image-box">
                  <figure className="image">
                    <img
                      src="assets/img/rebranding.png"
                      className="img-fluid"
                      alt=""
                    />
                  </figure>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
