import React from "react";

export default function BlockchainSupply() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-5.png")',
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-6.png")',
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-7.png")',
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-8.png")',
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: 'url("assets/images/shape/shape-9.png")',
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose TON Blockchain?</h2>
              <p>
                The Open Network (TON) is a cutting-edge blockchain ecosystem
                designed to handle millions of transactions per second through
                its unique sharding and workchain model. Originally designed by
                Telegram, TON offers high throughput, low latency, and scalable
                solutions ideal for various decentralized applications (DApps),
                NFT projects, DeFi platforms, and beyond.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
