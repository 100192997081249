import React, { useState } from "react";

export default function Platform() {
  const [toggle, settoggle] = useState(0);

  return (
    <section className="faq-section testimonial-page-one pt_140 pb_150 ">
      <div className="auto-container">
        <div data-animation-box className="sec-title centred mb_55">
          <h2>
            Glimpse of our NFT Marketplace Development Services
          </h2>
          <p className="pt-3">
            Explore a comprehensive suite of tailored solutions designed to
            empower your NFT marketplace, from intuitive user interfaces and
            seamless transaction processes to robust security measures and
            innovative features that enhance engagement and drive user adoption.
          </p>
        </div>
        <div className="inner-box mr_30">
          <ul className="accordion-box">
            <li className="accordion block active-block">
              <div className={`acc-btn ${toggle === 0 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(0)} />
                <h4>Custom Marketplace Design</h4>
              </div>
              <div className={`acc-content ${toggle === 0 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    We design a fully branded, user-friendly NFT marketplace
                    that reflects your vision and resonates with your target
                    audience. Our custom design solutions ensure a smooth
                    experience for both buyers and sellers.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 1 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(1)} />
                <h4>Multi-Blockchain Support</h4>
              </div>
              <div className={`acc-content ${toggle === 1 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    Our marketplaces are built with multi-chain compatibility,
                    allowing support for Ethereum, Binance Smart Chain, Solana,
                    Polygon, and more. This enables greater flexibility and
                    broader reach for your marketplace.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 2 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(2)} />
                <h4>Smart Contract Integration</h4>
              </div>
              <div className={`acc-content ${toggle === 2 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    We develop and integrate secure, self-executing smart
                    contracts that automate the creation, minting, trading, and
                    auctioning of NFTs, ensuring transparency, security, and
                    tamper-proof transactions.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 3 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(3)} />
                <h4>Advanced Search & Filtering</h4>
              </div>
              <div className={`acc-content ${toggle === 3 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    Provide your users with intuitive tools for discovering
                    NFTs. Our advanced search and filtering features enhance
                    navigation, making it easier for users to explore, purchase,
                    or sell NFTs within specific categories.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 4 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(4)} />
                <h4>Auction & Bidding System</h4>
              </div>
              <div className={`acc-content ${toggle === 4 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    Enable dynamic pricing and competitive buying through
                    integrated auction and bidding features, giving users
                    multiple options for acquiring NFTs.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 5 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(5)} />
                <h4>Wallet Integration</h4>
              </div>
              <div className={`acc-content ${toggle === 5 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    Seamless integration with popular crypto wallets (e.g.,
                    MetaMask, Trust Wallet, Coinbase) for secure transactions
                    and asset management. This makes it easy for users to store
                    and manage their NFTs directly within your platform.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 6 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(6)} />
                <h4>Royalties & Revenue Models</h4>
              </div>
              <div className={`acc-content ${toggle === 6 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    Implement custom royalty systems that ensure creators
                    continue to earn on secondary sales. We also integrate
                    diverse revenue models such as listing fees, transaction
                    fees, and commissions to maximize profitability.
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion block">
              <div className={`acc-btn ${toggle === 7 ? "active" : ""}`}>
                <div className="icon-box" onClick={() => settoggle(7)} />
                <h4>High-Level Security</h4>
              </div>
              <div className={`acc-content ${toggle === 7 ? "current" : ""}`}>
                <div className="text">
                  <p>
                    We employ advanced security protocols including two-factor
                    authentication (2FA), encryption, and smart contract audits
                    to safeguard your platform and user assets.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
