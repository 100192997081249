import React from 'react'
import Header from '../Coman/Header'
import CryptocurrencyWalletHero from './CryptocurrencyWalletHero'
import Footar from '../Coman/Footar'
import TheGlobal from './TheGlobal'
import Supercharge from './Supercharge'
import Empowering from './Empowering'
import Unlocking from './Unlocking'
import DigitalWalletBlog from './DigitalWalletBlog'
import LookingD from '../About-Us/LookingD'
import WhatisCryWallet from './WhatisCryWallet'
import DedicatedS from './DedicatedS'
import LaunchYourW from './LaunchYourW'

export default function CryptocurrencyWallet() {
  return (
    <div>
        <Header/>
        <CryptocurrencyWalletHero/>
        <TheGlobal/>
        <WhatisCryWallet/>
        {/* <Supercharge/> */}
        <Empowering/>
        <Unlocking/>
        <LaunchYourW/>
        <DedicatedS/>
        <DigitalWalletBlog/>
        <LookingD/>
        <Footar/>      
    </div>
  )
}
