import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function ExchangeHero() {
  return (
    <>
      <section
        className="banner-style-six p_150"
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage:
            "url(assets/img/exchange/Launch-your-own-Crypto-Exchange-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center pb_50">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_30 pt-5">
                <h1 className="text-white">Launch your own Crypto Exchange</h1>
                <p className="text-white pt-5">
                  We will design & develop a customized cryptocurrency exchange
                  for you with all essential features like spot trading, account
                  wallets, p2p window, user dashboard, admin panel & more.
                </p>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 "></div>
          </div>
        </div>
      </section>
    </>
  );
}
