import React from "react";
import Header from "../Coman/Header";
import LookingD from "../About-Us/LookingD";
import Footar from "../Coman/Footar";
import CryptoGamingPlatformHero from "./CryptoGamingPlatformHero";
import BlogWeb from "../Web-3-0-Devlopment/BlogWeb";
import Keeps from "./Keeps";
import Features from "./Features";
import YourSecurityTO from "./YourSecurityTO";
import SecurityProtocols from "./SecurityProtocols";

export default function CryptoGamingPlatform() {
  return (
    <div>
      <Header />
      <CryptoGamingPlatformHero />
      <Keeps />
      <YourSecurityTO />
      <Features />
      <SecurityProtocols />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
