import React from "react";

export default function Layer2Solutions() {
  return (
    <>
      <section className="contact-info-section centred pt_100 pb_120">
        <div className="auto-container">
          <div data-animation-box="true" className="sec-title mb_55">
            <h2>Xeer’s Polkadot Blockchain Development Services</h2>
            <p className="pt-3">
              With Xeer Technology’s deep expertise in Polkadot, we deliver
              reliable and innovative solutions designed to take advantage of
              Polkadot’s ecosystem.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"345px"}}>
                  <div className="">
                  <img src="assets/img/core_features_development.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Parachain Development</h3>
                  <p className="">
                    Our developers create custom parachains that maximize
                    scalability and interoperability, integrating your
                    blockchain project seamlessly with the Polkadot ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init aos-animate"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"345px"}}>
                  <div className="">
                  <img src="assets/img/dapps.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Polkadot DApp Development </h3>
                  <p className="">
                    We build powerful DApps on Polkadot that leverage its
                    decentralized, scalable infrastructure for seamless user
                    experiences and network efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"345px"}}>
                  <div className="">
                  <img src="assets/img/cross_platform_compatibility.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Cross-Chain Bridge Development</h3>
                  <p className="">
                    Our cross-chain solutions allow your Polkadot project to
                    interact with other blockchain networks, enhancing reach and
                    usability across ecosystems.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"345px"}}>
                  <div className="">
                  <img src="assets/img/custom_smart_contract_development.svg" style={{width:"74px"}} />
                  </div>
                  <h3 className=" pt-3">Smart Contract Development</h3>
                  <p className="">
                    Using Polkadot’s Substrate framework, we create secure and
                    efficient smart contracts that automate processes and drive
                    innovation for businesses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-block">
              <div
                className="info-block-one aos-init"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"345px"}}>
                  <div className="">
                  <img src="assets/img/consulting.svg" style={{width:"66px"}} />
                  </div>
                  <h3 className=" pt-3">Polkadot Consulting and Strategy</h3>
                  <p className="">
                    Xeer’s consultants provide insights and strategic planning
                    to help you navigate Polkadot’s ecosystem and maximize its
                    potential for your project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
