import React from "react";

export default function Remunerative() {
  return (
    <>
      <section className="feature-style-two pt-5 pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Why Launch a Web3 Wallet?</h2>
            <p className="pt-3">
              Unlock new opportunities for security, growth, and engagement in
              the decentralized ecosystem by offering your users a secure and
              user-friendly Web3 wallet solution.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/wallet/enhanced-user-security.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />{" "}
                  </div>
                  <h3>Enhanced User Security</h3>
                  <p>
                    Web3 wallets give users full control over their private
                    keys, minimizing the risks associated with third-party
                    custodians and providing stronger protection for digital
                    assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/wallet/decentralized_app_(dapp)_development.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />{" "}
                  </div>
                  <h3>Access to Decentralized Ecosystems</h3>
                  <p>
                    By launching a Web3 wallet, users can easily connect with
                    decentralized applications (dApps), unlocking opportunities
                    in DeFi, NFT trading, and blockchain-based gaming.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "355px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/wallet/cross_platform_compatibility.svg"
                      width={45}
                      height={45}
                      className="mb-2"
                    />{" "}
                  </div>
                  <h3>Cross-Chain Compatibility</h3>
                  <p>
                    A Web3 wallet supports multiple blockchain protocols,
                    allowing users to manage different cryptocurrencies and
                    tokens from one interface, increasing convenience and
                    flexibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
