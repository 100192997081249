import React from "react";

export default function NeedforDeFi() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>What is a DeFi Lottery Platform</h2>
              <p>
                A DeFi Lottery Platform is a decentralized application that
                operates on blockchain technology to facilitate lottery games
                without centralized control or intermediaries. Unlike
                traditional lotteries, which are managed by governments or
                private organizations, DeFi (decentralized finance) lottery
                platforms leverage smart contracts to create transparent,
                automated, and trustless lottery systems. These platforms aim to
                enhance fairness, security, and accessibility, providing users
                with more control and ownership over their participation and
                winnings.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
