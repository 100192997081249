import React from "react";

export default function NFTLending() {
  return (
    <section className="feature-section py_100">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_four">
              <div className="mr_40 pr_50">
                <div className="image-inner">
                  <img
                    src="assets/img/nft_dev/the-increasing-popularity-of-nfts.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content-box ml_40">
              <div data-animation-box className="sec-title mb_25">
                <h2>The increasing popularity of NFTs</h2>
              </div>
              <div className="text-box">
                <p>
                  NFTs (Non-Fungible Tokens) have rapidly gained popularity as
                  they revolutionize the way digital assets are created, owned,
                  and traded. Unlike traditional cryptocurrencies, each NFT
                  represents a unique item, whether it's digital art,
                  collectibles, music, virtual real estate, or in-game assets.
                  The surge in interest is driven by creators, investors, and
                  businesses recognizing the potential of NFTs to authenticate
                  ownership and provide scarcity in the digital world. As
                  blockchain technology evolves, NFTs are becoming a crucial
                  part of the decentralized economy, offering new revenue
                  streams and creative opportunities for both individuals and
                  enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
