import React from "react";

export default function Render() {
  return (
    <>
      {/* <section className="feature-section pb_150">
        <div className="pattern-layer">
          <div
            className="pattern-1"
            style={{ backgroundImage: "url(assets/images/shape/shape-68.png)" }}
          />
          <div
            className="pattern-2"
            style={{ backgroundImage: "url(assets/images/shape/shape-69.png)" }}
          />
        </div>
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_25 text-center">
            <h2>Why choose Xeer Technology for DeFi wallet development?</h2>
            <p className="py-3">
              Leverage Xeer Technology’s expertise in building secure, scalable,
              and feature-rich DeFi wallet apps for Uniswap and 1inch. Our
              solutions are designed to provide users with seamless access to
              DeFi protocols while offering the security and flexibility
              required in today’s decentralized financial landscape. Reach out
              to us today to start building your DeFi wallet clone app!
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="mr_40 pr_50">
                <figure className="image">
                  <img
                    src="assets/img/wallet/why_choose_xeer_tchnology_for_defi_wallet_development.png"
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div className="text-box">
                  <ul className="list-style-one clearfix">
                    <li>
                      <h5>Fast Development & Deployment</h5>
                      <p className="pt-2">
                        We offer quick and efficient development of DeFi wallet
                        clone apps, enabling you to launch your platform without
                        delay.
                      </p>
                    </li>
                    <li>
                      <h5>Highly Scalable Solutions</h5>
                      <p className="pt-2">
                        Our wallet clone apps are designed to scale with your
                        user base, offering reliable performance even as
                        transaction volume grows.
                      </p>
                    </li>
                    <li>
                      <h5>Full Customization</h5>
                      <p className="pt-2">
                        Whether you want to clone Uniswap or 1inch with
                        additional features or tailored UI/UX, we provide fully
                        customizable solutions to meet your specific
                        requirements.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why choose Xeer Technology for DeFi wallet development?

            </h2>
            <p className="p-3">
              Leverage Xeer Technology’s expertise in building secure, scalable, and feature-rich DeFi wallet apps for Uniswap and 1inch. Our solutions are designed to provide users with seamless access to DeFi protocols while offering the security and flexibility required in today’s decentralized financial landscape. Reach out to us today to start building your DeFi wallet clone app!


            </p>

          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/standard_development_practice.svg" />
                  </div>
                  <h3 className="pt-4">Fast Development & Deployment
                  </h3>
                  <p>
                    We offer quick and efficient development of DeFi wallet clone apps, enabling you to launch your platform without delay.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/blockchain-dev/SVG/scalability.svg" />
                  </div>
                  <h3 className="pt-4">Highly Scalable Solutions
                  </h3>
                  <p>
                    Our wallet clone apps are designed to scale with your user base, offering reliable performance even as transaction volume grows.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "50px" }} src="assets/img/exchange/dse/customizable_user_interfaces.svg" />
                  </div>
                  <h3 className="pt-2">Full Customization
                  </h3>
                  <p>
                    Whether you want to clone Uniswap or 1inch with additional features or tailored UI/UX, we provide fully customizable solutions to meet your specific requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
