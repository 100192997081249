import React from "react";

export default function ArtMarkCon() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Our DeFi Smart Contract Solutions</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/decentralized_exchanges.svg" />
                  </div>
                  <h3>
                    <a>Decentralized Exchanges (DEXs)</a>
                  </h3>
                  <p>
                    We design and deploy robust smart contracts for DEXs,
                    facilitating secure token swaps, liquidity pooling, and
                    yield farming. With a focus on speed, scalability, and
                    transparency, our smart contracts ensure seamless
                    decentralized trading.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/lending_borrowing_protocols.svg" />
                  </div>
                  <h3>
                    <a>Lending & Borrowing Protocols</a>
                  </h3>
                  <p>
                    Our DeFi smart contracts support secure, peer-to-peer
                    lending and borrowing. By implementing collateralized
                    lending protocols, we create opportunities for users to
                    safely lend or borrow assets within a decentralized
                    environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/staking_yield_Farmingf.svg" />
                  </div>
                  <h3>
                    <a>Staking & Yield Farming</a>
                  </h3>
                  <p>
                    Enhance user engagement and incentivize liquidity with our
                    customized staking and yield farming contracts. Our
                    contracts enable users to earn rewards, contributing to the
                    platform's ecosystem growth while fostering user loyalty.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/stablecoins_asset_anagement.svg" />
                  </div>
                  <h3>
                    <a>Stablecoins & Asset Management</a>
                  </h3>
                  <p>
                    We create smart contracts for stablecoins and decentralized
                    asset management solutions, enabling users to securely
                    manage their assets with minimal volatility. From asset
                    tokenization to secure fund management, our contracts bring
                    transparency and trust to asset handling.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"380px"}}>
                  <div className="icon-box">
                    <img style={{width:"50px"}} src="assets/img/smart-con/insurance_protocols.svg" />
                  </div>
                  <h3>
                    <a>Insurance Protocols</a>
                  </h3>
                  <p>
                    Build trust with decentralized insurance protocols. Our
                    insurance smart contracts offer protection against potential
                    risks, providing users with transparency and security while
                    offering financial coverage for DeFi activities.
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </>
  );
}
