import React from "react";

export default function TheParadigm() {
  return (
    <>
      <section
        className="feature-section pt_80 pb_150"
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <img
                  src="assets/img/defi/The-New-Realm-of-DeFi-&-Its-Future-Scope-Section-Image-File.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="">
                  <div data-animation-box className="sec-title ">
                  <h2>The New Realm of DeFi & Its Future Scope</h2>
                  </div>
                  <p className="py-3">
                    Decentralized Finance (DeFi) is a revolutionary financial
                    system built on blockchain technology that removes
                    intermediaries like banks and brokers, allowing users to
                    access financial services such as lending, borrowing,
                    trading, and earning interest directly through decentralized
                    platforms. DeFi operates through smart contracts, providing
                    transparency, security, and inclusivity.
                  </p>
                  <p>
                    The future of DeFi is poised for significant growth as it
                    continues to expand across multiple blockchain networks,
                    offering greater accessibility, innovative financial
                    products, and transforming traditional financial systems
                    into a more open, decentralized, and user-controlled
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
