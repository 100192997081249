import React from "react";

export default function ReadytoMLMPlatform() {
  return (
    <>
      <section
        className="clients-section alternat-2 p_30 centred"
        style={{ backgroundColor: "#071522" }}
      >
        <div className="auto-container">
          <div className="inner-container">
            <h1 className="text-white">Ready to Launch Your MLM Platform?</h1>
            <p className="text-white py-3">
              Contact Xeer Technology today to discuss how we can create a
              solution that accelerates your MLM success.
            </p>
          </div>
          <a href="#" className="theme-btn btn-one mr_20">
            Get a quote
          </a>
        </div>
      </section>
    </>
  );
}
