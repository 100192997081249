import React from "react";

export default function SecurelyStore() {
  return (
    <>
      {/* <section className="about-style-three p_100">
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/shape-67.png)" }}
        />
        <div className="auto-container">
          <div className="sec-title mb_25 text-center">
            <h2>
              Why choose Xeer Technology for your Bitcoin Wallet development
              needs!
            </h2>
          </div>
          <div className="text-box mb_20 text-center">
            <p>
              At Xeer Technology, our Bitcoin Wallet Development Service is
              designed to provide secure, scalable, and feature-rich wallets
              that meet the needs of both individuals and businesses. Below are
              the core elements that set our service apart:
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_five">
                <div className="image-box mr_80">
                  <figure className="image">
                    <img src="assets/img/wallet/why-choose-xeer-technology-for-your-bitcoin-wallet-development-needs.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_four">
                <div className="content-box ml_20">
                  <div className="inner-box">
                    <div className="single-item mb_20">
                      <div className="icon-box">
                        <i className="icon-13" />
                      </div>
                      <h3>Customizable Solutions</h3>
                      <p>
                        Whether you need a custodial or non-custodial wallet, or
                        you want advanced features like multi-signature support,
                        our team provides tailor-made solutions to meet your
                        needs.
                      </p>
                    </div>
                    <div className="single-item mb_20">
                      <div className="icon-box">
                        <i className="icon-13" />
                      </div>
                      <h3>User-Friendly Interface</h3>
                      <p>
                        Our wallet interfaces are intuitive and simple, offering
                        a seamless user experience, designed for ease of use
                        regardless of if you are a beginner or an advanced user.
                      </p>
                    </div>
                    <div className="single-item mb_20">
                      <div className="icon-box">
                        <i className="icon-14" />
                      </div>
                      <h3>Advanced Wallet Features</h3>
                      <p>
                        Integrated advanced features such as real-time
                        transaction tracking, two-factor authentication (2FA),
                        biometric logins, QR code payments, and backup &
                        recovery options.
                      </p>
                    </div>
                    <div className="single-item">
                      <div className="icon-box">
                        <i className="icon-14" />
                      </div>
                      <h3>Ongoing Support & Maintenance</h3>
                      <p>
                        Our services don’t end with development. We offer
                        ongoing support, updates, and maintenance to ensure your
                        Bitcoin wallet stays secure and performs optimally over
                        time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Why choose Xeer Technology for your Bitcoin Wallet development needs!

            </h2>
            <p className="p-3">
              At Xeer Technology, our Bitcoin Wallet Development Service is designed to provide secure, scalable, and feature-rich wallets that meet the needs of both individuals and businesses. Below are the core elements that set our service apart:
            </p>

          </div>
          <div className="row clearfix">
          <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/dedicated_support.svg" />
                  </div>
                  <h3 className="pt-4">Customizable Solutions
                  </h3>
                  <p>
                  Whether you need a custodial or non-custodial wallet, or you want advanced features like multi-signature support, our team provides tailor-made solutions to meet your needs.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/ui_ux (1).svg" />
                  </div>
                  <h3 className="pt-4">User-Friendly Interface
                  </h3>
                  <p>
                  Our wallet interfaces are intuitive and simple, offering a seamless user experience, designed for ease of use regardless of if you are a beginner or an advanced user.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/experienced_team.svg" />
                  </div>
                  <h3 className="pt-4">Advanced Wallet Features
                  </h3>
                  <p>
                  Integrated advanced features such as real-time transaction tracking, two-factor authentication (2FA), biometric logins, QR code payments, and backup & recovery options.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box">
                  <div >
                    <img style={{ width: "70px" }} src="assets/img/exchange/se/standard_development_practice.svg" />
                  </div>
                  <h3 className="pt-4">Ongoing Support & Maintenance
                  </h3>
                  <p>
                  Our services don’t end with development. We offer ongoing support, updates, and maintenance to ensure your Bitcoin wallet stays secure and performs optimally over time.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
