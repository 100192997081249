import React from "react";

export default function Harness() {
  return (
    <>
      <section className="glance-section centred p_50">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light">
              <h2 className="text-white">
                Unlock the power of Ethereum with a secure, user-friendly wallet
                – Get Started with Xeer Technology Today!
              </h2>
            </div>
            <a href="/contact-us" className="theme-btn btn-one my_20">
              Get a Quote
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
