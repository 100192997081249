import React from "react";

export default function PackageofWEB() {
  return (
    <>
      <section className="feature-style-two pt_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Explore Wide Possibilities of Building Web3 Applications</h2>
            <p>
              Embrace the limitless opportunities that Web3 offers to redefine
              traditional models and create value-driven applications for your
              audience.
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "425px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-music-platform.svg"
                      width={45}
                      className="me-2 mb-2"
                    />
                  </div>
                  <h3>Web3 Music Platform</h3>
                  <p>
                    A decentralized music streaming service that enables artists
                    to distribute their music directly to fans, ensuring fair
                    compensation and greater control over their content.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "425px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-gaming-platform.svg"
                      width={45}
                      className=" mb-2"
                    />
                  </div>
                  <h3>Web3 Gaming Platform</h3>
                  <p>
                    A decentralized gaming ecosystem that allows players to earn
                    cryptocurrency and trade in-game assets, creating a
                    play-to-earn model and enhancing player engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "425px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-video-streaming-platform.svg"
                      width={45}
                      className=" mb-3"
                    />{" "}
                  </div>
                  <h3>Web3 Video Streaming Platform</h3>
                  <p>
                    A decentralized video sharing platform that enables content
                    creators to upload and monetize their videos without relying
                    on traditional advertising models, offering viewers an
                    ad-free experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "425px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-finance-platform.svg"
                      width={45}
                      className=" mb-2"
                    />{" "}
                  </div>
                  <h3>Web3 Finance Platform</h3>
                  <p>
                    A decentralized finance (DeFi) platform that offers services
                    like lending, borrowing, and yield farming, allowing users
                    to manage their assets without intermediaries.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-social-media-platform.svg"
                      width={45}
                      className=" mb-2"
                    />{" "}
                  </div>
                  <h3>Web3 Social Media Platform</h3>
                  <p>
                    A decentralized social network that prioritizes user privacy
                    and data ownership, enabling users to connect and share
                    content without centralized control.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-nftmarketplace.svg"
                      width={45}
                      className=" mb-2"
                    />{" "}
                  </div>
                  <h3>Web3 NFT Marketplace</h3>
                  <p>
                    A decentralized marketplace for creating, buying, and
                    selling non-fungible tokens (NFTs), providing artists and
                    collectors a platform for digital art and collectibles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-identity-verification-platform.svg"
                      width={45}
                      className=" mb-2"
                    />{" "}
                  </div>
                  <h3>Web3 Identity Verification Platform</h3>
                  <p>
                    A decentralized identity management solution that allows
                    users to verify their identity securely without sharing
                    personal data, enhancing privacy and security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{ height: "385px" }}>
                  <div className="icon-box">
                    <img
                      src="assets/img/web3/web3-real-estate-platform.svg"
                      width={45}
                      className=" mb-2"
                    />{" "}
                  </div>
                  <h3>Web3 Real Estate Platform</h3>
                  <p>
                    A decentralized platform for buying, selling, and leasing
                    properties using blockchain technology, enabling transparent
                    transactions and fractional ownership options.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
