import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function MultiCurrencyWalletHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/Wallet/Crypto-Wallet-Clone-Development-Hero-Section-Image.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Crypto Wallet Clone Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Launch clone of popular crypto wallets like Metamask or
                    Trust Wallet with the features and functionality of leading
                    wallet platforms, tailored to your business needs for a
                    seamless and secure user experience.
                  </p>
                </div>
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
           
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
