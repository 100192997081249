import React from 'react'
import Header from '../Coman/Header'
import Footar from '../Coman/Footar'
import CoinDevelopmentHero from './CoinDevelopmentHero'
import BlogWeb from '../Web-3-0-Devlopment/BlogWeb'
import LookingD from '../About-Us/LookingD'
import BringsNext from './BringsNext'
import CanYouBelieve from './CanYouBelieve'
import Advisory from './Advisory'
import Multiple from './Multiple'
import Mastering from './Mastering'
import GlobalAccessibility from './GlobalAccessibility'

export default function CoinDevelopment() {
  return (
    <div>
      <Header/>
      <CoinDevelopmentHero/>
      <BringsNext/>
      <Advisory/>
      <Multiple/>
      {/* <GlobalAccessibility/> */}
      <CanYouBelieve/>
      <Mastering/>
      <BlogWeb/>
      <LookingD/>
      <Footar/>
    </div>
  )
}
