import React from "react";
import Header from "../Coman/Header";
import WebDevlopmentHero from "./WebDevlopmentHero";
import Footar from "../Coman/Footar";
import Widen from "./Widen";
import Empowering from "./Empowering";
import BlogWeb from "./BlogWeb";
import LookingD from "../About-Us/LookingD";
import ConnectwithSMEs from "./ConnectwithSMEs";
import PackageofWEB from "./PackageofWEB";
import WhatMakes from "./WhatMakes";

export default function WebDevlopment() {
  return (
    <div>
      <Header />
      <WebDevlopmentHero />
      <Widen />
      <Empowering />
      <ConnectwithSMEs />
      <PackageofWEB />
      <WhatMakes />
      <BlogWeb />
      <LookingD />
      <Footar />
    </div>
  );
}
