import React from "react";

export default function SelfCustody() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Get Script For DeFi Lending & Borrowing Platform</h2>
            <p className="pt-3">
              Unlock quick launch of a DeFi lending-borrowing platform with our
              ready-to-use scripts!
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/binance.svg"
                  />
                  <h5 className="py-3">Aave Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/Bitstamp.svg"
                  />
                  <h5 className="py-3">Pancakeswap Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/Bitfinex.svg"
                  />
                  <h5 className="py-3">Summer.fi Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/Coinbase.svg"
                  />
                  <h5 className="py-3">Compound</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/Layer_x0020_1.svg"
                  />
                  <h5 className="py-3">MakerDAO Script</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 pt_120">
              <div className="mw-box-img">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2023/09/Layer_x0020_1-1.svg"
                  />
                  <h5 className="py-3">Yearn Finance</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
