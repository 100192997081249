import React from "react";

export default function Keeps() {
  return (
    <>
      <section className="feature-section home-3 p_150">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="">
                <div className="image-box mr_40">
                  <figure className="image">
                    <img src="assets/img/securitization/security-token-offering--development-with-xeer-technology.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div className="sec-title mb_25">
                  <h2>
                    Security Token Offering (STO) Development with Xeer
                    Technology
                  </h2>
                </div>
                <div className="text-box">
                  <p>
                    As digital securities redefine investment landscapes,
                    Security Token Offerings (STOs) provide a secure, compliant
                    solution for tokenizing assets, from real estate to private
                    equity. Xeer Technology specializes in STO development,
                    guiding businesses through the tokenization process to help
                    them access secure, cost-effective capital.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
