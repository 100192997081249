import React from "react";

export default function CloneSoftware() {
  return (
    <>
      <section className="feature-section p_120">
        <div className="auto-container">
                <div data-animation-box className="sec-title mb_50 text-center">
                  <h2>DEX Aggregator Development by Xeer Technology</h2>
                </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div className="text-box">
                  <h3>Unlock Optimal Trading Across Decentralized Exchanges</h3>
                  <p className="pt-2">
                    At Xeer Technology, we specialize in building advanced DEX
                    aggregators that empower users to execute trades with the
                    best prices, lowest slippage, and highest liquidity by
                    connecting to multiple decentralized exchanges (DEXs). Our
                    DEX aggregator solutions cater to the demands of modern DeFi
                    traders, providing a seamless, cost-effective, and optimized
                    trading experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className=" ml_40">
              
                <div className="image-box">
                  <figure className="image image-1">
                    <img src="assets/img/dex/Unlock-Optimal-Trading-Across-Decentralized-Exchanges-Section-Image-File.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
