import React from "react";

export default function WhyArentYou() {
  return (
    <>
      <section className="glance-section centred p_100">
        <div className="auto-container">
          <div className="inner-container">
            <div className="pattern-layer">
              <div
                className="pattern-1"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-5.png)",
                }}
              />
              <div
                className="pattern-2"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-6.png)",
                }}
              />
              <div
                className="pattern-3 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-7.png)",
                }}
              />
              <div
                className="pattern-4 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-8.png)",
                }}
              />
              <div
                className="pattern-5 rotate-me"
                style={{
                  backgroundImage: "url(assets/images/shape/shape-9.png)",
                }}
              />
            </div>
            <div className="sec-title light mb_80">
              <h2>Why Choose Xeer for Crypto Asset Management?</h2>
              <p>
                Managing crypto assets requires specialized expertise and robust
                technology for security and operational efficiency. Xeer
                Technology delivers custom-built solutions designed to
                streamline asset management processes, ensuring transparency,
                compliance, and enhanced asset security.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
