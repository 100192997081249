import React from "react";

import BlockchainDevelopmentHero from "./BlockchainDevelopmentHero";
import DevelopmentSolutions from "./DevelopmentSolutions";
import BlockchainMarketForecast from "./BlockchainMarketForecast";
import BlockchainDevelopmentServices from "./BlockchainDevelopmentServices";
import OurBlockchaindevelopment from "./OurBlockchaindevelopment";
import BlockchainFrameworks from "./BlockchainFrameworks";
import BlockchainDevelopmentPlatforms from "./BlockchainDevelopmentPlatforms";
import BlogsNFT from "../../NFT/BlogsNFT";
import Footar from "../../Coman/Footar";
import LookingD from "../../About-Us/LookingD";
import Header from "../../Coman/Header";

export default function BlockchainDevelopment() {
  return (
    <>
      <div class="boxed_wrapper ltr">

        <Header/>
        <BlockchainDevelopmentHero/>
        <DevelopmentSolutions/>
        <BlockchainMarketForecast/>
        <BlockchainDevelopmentServices/>
        {/* <OurBlockchaindevelopment/> */}
        <BlockchainFrameworks/>
        <BlockchainDevelopmentPlatforms/>
        <BlogsNFT/>
        <LookingD/>
        <Footar/>
      </div>
    </>
  );
}
