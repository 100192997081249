import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function CryptoGamingAppHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{
          backgroundImage:
            "url(assets/img/crypto-g-dev/crypto-gaming-app-development.jpg)",
        }}
      >
        <div className="auto-container ">
          <div className="row align-items-center pb_100">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">Crypto Gaming App Development</h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    At Xeer Technology, we empower game developers & businesses
                    to embrace the future with innovative crypto gaming
                    solutions. Our Crypto Gaming App Development services are
                    designed to help you launch blockchain-based games that
                    offer immersive experiences & unique monetization
                    opportunities.
                  </p>
                </div>
              </div>
              <div className="btn-box pt-3">
                <TalkToOurTeamBTN />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
