import React from "react";

export default function ArbitrageTBots() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Types of Crypto Arbitrage Bots We Build:</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/triangular_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Triangular Arbitrage
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/spatial_arbitrage.svg" />
                  </div>
                  <h5 className="pt-3">
                    Spatial Arbitrage
                    <br />
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/intra_exchange_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Inter-Exchange Arbitrage
                    
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/inter_exchange_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Intra-Exchange Arbitrage
                    Bots
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/decentralized_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Decentralized Arbitrage
                    
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"45px"}} src="assets/img/exchange/atb/mev_flash_loan_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    MEV Flash Loan Arbitrage
                    
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"100px"}} src="assets/img/exchange/atb/sandwich_arbitrage_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Sandwich Arbitrage
                    
                    Bots
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1300}
              >
                <div className="inner-box" style={{height:"225px"}}>
                  <div className="">
                    <img style={{width:"75px"}} src="assets/img/exchange/atb/crypto_arbitrage_ai_bots.svg" />
                  </div>
                  <h5 className="pt-3">
                    Crypto Arbitrage AI
                    
                    Bots
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
