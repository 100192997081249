import React from "react";

export default function CompleteListing() {
  return (
    <>
      <section className="feature-style-two pb_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>
              Key Features of Our CoinMarketCap & CoinGecko Listing Service
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/complete_listing_management.svg" />
                  </div>
                  <h3>Complete Listing Management</h3>
                  <p>
                    Assistance with completing the application process,
                    submitting required details, and following up with platform
                    support to expedite listing.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/regulatory_compliance_review.svg" />
                  </div>
                  <h3>Regulatory Compliance Review</h3>
                  <p>
                    Ensuring all listing submissions meet CoinMarketCap and
                    CoinGecko’s compliance standards for a smooth, hassle-free
                    approval.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={1100}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/analytics_and_market_data_integration.svg" />
                  </div>
                  <h3>Analytics and Market Data Integration</h3>
                  <p>
                    Integration of essential market data points, including price
                    tracking, market cap, and trading volume, to support
                    real-time visibility and analysis.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={700}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/marketing_boost_community_engagement.svg" />
                  </div>
                  <h3>Marketing Boost & Community Engagement</h3>
                  <p>
                    Strategic promotional support to elevate your token’s reach,
                    combining social media, PR, and influencer engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature-block">
              <div
                className="feature-block-one"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration={900}
              >
                <div className="inner-box" style={{height:"320px"}}>
                  <div className="">
                    <img style={{width:"80px"}} src="assets/img/listing/svg/post_listing_monitoring.svg" />
                  </div>
                  <h3>Post-Listing Monitoring</h3>
                  <p>
                    Continuous performance tracking, updates, and improvements
                    to maintain token ranking and visibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
