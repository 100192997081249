import React from "react";
import TalkToOurTeamBTN from "../Coman/TalkToOurTeamBTN";

export default function NFTHero() {
  return (
    <>
      <section
        className="feature-section p_150"
        style={{backgroundPosition:"50% 50%",backgroundSize:"cover",
          backgroundImage:
            "url(assets/img/nft_dev/nft-development-launching-services.jpg)",
        }}
      >
        <div className="auto-container py-5">
          <div className="row align-items-center pb_50">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2 className="text-white">
                    NFT Development & Launching Services
                  </h2>
                </div>
                <div className="text-box">
                  <p className="text-white">
                    Join the revolution & partner with us to launch your own
                    NFTs in the market! We design, develop & mint NFTs on
                    different blockchain ecosystems!
                  </p>
                </div>
              </div>
              <TalkToOurTeamBTN />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column"></div>
          </div>
        </div>
      </section>
    </>
  );
}
