import React from "react";

export default function LeadingBlockNET() {
  return (
    <>
      <section className="feature-style-two p_120 centred">
        <div className="auto-container">
          <div data-animation-box className="sec-title mb_55">
            <h2>Blockchain Networks We Use For NFT Developments</h2>
            <p>
              We provide non-fungible token development services on any of your
              desired blockchains
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    style={{ width: "60%" }}
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/04/ethereum-eth-logo.svg"
                  />
                </div>
                <h3></h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Binance-1.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/04/tron.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Ripple.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Solana-3.png.webp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center pt-5">
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Near.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Optimum.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Cronos-1-1.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/Telos-EVM.png.webp"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="img-box-two justify-content-center">
                <div className="py-3 px-3">
                  <img
                    className="rounded mx-auto d-block"
                    src="https://www.antiersolutions.com/wp-content/uploads/2022/09/EOS.png.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
