import React from "react";

export default function TokenListingServices() {
  return (
    <>
      <section className="feature-section p_100">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="ps-5">
                <div className="ps-5">
                  <div>
                    <img style={{width:"350px"}} src="assets/img/listing/token-listing-services-with-xeer-technology.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content-box ml_40">
                <div data-animation-box className="sec-title mb_25">
                  <h2>Token Listing Services with Xeer Technology</h2>
                </div>
                <div className="text-box">
                  <p>
                    Expand your token’s reach with a smooth, strategic listing
                    process designed to bring credibility and visibility to your
                    project. At Xeer Technology, we provide complete support to
                    guide you through every stage of the token listing journey,
                    enhancing your token’s market presence and ensuring
                    alignment with regulatory standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
