import React from "react";
import Header from "../Coman/Header";
import NFTWalletHero from "./NFTWalletHero";
import Footar from "../Coman/Footar";
import HowSecure from "./HowSecure";
import Custodia from "./Custodia";
import CrossChain from "./CrossChain";
import Requiremen from "./Requiremen";
import UniqueOfferings from "../MultiCurrency-Wallet/UniqueOfferings";
import Achieving from "./Achieving";
import NFTWalletBlog from "./NFTWalletBlog";
import LookingD from "../About-Us/LookingD";
export default function NFTWallet() {
  return (
    <div>
      <Header />
      <NFTWalletHero />
      <HowSecure />
      <Custodia />
      {/* <CrossChain /> */}
      <Requiremen />
      {/* <UniqueOfferings /> */}
      <Achieving />
      <NFTWalletBlog/>
      <LookingD/>
      <Footar />
    </div>
  );
}
